import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { CheckCircleIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import AdminNavbar from "./AdminNavbar";

const AdminAccounts = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccounts(response.data);
        setLoaded(true);
    }

    const handleSearch = async () => {
        if(userSearch.length > 0){
            const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/accounts?username=' + userSearch, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setAccounts(response.data);
        }
    }


    useEffect(() => {
        refreshToken();
        getAccounts();
    }, []);
    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);
    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>Konta</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz zarządzać kontami użytkowników.</span>
        </div>        <div className="flex flex-row gap-2 items-center justify-between mb-6">
        <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block px-2.5 py-3 mb-1" placeholder="Nazwa konta TikTok" value={userSearch} onChange={(e) => setUserSearch(e.target.value)}/>
        <div className="bg-sky-600 w-fit p-2 rounded-lg" onClick={handleSearch}>Wyszukaj</div>
        </div>
        <div className="flex flex-col gap-2">
        {accounts.map((object, i) =>
        <>
        <a
                  href=""
                  onClick={() => navigate("/admin/account/" + object.id)}
                  className='block px-2 py-3 text-sm rounded-md text-slate-300 bg-slate-900'
                >
                    <div className="flex flex-row gap-2 items-center">
    
        <img className='w-7 h-7 rounded-3xl border border-solid border-gray-500' src={object.avatar}/>
        <div className="flex flex-row justify-between w-full items-center">
    <span className="text-xs text-slate-400">@{object.username}</span>
    {object.status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {object.status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {object.status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
</div>
</div>
                </a>
    </>
        )}
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminAccounts;