import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { CheckBadgeIcon, CheckCircleIcon, ClockIcon, CurrencyDollarIcon, UserCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import { short_formatter } from "../../utils/chart";
import { TikTok, priceformatter } from "../../utils/utils";
import AdminNavbar from "./AdminNavbar";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const AdminPayouts = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [status, setStatus] = useState('all');
    const [payouts, setPayouts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getPayouts = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/payouts?status=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPayouts(response.data);
        setLoaded(true);
    }


    const handleStatus = async(props) => {
        console.log(props);
        await setStatus(props);
        await getPayouts(props);
    }

    const reject = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz odrzucić tą wypłatę?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewpayout?id=' + props + '&action=reject', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getPayouts(status);
              Swal.fire('Odrzucono wypłatę', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const accept = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz zaakceptować tą wypłatę?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewpayout?id=' + props + '&action=accept', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                if(response.data.status == "success"){
                    getPayouts(status);
              Swal.fire('Zaakceptowao wypłatę', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    useEffect(() => {
        refreshToken();
        getPayouts("all");
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>Wypłaty</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz sprawdzić wypłaty użytkowników.</span>
        </div>
        <div className="flex flex-row gap-1 mb-4 flex-wrap">
        <div onClick={() => {handleStatus("all")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "all" ? "bg-green-900" : "bg-slate-800")}>Wszystko</div>
        <div onClick={() => {handleStatus("accepted")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "accepted" ? "bg-green-900" : "bg-slate-800")}>Zaakceptowane</div>
        <div onClick={() => {handleStatus("waiting")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "waiting" ? "bg-green-900" : "bg-slate-800")}>Oczekujące</div>
        <div onClick={() => {handleStatus("rejected")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "rejected" ? "bg-green-900" : "bg-slate-800")}>Odrzucone</div>
        </div>
    <div className="flex flex-col gap-4">
    {payouts.length > 0 ? payouts.map((object, i) =>
            <div className="flex flex-col bg-slate-900 rounded-lg">
            <div className="flex flex-col gap-1 p-2">
            <div className="flex flex-row items-start gap-2">
                <div className="flex items-start justify-start p-1 bg-slate-800 rounded-lg">
                <CurrencyDollarIcon className="w-7 h-7 text-sky-400"></CurrencyDollarIcon>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm flex flex-row gap-1.5 items-center mb-1">Wypłata #{object.id}
                    {object.status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Zatwierdzona</div>}
    {object.status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekująca</div>}
    {object.status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucona</div>}
                    </span>
                    <span className="text-slate-400 text-xs">{object.createdAt}</span>
                </div>
            </div>
            <div className="flex flex-row gap-1 flex-wrap mt-1">
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1"><CurrencyDollarIcon className="w-4 h-4 text-white"></CurrencyDollarIcon> {priceformatter.format(object.sum)}</div>
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1 capitalize"><CurrencyDollarIcon className="w-4 h-4 text-white"></CurrencyDollarIcon> {object.payment_method}</div>
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1"><UserCircleIcon className="w-4 h-4 text-white"></UserCircleIcon> {object.user.nick}</div>
            </div>
            <div className="flex flex-col gap-1 flex-wrap mt-1.5 pl-1">
            <div className=" text-white text-xs rounded-lg flex flex-row gap-1">Imię i nazwisko: <strong>{object.name}</strong></div>
            <div className=" text-white text-xs rounded-lg flex flex-row gap-1">Numer: <strong>{object.number}</strong></div>
            </div>
            </div>
            <div className="flex flex-row gap-1 p-2">
                <div onClick={() => accept(object.id)} className="w-full cursor-pointer bg-green-500 text-white text-center p-1 px-2 rounded-lg text-xs flex items-center justify-center">
                    Zaakceptuj
                    </div>
                    <div onClick={() => reject(object.id)} className="w-full cursor-pointer bg-red-500 text-white text-center p-1 px-2 rounded-lg text-xs flex items-center justify-center">
                    Odrzuć
                    </div>
                    <div onClick={() => navigate("/admin/user/" + object.user_id)} className="w-full cursor-pointer bg-blue-500 text-white text-center p-1 px-2 rounded-lg text-xs flex items-center justify-center">
                    Sprawdź profil użytkownika
                    </div>
                </div>
</div>
    ) : <span className="text-slate-400 text-base text-center">Nie ma jeszcze żadnych wypłat z tym statusem!</span>}</div>
    </div>
    </motion.main>
    </>
        );

}

export default AdminPayouts;