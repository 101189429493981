import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftOnRectangleIcon, CheckCircleIcon, ClockIcon, LockClosedIcon, PencilSquareIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { classNames } from "../utils/utils";

const Profile = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState([]);
    const [nick, setNick] = useState([]);
    const [user, setUser] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            setNick(decoded.nick);
            getAccounts();
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccounts(response.data.accounts);
        setUser(response.data);
        if(response.data.accounts.length > 0){
        setAccount(response.data.accounts[0]);
        }
        setLoaded(true);
    }

    const Logout = async () => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + 'logout');
            navigate("/login");
        } catch (error) {
            
        }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className="flex justify-center gap-3 flex-col items-center mb-14">
          {account.avatar ?
          <div class="h-20 w-20 relative">
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-50" style={{ filter: 'blur(22.5px)', willChange: 'transform', backgroundImage: "url(" + account.avatar + ")"}}></div>
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-full" style={{backgroundImage: "url(" + account.avatar + "), url('https://jmmplatform.pl/waiting.png')"}}></div>
</div>
:
<div class="h-20 w-20 relative">
<div class="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-full bg-slate-500"></div>
    </div>
          }
{account.username ?
<span className="font-bold text-white text-xl">{account.username}</span>
:
<span className="font-bold text-white text-xl">{nick}</span>}
</div>
<div className="text-slate-200 text-lg font-normal mt-7 mb-4 hidden">Informacje</div>
<div className="flex justify-between items-center flex-row hidden">
                            <div className="text-slate-300 text-sm">Ostrzeżenia</div>
                            <div className="text-green-500 text-base">0/3</div>
                        </div>
                        <div className="text-slate-200 text-lg font-normal mt-9 mb-4">Moje profile na tiktoku</div>
                        <div className="flex flex-col gap-2">
                        {accounts.map((object, i) =>
        <>
                <div
                  className={classNames('block px-2 py-3 text-sm rounded-md text-slate-300 bg-slate-900')}
                >
                    <div className="flex justify-between items-center">
                          <div className='flex flex-row items-center gap-2'>
        <img className='w-6 h-6 rounded-3xl border border-solid border-gray-500' onError={({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src="https://jmmplatform.pl/waiting.png";
  }} src={object.avatar}/>
<span className='font-normal text-base text-white overflow-hidden text-ellipsis whitespace-nowrap' style={{maxWidth: '9rem'}}>{object.username}</span>
</div>
{object.status == "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {object.status == "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {object.status == "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
</div>
                </div>
            </>
            )}
                                    <div onClick={() => navigate("/addprofile")} className=" cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-3 flex justify-center flex-row items-center gap-2">
                        <PlusIcon className="w-5 h-5"></PlusIcon>
                        Dodaj profil
        </div>
            </div>

            <div className="text-slate-200 text-lg font-normal mt-9 mb-4">Dane kontaktowe</div>
            <div className="flex flex-col gap-2">
                {user.instagram ? 
            <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Instagram</div>
                            <div className="text-white text-base">{user.instagram}</div>
                        </div>
                        : 
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Instagram</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                        }
                        {user.mail ? 
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Adres e-mail</div>
                            <div className="text-white text-base">{user.mail}</div>
                        </div>
                        : 
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Adres e-mail</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                        }
                        {user.phone ? 
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Numer telefonu</div>
                            <div className="text-white text-base">{user.phone}</div>
                        </div>
                        : 
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Numer telefonu</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                        }
                        {user.city ? 
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Miasto i województwo</div>
                            <div className="text-white text-base">{user.city}</div>
                        </div>
                        : 
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Miasto i województwo</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                    }
            <div onClick={() => navigate("/addcontact")} className="mt-2 cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-3 flex justify-center flex-row items-center gap-2">
                        {user.instagram && user.mail && user.phone ? 
                        <>
                                                <PencilSquareIcon className="w-5 h-5 text-slate-400"></PencilSquareIcon>
                                                Edytuj dane
                                                </>
                        :
                        <>
                        <PlusIcon className="w-5 h-5"></PlusIcon>
                        Uzupełnij dane
                        </>
}
        </div>
            </div>
            <div className="text-slate-200 text-lg font-normal mt-9 mb-4">Metoda płatności</div>
            <div className="flex flex-col gap-2">
                {user.payment_methods ?
                <>
                {user.payment_methods['blik'] ? 
            <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Blik</div>
                            <div className="text-white text-base">{user.payment_methods['blik'].number}</div>
                        </div>
                        :  
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Blik</div>
                            <div className="text-white text-base">Brak</div>
                        </div>
                        }
                        {user.payment_methods['przelew'] ? 
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Przelew</div>
                            <div className="text-white text-base">{user.payment_methods['przelew'].number}</div>
                        </div>
                        : 
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Przelew</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                        }
                        {user.payment_methods['bitcoin'] ? 
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Bitcoin</div>
                            <div className="text-white text-base">{user.payment_methods['bitcoin'].number}</div>
                        </div>
                        :
                        <div className="flex justify-between items-center flex-row">
                        <div className="text-slate-300 text-sm">Bitcoin</div>
                        <div className="text-white text-base">Brak</div>
                    </div>
                        }
                        </>
                        : ""}
            <div onClick={() => navigate("/addpayment")} className="mt-2 cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-3 flex justify-center flex-row items-center gap-2">
                        {user.payment_methods ? 
                        <>
                                                <PencilSquareIcon className="w-5 h-5 text-slate-400"></PencilSquareIcon>
                                                Edytuj metody płatności
                                                </>
                        :
                        <>
                        <PlusIcon className="w-5 h-5"></PlusIcon>
                        Dodaj metodę płatności
                        </>
}
        </div>
            </div>
        <div onClick={() => navigate("/changepassword")} className="cursor-pointer mt-8 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-3 flex justify-center flex-row items-center gap-2">
                        <LockClosedIcon className="w-5 h-5"></LockClosedIcon>
                        Zmień hasło
        </div>
        <div onClick={() => Logout()} className="cursor-pointer mt-4 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-3 flex justify-center flex-row items-center gap-2">
                        <ArrowLeftOnRectangleIcon className="w-5 h-5"></ArrowLeftOnRectangleIcon>
                        Wyloguj się
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default Profile;