import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Modal } from "../fragments/Modal";
import Recaptcha from "react-google-recaptcha"
import { useRef } from "react";

const AddProfile = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [link, setLink] = useState('');
    const captchaRef = useRef(null);

    const [modal, setModal] = useState({ show: false, data: null });
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };


    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const addProfile = async () => {
        try{
            const ctoken = captchaRef.current.getValue();
            captchaRef.current.reset();
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'addprofile?url=' + link + "&token=" + ctoken, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.status == "success"){
            setModal({ show: true, data: { type: 'addProfile' } });
        
        }else{
            setModal({ show: true, data: { type: 'error', message: response.data.message } });
        }
    }catch(e){
        setModal({ show: true, data: { type: 'error', message: e.response.data.message } });
    }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/profile")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Dodaj profil</span>

<XMarkIcon onClick={() => navigate("/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Link do profilu na TikToku</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="https://tiktok.com/@xxxtrueman" value={link} onChange={(e) => setLink(e.target.value)}/>
    <div class="captcha-container">
    <Recaptcha
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef} />
                                </div>
        <button onClick={addProfile} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj profil</button>
 
        </div>
    </motion.main>
    </>
        );

}

export default AddProfile;