import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Modal } from "../fragments/Modal";
import { short_formatter } from "../utils/chart";
import { Top } from "../utils/utils";

const TopDay = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [topTikToks, setTopTikToks] = useState([]);
    const navigate = useNavigate();



    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            await getTopTiktoks();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getTopTiktoks = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'toptiktoks', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setTopTikToks(response.data);
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/dashboard")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Najpopularniejsze tiktoki</span>

<XMarkIcon onClick={() => navigate("/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    <div className="text-white text-lg font-normal mb-4">Najpopularniejsze w ostatnich 24h</div>
                        <div className='flex flex-col gap-2'>
                        {topTikToks.length > 0 ?
                        topTikToks.map((object, i) =>
                        <>
                        <Top url={object.url} avatar={object.account_tiktok.avatar} cover={object.cover} likes={short_formatter.format(object.likes)} comments={short_formatter.format(object.comments)} views={short_formatter.format(object.views)} date={new Date(parseInt(object.createTime)*1000).toLocaleString()}></Top>
                       </>) : ""}
         </div>
        </div>
    </motion.main>
    </>
        );

}

export default TopDay;