import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, BanknotesIcon, CheckCircleIcon, CheckIcon, ClockIcon, CurrencyDollarIcon, HeartIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { classNames, priceformatter, TikTok } from "../utils/utils";
import { Modal } from "../fragments/Modal";

const Payout = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState([]);
    const [account, setAccount] = useState([]);
    const [sum, setSum] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const navigate = useNavigate();

    const [modal, setModal] = useState({ show: false, data: null });
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };


    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            getAccounts();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data.accounts[0])
        setUser(response.data);
    }

    const handlePaymentChange = async(payment) => {
        setPaymentMethod(payment);
        if(user.payment_methods){
            if(user.payment_methods[payment]){
                setNumber(user.payment_methods[payment].number);
                setName(user.payment_methods[payment].name);
            }else{
                setNumber('');
                setName('');
            }
        }
    }

    const handlePayout = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'payout?sum=' + sum + '&method=' + paymentMethod, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.status == "success"){
            setModal({ show: true, data: { type: 'payoutSuccess', id: response.data.id } });
            setSum("");
        
        }else{
            setModal({ show: true, data: { type: 'error', message: response.data.message } });
        }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/earnings")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Wypłaty</span>

<XMarkIcon onClick={() => navigate("/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    {user.payment_methods ? 
        <>
        {Object.keys(user.payment_methods).length > 0 ? 
        <>
    <div className="text-white text-base font-normal mb-3">Metoda wypłaty</div>

    <div className="flex flex-col gap-2 mb-6">
{user.payment_methods['blik'] ? 
    <div onClick={() => handlePaymentChange("blik")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "blik" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Blik na telefon</span>
        </div>
        <div>
            <img className="w-9 h-auto" src="https://jmmplatform.pl/blik.png"/>
        </div>
    </div>
    : ""}
    {user.payment_methods['przelew'] ? 
    <div onClick={() => handlePaymentChange("przelew")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "przelew" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Przelew</span>
        </div>
        <div>
            <BanknotesIcon className="text-slate-400 w-5 h-auto"></BanknotesIcon>
        </div>
    </div>
    : ""}
    {user.payment_methods['bitcoin'] ? 
    <div onClick={() => handlePaymentChange("bitcoin")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "bitcoin" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Bitcoin</span>
        </div>
        <div>
            <img className="w-5 h-auto" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"/>
        </div>
    </div>
    : "" }
</div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Kwota do wypłaty</div>

    <input type="number" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="Kwota do wypłaty" value={sum} onChange={(e) => setSum(e.target.value)}/>
            <span className="text-xs text-slate-400">Dostępne środki: <strong>{user.wallet} zł</strong></span>
        <button onClick={handlePayout} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm p-3 rounded-lg w-full flex justify-center gap-2 items-center"><CurrencyDollarIcon className="text-white w-5 h-5"></CurrencyDollarIcon> Zleć wypłatę</button>
        
        <div className="text-white text-base font-normal mb-3 mt-7">Historia wypłat</div>
        <div className="flex flex-col gap-2">
        {user.payouts.length > 0 ? user.payouts.map((object, i) =>
        <>
        <div className="flex items-center bg-slate-900 p-2 rounded-lg">
            <div className="flex flex-col gap-1">
            <div className="flex flex-row items-start gap-2">
                <div className="flex items-start justify-start p-1 bg-slate-800 rounded-lg">
                <CurrencyDollarIcon className="w-7 h-7 text-sky-400"></CurrencyDollarIcon>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm flex flex-row gap-1.5 items-center mb-1">Wypłata #{object.id}
                    {object.status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Zatwierdzona</div>}
    {object.status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekująca</div>}
    {object.status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  p-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucona</div>}
                    </span>
                    <span className="text-slate-400 text-xs">{object.createdAt}</span>
                </div>
            </div>
            <div className="flex flex-row gap-1 flex-wrap mt-1">
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1"><CurrencyDollarIcon className="w-4 h-4 text-white"></CurrencyDollarIcon> {priceformatter.format(object.sum)}</div>
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1 capitalize"><CurrencyDollarIcon className="w-4 h-4 text-white"></CurrencyDollarIcon> {object.payment_method}</div>

            </div>
            </div>
</div>
                  </>
        ): <><span className="text-slate-400 text-base text-center">Nie wypłaciłeś jeszcze żadnych środków!</span></>}
        </div>

        </>
        : <><span className="text-center flex items-center mb-4">Aby zlecić wypłatę musisz dodać metodę płatności do swojego profilu.</span>
        <button onClick={() => navigate("/addpayment")} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm p-3 rounded-lg w-full flex justify-center gap-2 items-center"><CurrencyDollarIcon className="text-white w-5 h-5"></CurrencyDollarIcon> Dodaj metodę płatności</button></> }
        </>
        : <><span className="text-center flex items-center mb-4">Aby zlecić wypłatę musisz dodać metodę płatności do swojego profilu.</span>
        <button onClick={() => navigate("/addpayment")} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm p-3 rounded-lg w-full flex justify-center gap-2 items-center"><CurrencyDollarIcon className="text-white w-5 h-5"></CurrencyDollarIcon> Dodaj metodę płatności</button>
        </> }
        </div>
    </motion.main>
    </>
        );

}

export default Payout;