import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Modal } from "../fragments/Modal";

const AddContact = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [instagram, setInstagram] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [user, setUser] = useState('');
    const [city, setCity] = useState(null);
    const [telegram, setTelegram] = useState(null);

    const [modal, setModal] = useState({ show: false, data: null });
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };


    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            getUser();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getUser = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setUser(response.data);
        setEmail(response.data.mail);
        setInstagram(response.data.instagram);
        setPhone(response.data.phone);
        setCity(response.data.city);
        setTelegram(response.data.telegram);
    }

    const editContact = async () => {
        const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'editcontact', {
            instagram: instagram,
            phone: phone,
            telegram: telegram,
            email: email,
            city: city
        }, {headers: {
            Authorization: `Bearer ${token}`
        }});
        if(response.data.status == "success"){
            setModal({ show: true, data: { type: 'addContact' } });
        
        }else{
            setModal({ show: true, data: { type: 'error', message: response.data.message } });
        }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/profile")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Twoje dane</span>

<XMarkIcon onClick={() => navigate("/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
        <div className="flex flex-col gap-1.5">
            <div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Instagram</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="@xxxtrueman" value={instagram} onChange={(e) => setInstagram(e.target.value)}/>
</div>
<div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Telegram - nazwa użytkownika (bez @)</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="np. jankowalski" value={telegram} onChange={(e) => setTelegram(e.target.value)}/>
</div>
<div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Numer telefonu</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="np. 513 102 102" value={phone} onChange={(e) => setPhone(e.target.value)}/>
</div>
<div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Adres e-mail</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="test@test.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
</div>
<div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Miasto i województwo</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="Warszawa, Mazowieckie" value={city} onChange={(e) => setCity(e.target.value)}/>
</div>
</div>
        <button onClick={editContact} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-base py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Uzupełnij dane</button>
 
        </div>
    </motion.main>
    </>
        );

}

export default AddContact;