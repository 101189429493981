import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon, FolderIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { VideoComponent, downloadVideo } from "../utils/utils";
import moment from "moment";

const Inspirations = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [categories, setCategories] = useState([]);
    const [account, setAccount] = useState([]);
    const [limit, setLimit] = useState(4);
    const [inspirations, setInspirations] = useState(4);
    const [loaded, setLoaded] = useState(false);
    const [more, setMore] = useState(false);
    const [offset, setOffset] = useState(10);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getCategories();
            await getInspirations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getCategories = async () => {
      const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'categories?limit=' + limit, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      setCategories(response.data);
      if(limit == 4)setLimit(999);;
      if(limit == 999) setLimit(4);
  }

  const getInspirations = async () => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'allinspirations', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    setInspirations(response.data);
}

const moreVideos = async () => {
let newOffset = offset+10;
setOffset(newOffset);
}

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Filmy do tiktoków</span>
        <span className='text-slate-300 font-normal text-sm'>Poniżej znajdziesz foldery z filmami do pobrania, które możesz <strong>dowolnie</strong> używać do stworzenia tiktoków!</span>
        </div>
        {inspirations.newcount > 0 ?
        <div onClick={() => navigate("/category/0")} className="bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-base font-normal p-2 flex justify-center flex-row items-center gap-2">
          <span className="bg-red-500 rounded-full p-pill text-white text-xs">{inspirations.newcount}</span> Zobacz nowe filmy
        </div>
        : "" }
        <div className="text-white text-lg font-normal mt-5 mb-5">Foldery</div>
        <div className="grid grid-cols-2 gap-3">
        {categories.map((object, i) =>
      <div onClick={() => navigate("/category/" + object.id)} className="cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md justify-center flex-col gap-2 py-3 flex text-center">
        <div className=" flex justify-center">
          <FolderIcon className="w-12 h-12 text-sky-500"></FolderIcon>
</div>
<div className="text-slate-300 text-sm">{object.title}</div>
      </div>
)}
        </div>
        <div onClick={() => {getCategories(); setMore(!more)}} className="cursor-pointer bg-slate-900 inline-flex w-full justify-center items-center gap-x-1.5 rounded-xl px-2 py-2 text-sm font-semibold text-white mt-4 border border-solid border-slate-800">
        <div className='flex flex-row items-center gap-3'>
<span className='text-slate-300 text-base font-light'>{more ? "Pokaż mniej" : "Pokaż więcej"}</span>
</div>
{more ? 
  <ChevronUpIcon className="-mr-1 h-5 w-5 text-sky-500"></ChevronUpIcon>
:
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-sky-500" aria-hidden="true" />
        }
        </div>
        <div className="text-white text-lg font-normal mt-8 mb-5">Wszystkie filmy</div>
        <div></div>
      <div className="grid grid-cols-2 md:flex md:flex-row md:flex-wrap md:justify-center gap-3">

      {inspirations.videos.slice(0, offset).map((object, i) =>
      <>
      <VideoComponent object={object}/>
  </>
)}
      </div>

      <div onClick={() => {moreVideos()}} className="cursor-pointer bg-slate-900 inline-flex w-full justify-center items-center gap-x-1.5 rounded-xl px-2 py-2 text-sm font-semibold text-white mt-4 border border-solid border-slate-800">
        <div className='flex flex-row items-center gap-3'>
<span className='text-slate-300 text-base font-light'>Pokaż więcej</span>
</div>
        </div>

          </div>
          </motion.main>
    </>
        );

}

export default Inspirations;