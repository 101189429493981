import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon, FolderIcon, PlayCircleIcon, PlusIcon, VideoCameraIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import moment from "moment";
import AdminNavbar from "./AdminNavbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';

const AdminInspirations = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [categories, setCategories] = useState([]);
    const [account, setAccount] = useState([]);
    const [limit, setLimit] = useState(4);
    const [inspirations, setInspirations] = useState(4);
    const [loaded, setLoaded] = useState(false);
    const [more, setMore] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const MySwal = withReactContent(Swal)

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getCategories();
            await getInspirations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getCategories = async () => {
      const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'categories?limit=' + limit, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      setCategories(response.data);
      if(limit == 4)setLimit(999);;
      if(limit == 999) setLimit(4);
  }

  const getInspirations = async () => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'allinspirations', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    
    setInspirations(response.data);
}

async function deleteInspiration(id, type){
    MySwal.fire({
        title: <strong>UWAGA</strong>,
        html: type == "video" ? "Czy chcesz usunąć ten film?" : "Czy chcesz usunąć ten folder i jego całą zawartość?",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText:'Tak',
        cancelButtonText: 'Anuluj'
      }).then(async (result) => {
        if (result.isConfirmed) {
            try{
                refreshToken();
            const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/deleteinspiration?id=' + id + '&type=' + type, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.data.status == "success"){
                getCategories();
                getInspirations();
          Swal.fire('Usunięto', '', 'success')
            }else{
                Swal.fire('Błąd', response.data.message, 'error');
            }
        }catch(e){
            
            Swal.fire('Błąd', e.message, 'error');
        }
        }
      })
}

const downloadVideo = (url) => {
  setDownloading(true);
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const urlObject = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', 'recording.mp4');
    link.setAttribute('target', '_blank')
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloading(false);
  });
};

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Filmy do tiktoków</span>
        <span className='text-slate-300 font-normal text-sm'>Poniżej znajdziesz foldery z filmami do pobrania, które możesz <strong>dowolnie</strong> używać do stworzenia tiktoków!</span>
        </div>
        <div onClick={() => navigate("/admin/addinspiration/video")}  className="bg-slate-900 cursor-pointer border border-solid border-slate-800 rounded-md text-white text-base font-normal p-2 flex justify-center flex-row items-center gap-2">
          <VideoCameraIcon className="w-5 h-5 text-sky-500"></VideoCameraIcon> Dodaj nowy film
        </div>
        <div onClick={() => navigate("/admin/addinspiration/folder")} className="bg-slate-900 cursor-pointer border border-solid border-slate-800 rounded-md text-white text-base font-normal p-2 mt-3 flex justify-center flex-row items-center gap-2">
          <FolderIcon className="w-5 h-5 text-sky-500"></FolderIcon> Dodaj nowy folder
        </div>
        <div className="text-white text-lg font-normal mt-5 mb-5">Foldery</div>
        <div className="grid grid-cols-2 gap-3">
        {categories.map((object, i) =>
      <div className="cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md justify-center flex-col gap-2 py-3 flex text-center">
        <div onClick={() => navigate("/admin/category/" + object.id)}>
        <div className=" flex justify-center">
          <FolderIcon className="w-12 h-12 text-sky-500"></FolderIcon>
</div>
<div className="text-slate-300 text-sm">{object.title}</div>

</div>
{object.id != "1" ? 
<div onClick={() => deleteInspiration(object.id, "folder")} className="bg-red-900/30 text-red-500 flex flex-row justify-center items-center text-base rounded-md m-2 p-1 cursor-pointer absolute -top-1 -right-1 hover:scale-105"><XMarkIcon className="text-red-500 h-5 w-5"></XMarkIcon></div>
: ""}
      </div>
)}
        </div>
        <div onClick={() => {getCategories(); setMore(!more)}} className="cursor-pointer bg-slate-900 inline-flex w-full justify-center items-center gap-x-1.5 rounded-xl px-2 py-2 text-sm font-semibold text-white mt-4 border border-solid border-slate-800">
        <div className='flex flex-row items-center gap-3'>
<span className='text-slate-300 text-base font-light'>{more ? "Pokaż mniej" : "Pokaż więcej"}</span>
</div>
{more ? 
  <ChevronUpIcon className="-mr-1 h-5 w-5 text-sky-500"></ChevronUpIcon>
:
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-sky-500" aria-hidden="true" />
        }
        </div>
        <div className="text-white text-lg font-normal mt-8 mb-5">Wszystkie filmy</div>
        <div></div>
      <div className="grid grid-cols-2 md:flex md:flex-row md:flex-wrap gap-3">

      {inspirations.videos.map((object, i) =>
      <>
    <div className="bg-slate-900 border-solid border border-slate-800 rounded-md md:w-40">
      {moment().diff(moment(object.createdAt), 'days') < 3 ? 
      <div className="text-xs bg-red-500 rounded-lg p-1 px-1.5 absolute -top-1 -right-2 z-3">🔥 NOWY</div>
      : ""}
    <div onClick={() => navigate("/admin/video/" + object.id)} className="vid rounded-md flex justify-center items-center cursor-pointer h-auto md:w-40" style={{aspectRatio: '9 / 13', background: 'center linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(' + process.env.REACT_APP_API_URL + "cover?filename=".concat(object.cover) +')', backgroundSize: "cover",}}><PlayCircleIcon className="text-sky-500 h-14 w-14 hover:scale-110 transition-transform"></PlayCircleIcon></div>
    <div onClick={() => downloadVideo(process.env.REACT_APP_API_URL + "video?filename=".concat(object.path))} className={"bg-slate-800 text-white flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer".concat(!downloading ? "" : " bg-slate-900 text-slate-400 opacity-70 cursor-not-allowed")}><ArrowDownTrayIcon className="text-sky-500 h-5 w-5"></ArrowDownTrayIcon>{downloading ? "Pobieranie..." : "Pobierz"}</div>
    <div onClick={() => deleteInspiration(object.id, "video")} className="bg-red-900/30 text-red-500 flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer"><XMarkIcon className="text-red-500 h-5 w-5"></XMarkIcon>Usuń</div>

  </div>
  </>
)}
      </div>

          </div>
          </motion.main>
    </>
        );

}

export default AdminInspirations;