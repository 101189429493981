import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Index = () => {
    const navigate = useNavigate();
    
return (<>
         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <div className="container mx-auto px-5 pb-2 h-full">
    <div id="bright"></div>
    <div id="bright-down"></div>
    <div className="flex flex-col justify-center items-center h-full">
        <div className="w-fit flex flex-col justify-center items-center">
    <span className="text-6xl mb-9">🏰</span>
    <span className="text-3xl font-bold text-white mb-1">WITAMY W</span>
    <span className="text-5xl font-bold text-sky-600 mb-7">IMPERIUM</span>
    <Link to="/dashboard" className="text-base font-medium bg-sky-600 rounded p-3 text-white w-full flex justify-center items-center cursor-pointer" >LET'S MAKE SOME MONEY</Link>
    </div>
    </div>
    </div>
    </motion.main>
    </>
)
}

export default Index;