import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect, Fragment, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, DocumentDuplicateIcon, DocumentIcon, FolderIcon, HandThumbUpIcon, PlayCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { downloadVideo } from "../utils/utils";
import moment from "moment";
import { Menu, Transition } from '@headlessui/react'
import AWSSoundPlayer from "../fragments/AudioPlayer";
import AudioPlayer from "../fragments/AudioPlayer";
import Tracks from "../fragments/Tracks";
import { useEffect } from "react";

const Music = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [sounds, setSounds] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const ref = useRef(null);
    const [searchParams] = useSearchParams();

    useLayoutEffect(() => {
        refreshToken();
        getAccounts();
        getSounds();
    }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const AddScroll = () => {
        if(searchParams.get("id")){
        const section = document.getElementById(searchParams.get("id").toString());
        section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
        }
      };

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

  const getSounds = async () => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'sounds', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    
    setSounds(response.data);
}

const likeSound = async (props) => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'likesound?id=' + props, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    
    getSounds();
}

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div className="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account !== undefined ? account.avatar : ""}></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-4 flex flex-col gap-1'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/helpful")}>Przydatne do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> Muzyka</span>
        <span className='text-slate-50 text-2xl'>Muzyka</span>
        </div>

        <div className="flex mt-4 flex-col gap-2">
        {sounds.map((object, i) =>
        <Menu as="div" id={object.id} className="relative inline-block text-left w-full mb-4 " ref={AddScroll}>
      <div>
        <Menu.Button className="w-full">
        <div className="flex flex-row gap-2 bg-slate-900 rounded-md p-3 items-center">
        <div className="p-3 bg-slate-800 rounded-lg">
        <DocumentDuplicateIcon className="text-sky-500 w-6 h-6"></DocumentDuplicateIcon>
        </div>
        <div className="flex flex-col mr-auto text-left">
            <div>
                {object.title}
            </div>
            <div className="text-xs text-slate-400">
            {object.author.length > 1 ? object.author : "Autor nieznany"}
            </div>
        </div>
        <div>
                <PlayCircleIcon className="text-sky-500 w-9 h-9" />
            </div>
        </div>
          
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="ring-0 focus:outline-none">
        <>
            <Menu.Item disabled>
              {({ close }) => (
                          <div className='flex flex-col items-center gap-2 bg-slate-900 p-3 rounded-lg mt-2'>
                                    <div className="flex flex-row w-full items-start">
                                    <div className="flex flex-col mr-auto">
            <div>
                {object.title}
            </div>
            <div className="text-xs text-slate-400">
            {object.author.length > 1 ? object.author : "Autor nieznany"}
            </div>
        </div>
        <div onClick={close}>
        <XMarkIcon className="text-white w-7 h-7"></XMarkIcon>
        </div>
        </div>
        <div className="flex flex-col w-full">
        <AudioPlayer track={object.path} />
        </div>
        <div onClick={() => {window.open(object.url, '_blank')}} className="p-2 bg-slate-800 text-slate-200 rounded-lg text-xs w-full flex justify-center items-center cursor-pointer">Zobacz na tiktoku</div>
        <div onClick={() => likeSound(object.id)} className={" bg-green-900/30 text-green-500 flex justify-center items-center gap-1 rounded-lg p-2 text-xs w-full ".concat(object.liked ? "opacity-40 cursor-not-allowed" : "cursor-pointer")}><HandThumbUpIcon className="w-4 h-4 text-green-500"></HandThumbUpIcon>{object.liked ? "Polubiono dźwięk" : "Polub dźwięk"}</div>

</div>
              )}
            </Menu.Item>
            </>
        </Menu.Items>
      </Transition>
    </Menu>
        )}
</div>

          </div>
          </motion.main>
    </>
        );

}

export default Music;