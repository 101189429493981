import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { motion } from "framer-motion";
import { i18n } from "dateformat";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Filler } from "chart.js";
import { CameraIcon, ChatBubbleOvalLeftEllipsisIcon, ChevronDoubleUpIcon, CurrencyDollarIcon, EyeIcon, HeartIcon, PlayIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import { options, optionsSec, short_formatter } from '../../utils/chart';
import Navbar from '../../components/Navbar';
import AdminNavbar from './AdminNavbar';
import dateFormat from 'dateformat';
import { Line } from 'react-chartjs-2';
import { Top, priceformatter } from '../../utils/utils';
ChartJS.register(Filler);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

i18n.dayNames = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

i18n.monthNames = [
  "St",
  "Lut",
  "Mrz",
  "Kw",
  "Maj",
  "Cz",
  "Lip",
  "Sier",
  "Wrz",
  "Paź",
  "Lis",
  "Gr",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


const AdminDashboard = () => {
    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [statistics, setStatistics] = useState([]);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartType, setChartType] = useState('views');

    const [chartDataSec, setChartDataSec] = useState([]);
    const [chartTypeSec, setChartTypeSec] = useState('views');
    const [period, setPeriod] = useState(7);
    const [labelsSec, setLabelsSec] = useState([]);
    const [toptiktoks, setTopTikToks] = useState([]);
    const formatter = Intl.NumberFormat('pl');

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            getStatistics();
            getTopTikToks();
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getStatistics = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/statistcs', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setStatistics(response.data);

        setLabels([]);
        setChartData([]);
        setLabelsSec([]);
        setChartDataSec([]);

        let stats = response.data.totalStats[0].slice(-period+1);

        response.data.totalStats[0].slice(-period+1).forEach(function (stat, i){
          if(i > 0){
          setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
          if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
          setChartData(chartData => [...chartData, stats[i].totalViews - stats[i-1].totalViews])
          }else{
            setChartData(chartData => [...chartData, 0])
          }
          }
        })

        setLoaded(true);
    }

    const getTopTikToks = async () => {
      const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/toptiktoks', {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      setTopTikToks(response.data);
  }


    function Card({title, percent, type}){
let icon;
let pill;
let stat;
      switch(title){
        case "Wyświetlenia":
           icon = <PlayIcon className='h-5 w-5 text-slate-400'></PlayIcon>;
           stat = short_formatter.format(statistics.totalViews) || 0;
           break;
        case "Obserwujący":
           icon = <UsersIcon className='h-5 w-5 text-slate-400'></UsersIcon>;
           stat = short_formatter.format(statistics.totalFollowers) || 0;
           break;
        case "Polubienia":
           icon = <HeartIcon className='h-5 w-5 text-slate-400'></HeartIcon>;
           stat = short_formatter.format(statistics.totalLikes) || 0;
           break;
        case "Komentarze":
          icon = <ChatBubbleOvalLeftEllipsisIcon className='h-5 w-5 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon>;
          stat = short_formatter.format(statistics.totalComments) || 0;
          break;
        
        }
        switch(type){
          case "red":
          pill = <div className='text-red-500 bg-red-900 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 font-bold">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
      </svg>
      {percent}%</div>;
             break;
          case "green":
          pill = <div className='text-green-500 bg-green-900 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 font-bold">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
      </svg>
      {percent}%</div>;
             break;
          }

        return (
            <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'>{icon} {title}</span>
                <span className='font-light text-white text-xl'>{stat}</span>                        </div>
        )
    }

    const data = {
      labels,
      datasets: [
        {
          data: chartData,
          borderColor: '#22C55E',
          pointRadius: 5,
          tension: 0.4,
          fill: 'start',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(34, 197, 94, 0.1)");
            gradient.addColorStop(0.75, "rgba(34, 197, 94, 0)");
            gradient.addColorStop(1, "transparent");
            return gradient;
          },
        },
      ],
    };

  const handleChart = async(type, time) => {
      setChartType(type);
      setPeriod(time);
      setLabels([]);
      setChartData([]);
      let stats = statistics.totalStats[0].slice(-time+1);
      switch(type){
          case "views":
              statistics.totalStats[0].slice(-time+1).forEach(function (stat, i){
                if(i > 0){
                  setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
                  if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
                  setChartData(chartData => [...chartData, stats[i].totalViews - stats[i-1].totalViews])
                  }else{
                    setChartData(chartData => [...chartData, 0])
                  }
                }
                })
                break;
          case "likes":
              statistics.totalStats[0].slice(-time+1).forEach(function (stat, i){
                if(i > 0){
                  setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
                  if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
                  setChartData(chartData => [...chartData, stats[i].totalLikes - stats[i-1].totalLikes])
                  }else{
                    setChartData(chartData => [...chartData, 0])
                  }
                }
                })
                break;
          case "comments":
              statistics.totalStats[0].slice(-time+1).forEach(function (stat, i){
                if(i > 0){
                  setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
                  if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
                  setChartData(chartData => [...chartData, stats[i].totalComments - stats[i-1].totalComments])
                  }else{
                    setChartData(chartData => [...chartData, 0])
                  }
                }
                })
                break;
          case "followers":
              statistics.totalStats[0].slice(-time+1).forEach(function (stat, i){
                if(i > 0){
                  setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
                  if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
                  setChartData(chartData => [...chartData, stats[i].totalFollowers - stats[i-1].totalFollowers])
                  }else{
                    setChartData(chartData => [...chartData, 0])
                  }
                }
                })
                break;
          default:
              statistics.totalStats[0].slice(-time+1).forEach(function (stat, i){
                if(i > 0){
                  setLabels(labels => [...labels, dateFormat(stat.date, "dd mmm")])
                  if(stats[i].totalViews - stats[i-1].totalViews < 5000000){
                  setChartData(chartData => [...chartData, stats[i].totalViews - stats[i-1].totalViews])
                  }else{
                    setChartData(chartData => [...chartData, 0])
                  }
                }
                })
                break;
      }
  }



    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
         <motion.main
   className="main__container"
   initial={{ display: "none", opacity: 0 }}
    animate={{ display: "flex", opacity: 1 }}
    exit={{ display: "none", opacity: 0 }}
    transition={{  duration: 1 }}
>
<AdminNavbar></AdminNavbar>
        <div className="container mx-auto px-5 pb-12">
          <div id="bright"></div>
        <div className='pb-10 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Witaj Ponownie!</span>
        <span className='text-slate-300 font-normal text-sm'>Sprawdź jak idzie rozwój użytkowników platformy.</span>
        </div>

        {chartDataSec.toString()}
        {labelsSec.toString()}


                        <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 mb-4'>
                <span className='text-white font-light text-base flex flex-row items-center gap-1'><CurrencyDollarIcon className='w-6 h-6 text-slate-400'/> Zarobki z wyświetleń</span>
                <span className='font-light text-white text-2xl'>{priceformatter.format(statistics.viewsEarnings)}</span>
                {/*<div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs absolute bottom-0 right-0 m-3'>- 0,00%</div>*/}
                        </div>



            <Masonry columnsCount={2} gutter="1rem">
            <Card title="Wyświetlenia" percent="15,00" type="green"/>
                <Card title="Obserwujący" percent="15,00" type="red"/>
            <Card title="Polubienia" percent="15,00" type="green"/>
            <Card title="Komentarze" percent="15,00" type="red"/>
            </Masonry>
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                style={{marginTop:"1rem"}}
            >
            <Masonry gutter="1rem">
            <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'><EyeIcon className='w-5 h-5 text-slate-400'></EyeIcon> Filmy</span>
                <span className='font-light text-white text-xl'>{short_formatter.format(statistics.videoCount)}</span>

                        </div>         
            <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'><EyeIcon className='w-5 h-5 text-slate-400'></EyeIcon> Wyświetlenia w 24h</span>
                <span className='font-light text-white text-xl'>{short_formatter.format(statistics.dayViews)}</span>

                        </div>

                        <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'><ChevronDoubleUpIcon className='w-5 h-5 text-slate-400'></ChevronDoubleUpIcon> Najlepszy TikTok (24h)</span>
                <div onClick={() => navigate(`/admin/tiktok/${statistics.topTikTok.id}`)} className=' gap-4 flex justify-start items-center rounded-lg flex-row cursor-pointer'>
        <div>
          <div className=' w-16 h-28 rounded-lg !bg-cover !bg-no-repeat' style={{background: `url(${statistics.topTikTok.cover}), url("https://jmmplatform.pl/waiting.png")`}}>
            <img className='w-6 h-6 rounded-xl -top-1 -right-2 absolute' onError={() => {this.onerror=null; this.src='https://jmmplatform.pl/waiting.png'}} src={statistics.topTikTok.account_tiktok.avatar} alt="Zdjęcie profilowe"></img>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='text-slate-400 text-sm font-light flex items-center'>{new Date(parseInt(statistics.topTikTok.createTime)*1000).toLocaleString()}</span>
          <div className='font-light text-white text-2xl flex flex-row gap-2 flex-wrap'>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><HeartIcon className='h-4 w-4 text-slate-400'></HeartIcon> {short_formatter.format(statistics.topTikTok.likes)}</span>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon> {short_formatter.format(statistics.topTikTok.views)}</span>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><ChatBubbleOvalLeftEllipsisIcon className='h-4 w-4 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon> {short_formatter.format(statistics.topTikTok.comments)}</span>
           <span className='flex flex-row text-sm gap-1 items-center break-keep'><UserIcon className='h-4 w-4 text-slate-400'></UserIcon> {statistics.topTikTok.account_tiktok.username}</span>
          </div>
            </div>
                  </div>

                        </div>

                        <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'><ChevronDoubleUpIcon className='w-5 h-5 text-slate-400'></ChevronDoubleUpIcon> Najlepsze konto (24h)</span>
                <div onClick={() => navigate(`/admin/account/${statistics.topAccount.id}`)} className=' gap-4 flex justify-start items-center rounded-lg flex-row cursor-pointer'>
        <div>
        <img className='w-8 h-8 rounded-full' src={statistics.topAccount.avatar}/>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='font-light text-white text-2xl flex flex-row gap-2 flex-wrap'>
          <span className='flex flex-row text-sm gap-1 items-center break-keep'><UserIcon className='h-4 w-4 text-slate-400'></UserIcon> {statistics.topAccount.username}</span>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon> {short_formatter.format(statistics.topAccountCount)}</span>          
          </div>
            </div>
                  </div>

                        </div>

                        <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-sm flex flex-row items-center gap-1'><CurrencyDollarIcon className='w-5 h-5 text-slate-400'></CurrencyDollarIcon> Szacunkowe zarobki</span>
                <span className='font-light text-white text-xl'>{priceformatter.format(statistics.earnings)}</span>
                <div className='flex flex-col gap-1.5'>
                <span className='text-slate-400 text-xs'>Łączna ilośc zakupów</span>
                <span className='font-light text-white text-xl'>{short_formatter.format(statistics.sold)}</span>
                </div>

                        </div>
            </Masonry>
            </ResponsiveMasonry>
            <div className="flex flex-col gap-3 mt-6 justify-center items-center">
        <div className=' h-72 w-full flex justify-center items-center mt-4'>
                  {labels.length > 1 ?
                <Line options={options} data={data} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                <div className="flex flex-row gap-1 mt-4 flex-wrap justify-center">
        <div onClick={() => {handleChart("views", period)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(chartType == "views" ? "bg-green-900" : "bg-slate-800")}>Wyświetlenia</div>
        <div onClick={() => {handleChart("likes", period)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(chartType == "likes" ? "bg-green-900" : "bg-slate-800")}>Polubienia</div>
        <div onClick={() => {handleChart("comments", period)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(chartType == "comments" ? "bg-green-900" : "bg-slate-800")}>Komentarze</div>        
        <div onClick={() => {handleChart("followers", period)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(chartType == "followers" ? "bg-green-900" : "bg-slate-800")}>Obserwujący</div>        
        <div onClick={() => {handleChart(chartType, 7)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(period == 7 ? "bg-green-900" : "bg-slate-800")}>7 dni</div>
        <div onClick={() => {handleChart(chartType, 14)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(period == 14 ? "bg-green-900" : "bg-slate-800")}>14 dni</div>
        <div onClick={() => {handleChart(chartType, 30)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(period == 30 ? "bg-green-900" : "bg-slate-800")}>30 dni</div>        
        <div onClick={() => {handleChart(chartType, 0)}} className={"p-2 text-xs rounded-xl cursor-pointer ".concat(period == 0 ? "bg-green-900" : "bg-slate-800")}>Od zawsze</div>        
        </div>
                </div>
                <div className='flex flex-col md:flex-row md:gap-5'>
                  <div className='w-full md:w-1/2'>
            <div className="text-white text-lg font-normal mt-7 mb-4">Ranking</div>
            <div className='flex flex-col gap-3'>
            {statistics.topUsers.map((object, i) =>

                <div onClick={() => navigate("/admin/user/" + object.id)} className='cursor-pointer p-2 flex flex-row justify-between items-center bg-slate-900 rounded-lg'>
                  {i < 3 ? <div className={`text-xs rounded-full w-5 flex justify-center items-center h-5 absolute -top-2 -left-2 z-3 ${i+1 == 1 ? "bg-yellow-500" : ""}${i+1 == 2 ? "bg-gray-400" : ""}${i+1 == 3 ? "bg-yellow-900" : ""}`}>{i+1}</div> : ""}
                  <div className='flex flex-row gap-3 items-center text-xs'>
                <img className='w-8 h-8 rounded-full' src={object.avatar}/>
                <span className=' text-ellipsis whitespace-nowrap overflow-hidden'>{object.nick}</span>
                </div>
                <div className='flex gap-3'>
                <div className='flex flex-row gap-2 items-center break-keep text-white w-max'>
                <CameraIcon className='h-4 w-4 text-slate-400'></CameraIcon><span className='text-xs'>{object.tiktokCount} filmów</span>
                </div>
                <div className='flex flex-row gap-1 items-center break-keep text-white w-max'>
                <PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon><span className='text-xs'>{short_formatter.format(object.totalViews)}</span>
                </div>
                </div>
                </div>
            )}
            </div>
            </div>
            <div className='w-full md:w-1/2'>
            <div className="text-white text-lg font-normal mt-7 mb-4">TOP zasięgowe tiktoki</div>
                        <div className='flex flex-col gap-2'>
                        {toptiktoks.length > 0 ?
                        toptiktoks.slice(0, -(Math.floor(statistics.topUsers.length-1/1.5))).map((object, i) =>
                        <>
                        <Top admin={true} account={object.account_tiktok} username={object.account_tiktok.username} id={object.id} avatar={object.account_tiktok.avatar} cover={object.cover} likes={short_formatter.format(object.likes)} comments={short_formatter.format(object.comments)} views={short_formatter.format(object.views)} date={new Date(parseInt(object.createTime)*1000).toLocaleString()}></Top>
                       </>) : ""}
         </div>
            </div>
            </div>
         </div>
        </motion.main>
        </>
    )
}

export default AdminDashboard
