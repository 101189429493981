import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import moment from "moment";
import AdminNavbar from "./AdminNavbar";

const AdminVideo = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [inspiration, setInspiration] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getVideo();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getVideo = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'inspiration?id=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setInspiration(response.data);
    }

    async function deleteInspiration(id, type){
        console.log(id);
        console.log(type);
    }

    const downloadVideo = (url) => {
        setDownloading(true);
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const urlObject = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = urlObject;
          link.setAttribute('download', 'recording.mp4');
          link.setAttribute('target', '_blank')
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloading(false);
        });
      };

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="flex flex-col container mx-auto px-6 h-full max-h-full mb-7">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/admin/inspirations")}>Filmy do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>{inspiration.category_title}</span>
        </div>
        <div className="flex flex-col mb-6 justify-center h-full relative">

        <video className="rounded-lg flex w-full h-full absolute object-contain" id="video" loop autoPlay controls>
   <source src={process.env.REACT_APP_API_URL + "video?filename=".concat(inspiration.path)}/>
   Your browser does not support the video tag.
</video> 
</div>
<div onClick={() => downloadVideo(process.env.REACT_APP_API_URL + "video?filename=".concat(inspiration.path))} className={"bg-slate-800 text-white flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer".concat(!downloading ? "" : " bg-slate-900 text-slate-400 opacity-70 cursor-not-allowed")}><ArrowDownTrayIcon className="text-sky-500 h-5 w-5"></ArrowDownTrayIcon>{downloading ? "Pobieranie..." : "Pobierz"}</div>
<div onClick={() => deleteInspiration(inspiration.id, "video")} className="bg-red-900/30 text-red-500 flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer"><XMarkIcon className="text-red-500 h-5 w-5"></XMarkIcon>Usuń</div>

        </div>
    </motion.main>
    </>
        );

}

export default AdminVideo;