import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon, ExclamationTriangleIcon, CurrencyDollarIcon, CheckCircleIcon, EyeIcon, HeartIcon, ShareIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { options, short_formatter } from "../utils/chart";
import { Modal } from "../fragments/Modal";
import { downloadVideo, priceformatter } from "../utils/utils";
import moment from "moment";
import { Line } from "react-chartjs-2";
import dateFormat from 'dateformat';

const Earnings = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [labels2, setLabels2] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [user, setUser] = useState([]);
    const [stats, setStats] = useState([]);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getUser();
            await getStats();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getStats = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'earningstats', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setStats(response.data);

        setLabels2([]);
        setChartData2([]);
        response.data.userRefsEarnings.forEach(function (object, i){
          setLabels2(labels => [...labels, dateFormat(new Date(object.date), "dd mmm")])
          setChartData2(chartData => [...chartData, object.price]);
          });

          setLabels([]);
          setChartData([]);
          response.data.userViewsEarnings.forEach(function (object, i){
            setLabels(labels => [...labels, dateFormat(new Date(object.date), "dd mmm")])
            setChartData(chartData => [...chartData, object.price]);
            });

    }

    const getUser = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setUser(response.data);
        setAccount(response.data.accounts[0])
    }

    const data = {
        labels: labels,
        datasets: [
          {
            data: chartData,
            borderColor: '#22C55E',
            pointRadius: 5,
            tension: 0.4,
            fill: 'start',
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(34, 197, 94, 0.1)");
              gradient.addColorStop(0.75, "rgba(34, 197, 94, 0)");
              gradient.addColorStop(1, "transparent");
              return gradient;
            },
          },
        ],
      };

      const data2 = {
        labels: labels2,
        datasets: [
          {
            data: chartData2,
            borderColor: '#22C55E',
            pointRadius: 5,
            tension: 0.4,
            fill: 'start',
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(34, 197, 94, 0.1)");
              gradient.addColorStop(0.75, "rgba(34, 197, 94, 0)");
              gradient.addColorStop(1, "transparent");
              return gradient;
            },
          },
        ],
      };

    const acceptRules = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'acceptrules', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        await getUser();
      }


    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);
if(!user.rules_accepted){
  return (
  <motion.main
  className="main__container"
  initial={{ display: "none",opacity: 0 }}
   animate={{ display: "flex",opacity: 1 }}
   exit={{ display: "none",opacity: 0 }}
   transition={{ duration: 1 }}
>
<Navbar avatar={account.avatar} username={account.username} account={account}></Navbar>
   <div className="container mx-auto px-6 pb-12">
         <div id="bright"></div>
         <div className='bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mb-4 border border-slate-700'>
<div className='flex flex-row gap-2 items-center text-white'>
<ExclamationTriangleIcon className='w-6 h-6 text-slate-300'/>
<div>Musisz zaakceptować regulamin</div>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
- Tiktoki dodajemy na bierząco, nie dodajemy starych filmów,<br></br>
- Na każdym tiktoku oznaczamy Truemana w opisie lub piszemy jego nazwę na filmie,<br></br>
- Zabronione jest kopiowanie od kogoś tiktoków 1:1,<br></br>
- Na platforme dodajemy tylko tiktoki z wizuerunkiem Truemana.
</div>
<button onClick={acceptRules} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Zaakceptuj regulamin</button>

</div>
         </div>
         </motion.main>
  );
}else{
    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<Navbar avatar={account.avatar} username={account.username} account={account}></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
           <div className="bg-slate-900 p-3 flex flex-row rounded-lg gap-6 justify-center items-center border border-solid border-slate-800 hidden">
            <div>
            <div class="triangle" style={{background: `linear-gradient(180deg, ${stats.userViews >= 10000000 ? '#22c55e' : '#1e293b'} 0%, ${stats.userViews >= 10000000 ? '#22c55e' : '#1e293b'} 33%, ${stats.userViews >= 5000000 ? '#22c55e' : '#1e293b'} 33%, ${stats.userViews >= 5000000 ? '#22c55e' : '#1e293b'} 66%, ${stats.userViews >= 1500000 ? '#22c55e' : '#1e293b'} 66%, ${stats.userViews >= 1500000 ? '#22c55e' : '#1e293b'} 100%)`}}>
                <div className="h-1/3 flex justify-center items-center border-b-4 border-slate-900 text-xs"><EyeIcon className="w-3 h-3 mt-1 text-white"></EyeIcon></div>
                <div className="h-1/3 flex justify-center items-center border-b-4 border-slate-900 text-xs">5M</div>
                <div className="h-1/3 flex justify-center items-center text-xs">1.5M</div>
</div>
            </div>
            <div className="flex flex-col justify-center items-start w-full gap-2">
            <div className="text-sm text-white text-center flex justify-center w-full">
                {stats.userViews >= 10000000 ? "Osiągnięto już wszystkie poziomy!" :
                <>
                Brakuje Ci 
                {/* 0-1500000 */}{stats.userViews < 1500000 ? ' ' + short_formatter.format(1500000-stats.userViews) + ' ' : ''} 
                {/* 1500000-5000000 */}{stats.userViews >= 1500000 && stats.userViews < 5000000 ? ' ' + short_formatter.format(5000000-stats.userViews) + ' ' : ''}
                {/* 5000000-10000000 */}{stats.userViews >= 5000000 && stats.userViews < 10000000 ? ' ' + short_formatter.format(10000000-stats.userViews) + ' ' : ''}  
                wyświetleń do następnego bonusu
                </>
    }
            </div>
            </div>
           </div>
           {/*<div className="text-white text-lg font-normal mt-4 mb-4">Dodatkowe nagrody</div>
            <div className="flex flex-row gap-2">
                <div className="w-1/3 bg-slate-900 p-2 flex flex-col rounded-lg items-center gap-2 justify-center text-sm text-center">
                        Najpopularniejsze profile
                </div>

                <div className="w-1/3 bg-slate-900 p-2 flex flex-col rounded-lg gap-2 justify-center items-center text-sm text-center">

                        Najbardziej lajkowane filmy
                </div>

                <div className="w-1/3 bg-slate-900 p-2 flex flex-row rounded-lg items-center gap-2.5 justify-center text-sm text-center">
                        Najpopularniejsze filmy
                </div>
  </div>*/}
            <div className=' gap-3 py-3 px-3 mt-4 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <div className='flex justify-between items-center'>
                <span className='font-normal text-xs text-slate-200'>Zarobki z wyświetleń</span>
                <span className='font-normal text-slate-500 text-xs'>{priceformatter.format(user.viewsEarnings)}</span>
                </div>
                <div className='h-44 w-full flex justify-center items-center'>
                  {labels.length >= 1 ?
                <Line options={options} data={data} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                        </div>
                        <div className=' gap-3 py-3 px-3 mt-4 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <div className='flex justify-between items-center'>
                <span className='font-normal text-xs text-slate-200'>Zarobki z reflinków</span>
                <span className='font-normal text-slate-500 text-xs'>{priceformatter.format(stats.userRefs)}</span>
                </div>
                <div className='h-44 w-full flex justify-center items-center'>
                  {labels2.length >= 1 ?
                <Line options={options} data={data2} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                        </div>
                        <div onClick={() => navigate("/payout")} className="mt-7 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-sm font-normal p-2.5 flex justify-center flex-row items-center gap-2">
                        Wypłaty
        </div>
                        <div onClick={() => navigate("")} className="opacity-40 mt-4 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-sm font-normal p-2.5 flex justify-center flex-row items-center gap-2">
                        Jak zarabiać więcej?
        </div>
        </div>
    </motion.main>
    </>
        );
                  }
}

export default Earnings;