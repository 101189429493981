import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';
import AdminNavbar from "./AdminNavbar";

const AdminTinderSwipe = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [tiktoks, setTikToks] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();

    const iframe_container = {
        left: 0,
                  width: "100%",
                  position: "relative",
                }

const iframe ={
height: "inherit",
border: 0}

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const getTikToks = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/tiktoks?status=waiting', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setTikToks(response.data);
        setLoaded(true);
    }

    const reject = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz odrzucić tego tiktoka?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewtiktok?id=' + tiktoks[0].id + '&action=reject', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getTikToks();
              Swal.fire('Odrzucono tiktoka', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const accept = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz zaakceptować tego tiktoka?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewtiktok?id=' + tiktoks[0].id + '&action=accept', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                if(response.data.status == "success"){
                    getTikToks();
              Swal.fire('Zaakceptowao tiktoka', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    useEffect(() => {
        refreshToken();
        getTikToks();
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12 h-full">
          <div id="bright"></div>
    <div className="flex flex-col gap-4 h-full">
    {tiktoks.length > 0 ? 
          <div className="flex justify-center items-center h-full flex-col gap-2" style={iframe_container}>
          <iframe
            src={"https://www.tiktok.com/embed/" + tiktoks[0].tiktok_id}
            style={iframe}
            className="bg-slate-900"
            allowfullscreen
            scrolling="no"
            allow="encrypted-media;"
          ></iframe>
          <div className="flex flex-row gap-2 w-full">
        <div onClick={accept} className="w-1/2 bg-green-900 cursor-pointer border border-solid border-green-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Zatwierdź
        </div>
        <div onClick={reject} className="w-1/2 bg-red-900 cursor-pointer border border-solid border-red-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Odrzuć
        </div>
        </div>
        </div> : <span className="text-slate-400 text-base text-center">Brak tiktoków</span>}</div>
    </div>
    </motion.main>
    </>
        );

}

export default AdminTinderSwipe;