import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownIcon, ArrowDownTrayIcon, ArrowUpIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, DocumentDuplicateIcon, DocumentIcon, FolderIcon, PencilIcon, PlayCircleIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import AdminNavbar from "./AdminNavbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';

const AdminQuotations = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [quotations, setQuotations] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const MySwal = withReactContent(Swal)

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getQuotations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

  const getQuotations = async () => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'quotations', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    
    setQuotations(response.data);
}

const deleteQuota = async (props) => {
    MySwal.fire({
        title: <strong>UWAGA</strong>,
        html: 'Czy na pewno chcesz usunąć ten cytat?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText:'Tak',
        cancelButtonText: 'Anuluj'
      }).then(async (result) => {
        if (result.isConfirmed) {
            try{
            const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/delQuota?id=' + props, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.data.status == "success"){
                getQuotations();
          Swal.fire('Usunięto cytat', '', 'success')
            }else{
                Swal.fire('Błąd', response.data.message, 'error');
            }
        }catch(e){
            
            Swal.fire('Błąd', e.message, 'error');
        }
        }
      })
}

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div className="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-4 flex flex-col gap-1'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/admin/helpful")}>Przydatne do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> Motywacyjne cytaty</span>
        <span className='text-slate-50 text-2xl'>Motywujące cytaty</span>
        </div>
        <button onClick={() => navigate("/admin/addquota")} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-base py-2 px-4 rounded w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj cytat</button>

        <div className="flex mt-4 flex-col gap-2">
        {quotations.map((object, i) =>
        <div key={i} className="flex bg-slate-900 border border-1 border-solid border-slate-800 rounded-lg flex-col p-3 gap-3">
        <span className="text-slate-200 text-sm">
        {object.text}
        </span>
        <span className="text-slate-200 text-xs">
        Polubień: {object.likes}
        </span>
        <div onClick={() => navigate("/admin/editquota/" + object.id)} className="cursor-pointer bg-slate-800 flex justify-center items-center gap-2 rounded-lg p-2 text-base"><PencilIcon className="w-5 h-5 text-sky-500"></PencilIcon>Edytuj cytat</div>
        <div onClick={() => deleteQuota(object.id)} className="cursor-pointer bg-red-900/30 text-red-500 flex justify-center items-center gap-2 rounded-lg p-2 text-base"><XMarkIcon className="w-5 h-5 text-red-500"></XMarkIcon>Usuń cytat</div>
        </div>
        )}
        </div>

          </div>
          </motion.main>
    </>
        );

}

export default AdminQuotations;