import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, BanknotesIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Modal } from "../fragments/Modal";

const AddPayments = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('blik');
    const [modal, setModal] = useState({ show: false, data: null });
    const [user, setUser] = useState({});
    const [button, setButton] = useState(true);
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };


    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const handlePaymentChange = async(payment) => {
        setPaymentMethod(payment);
        if(user.payment_methods){
            if(user.payment_methods[payment]){
                setNumber(user.payment_methods[payment].number);
                setName(user.payment_methods[payment].name);
            }else{
                setNumber('');
                setName('');
            }
        }
    }

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            await getUser();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getUser = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setUser(response.data);
        if(response.data.payment_methods){
            if(response.data.payment_methods['blik']){
                setNumber(response.data.payment_methods['blik'].number);
                setName(response.data.payment_methods['blik'].name);
            }
        }
    }

    const addPayment = async () => {
        if(button){
        setButton(false);
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'addpayment?method=' + paymentMethod + '&number=' + number + '&name=' + name, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.status == "success"){
            setModal({ show: true, data: { type: 'addPayment' } });
        
        }else{
            setModal({ show: true, data: { type: 'error', message: response.data.message } });
        }
        setButton(true);
    }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/profile")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Dodaj metodę płatności</span>

<XMarkIcon onClick={() => navigate("/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    <div className="text-lg mb-3">Metoda płatności</div>
<div className="flex flex-col gap-2">
    <div onClick={() => handlePaymentChange("blik")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "blik" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Blik na telefon</span>
        </div>
        <div>
            <img className="w-9 h-auto" src="https://jmmplatform.pl/blik.png"/>
        </div>
    </div>
    <div onClick={() => handlePaymentChange("przelew")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "przelew" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Przelew</span>
        </div>
        <div>
            <BanknotesIcon className="text-slate-400 w-5 h-auto"></BanknotesIcon>
        </div>
    </div>
    <div onClick={() => handlePaymentChange("bitcoin")} className="cursor-pointer p-2.5 rounded-lg flex flex-row justify-between items-center bg-slate-900 border border-solid border-slate-800">
        <div className="flex flex-row gap-1.5">
            <div className="w-6 h-6 border border-solid border-slate-700 rounded-full flex justify-center items-center">
                <div className={'w-3.5 h-3.5 rounded-full '.concat(paymentMethod == "bitcoin" ? "bg-sky-500" : '')}></div>
            </div>
        <span className="text-base">Bitcoin</span>
        </div>
        <div>
            <img className="w-5 h-auto" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"/>
        </div>
    </div>
</div>
        <div className="text-lg mt-5">Dane do płatności</div>
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Imię i nazwisko</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="np. Jan Kowalski" value={name} onChange={(e) => setName(e.target.value)}/>

    <div className="text-slate-300 text-base font-normal mt-3 mb-1">{paymentMethod == "blik" ? "Numer telefonu" : ''} {paymentMethod == "przelew" ? "Numer rachunku" : ''} {paymentMethod == "bitcoin" ? "Adres portfela" : ''}</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder={paymentMethod == "blik" ? "np. 513 012 012" : '' || paymentMethod == "przelew" ? "np. 49 1020 2892 2276 3005 0000 0000" : '' || paymentMethod == "bitcoin" ? "np. 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa" : ''} value={number} onChange={(e) => setNumber(e.target.value)}/>    
        
        <button onClick={addPayment} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> {button ? "Dodaj metodę płatności" : "Dodawanie metody płatności..."}</button>
 
        </div>
    </motion.main>
    </>
        );

}

export default AddPayments;