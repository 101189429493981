import { ArrowLeftIcon, ArrowRightIcon, PauseCircleIcon, PauseIcon, PlayCircleIcon, PlayIcon } from "@heroicons/react/24/outline";
import React from "react";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick
}) => (
    <>
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        <PauseCircleIcon className="w-5 h-5 text-sky-500"/>
      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
       <PlayCircleIcon className="w-5 h-5 text-sky-500"/>
      </button>
    )}
    </>
);

export default AudioControls;
