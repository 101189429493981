import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Index from "./components/Index";
import Inspirations from "./components/Inspirations";
import Login from "./components/Login";
import { AnimatePresence } from "framer-motion";
import MyTiktoks from "./components/MyTiktoks";
import TikTokPage from "./components/TikTokPage";
import AddTikTok from "./components/AddTikTok";
import Profile from "./components/Profile";
import AddProfile from "./components/AddProfile";
import AdminDashboard from "./admin/components/AdminDashboard";
import AdminUsers from "./admin/components/AdminUsers";
import AdminUser from "./admin/components/AdminUser";
import AdminAccount from "./admin/components/AdminAccount";
import AdminAccounts from "./admin/components/AdminAccounts";
import AdminTikTok from "./admin/components/AdminTikTok";
import AdminTikToks from "./admin/components/AdminTikToks";
import AdminTinderSwipe from "./admin/components/AdminTinderSwipe";
import AdminAddUser from "./admin/components/AdminAddUser";
import ChangePassword from "./components/ChangePassword";
import Register from "./components/Register";
import Category from "./components/Category";
import Video from "./components/Video";
import AdminInspirations from "./admin/components/AdminInspirations";
import AdminAddInspiration from "./admin/components/AdminAddInspiration";
import AdminVideo from "./admin/components/AdminVideo";
import AdminCategory from "./admin/components/AdminCategory";
import Helpful from "./components/Helpful";
import Quotations from "./components/Quotations";
import AdminHelpful from "./admin/components/AdminHelpful";
import AdminQuotations from "./admin/components/AdminQuotations";
import AdminAddQuota from "./admin/components/AdminAddQuota";
import AdminEditQuota from "./admin/components/AdminEditQuota";
import Music from "./components/Music";
import Earnings from "./components/Earnings";
import AddContact from "./components/AddContact";
import AdminAddSound from "./admin/components/AdminAddSound";
import AdminMusic from "./admin/components/AdminMusic";
import Tutorials from "./components/Tutorials";
import Tutorial from "./components/Tutorial";
import AddPayments from "./components/AddPayments";
import TopDay from "./components/TopDay";
import AdminRefs from "./admin/components/AdminRefs";
import Payout from "./components/Payout";
import AdminPayouts from "./admin/components/AdminPayouts";

function App() {
  const location = useLocation();
  return (
    <>
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Login/>}>
        </Route>
        <Route path="/login" element={<Login/>}>
        </Route>
        <Route path="/register" element={<Register/>}>
        </Route>
        <Route path="/start" element={<Index/>}>
        </Route>
        <Route path="/dashboard" element={<Dashboard/>}>
        </Route>
        <Route path="/profile" element={<Profile/>}>
        </Route>
        <Route path="/addprofile" element={<AddProfile/>}>
        </Route>
        <Route path="/addpayment" element={<AddPayments/>}>
        </Route>
        <Route path="/addcontact" element={<AddContact/>}>
        </Route>
        <Route path="/mytiktoks" element={<MyTiktoks/>}>
        </Route>
        <Route path="/topday" element={<TopDay/>}>
        </Route>
        <Route path="/addtiktok" element={<AddTikTok/>}>
        </Route>
        <Route path="/tiktok/:id" element={<TikTokPage/>}>
        </Route>
        <Route path="/inspirations" element={<Inspirations/>}>
        </Route>
        <Route path="/helpful" element={<Helpful/>}>
        </Route>
        <Route path="/quotations" element={<Quotations/>}>
        </Route>
        <Route path="/music" element={<Music/>}>
        </Route>
        <Route path="/earnings" element={<Earnings/>}>
        </Route>
        <Route path="/tutorials" element={<Tutorials/>}>
        </Route>
        <Route path="/tutorial/:id" element={<Tutorial/>}>
        </Route>
        <Route path="/category/:id" element={<Category/>}>
        </Route>
        <Route path="/video/:id" element={<Video/>}>
        </Route>
        <Route path="/admin/dashboard" element={<AdminDashboard/>}>
        </Route>
        <Route path="/admin/users" element={<AdminUsers/>}>
        </Route>
        <Route path="/admin/user/:id" element={<AdminUser/>}>
        </Route>
        <Route path="/admin/account/:id" element={<AdminAccount/>}>
        </Route>
        <Route path="/admin/accounts" element={<AdminAccounts/>}>
        </Route>
        <Route path="/admin/tiktok/:id" element={<AdminTikTok/>}>
        </Route>
        <Route path="/admin/tiktoks" element={<AdminTikToks/>}>
        </Route>
        <Route path="/admin/tiktoktinder" element={<AdminTinderSwipe/>}>
        </Route>
        <Route path="/admin/adduser" element={<AdminAddUser/>}>
        </Route>
        <Route path="/admin/inspirations" element={<AdminInspirations/>}>
        </Route>
        <Route path="/admin/addinspiration/:type" element={<AdminAddInspiration/>}>
        </Route>
        <Route path="/admin/video/:id" element={<AdminVideo/>}>
        </Route>
        <Route path="/changepassword" element={<ChangePassword/>}>
        </Route>
        <Route path="/admin/category/:id" element={<AdminCategory/>}>
        </Route>
        <Route path="/admin/helpful" element={<AdminHelpful/>}>
        </Route>
        <Route path="/admin/quotations" element={<AdminQuotations/>}>
        </Route>
        <Route path="/admin/music" element={<AdminMusic/>}>
        </Route>
        <Route path="/admin/addquota" element={<AdminAddQuota/>}>
        </Route>
        <Route path="/admin/addsound" element={<AdminAddSound/>}>
        </Route>
        <Route path="/admin/editquota/:id" element={<AdminEditQuota/>}>
        </Route>
        <Route path="/admin/refs" element={<AdminRefs/>}>
        </Route>
        <Route path="/payout" element={<Payout/>}>
        </Route>
        <Route path="/admin/payouts" element={<AdminPayouts/>}>
        </Route>
      </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
