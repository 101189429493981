import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlusIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { classNames, TikTok } from "../utils/utils";
import { short_formatter } from "../utils/chart";

const MyTiktoks = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [tiktoks, setTikToks] = useState([]);
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const setCurrentAccount = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'account?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setAccount(response.data);
        setTikToks(response.data.tiktoks)
    }

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setAccounts(response.data);
        if(response.data.length > 0){
        setAccount(response.data[0])
        setTikToks(response.data[0].tiktoks)
        }
    }

    useEffect(() => {
        refreshToken();
        getAccounts();
        setLoaded(true);
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>Moje tiktoki</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz sprawdzić lub dodać tiktoki.</span>
        </div>
        {account.username ?
        <>
        <button onClick={() => navigate("/addtiktok")} className="mb-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-base py-2 px-4 rounded w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj TikToka</button>
        <Menu as="div" className="relative inline-block text-left w-full mb-4 ">
      <div>
        <Menu.Button className=" bg-slate-900 border-slate-800 border border-solid inline-flex w-full justify-between items-center gap-x-1.5 rounded-xl px-3 py-3 text-sm font-semibold text-white">
        <div className='flex flex-row items-center gap-3'>
        <img className='w-6 h-6 rounded-3xl border border-solid border-slate-800' src={account.avatar}/>
<span className='text-slate-300 text-base font-light'>{account.username}</span>
</div>
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items  className="bg-slate-900 absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none boxshadow">
        {accounts.map((object, i) =>
        <>
            <Menu.Item key={i}>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => setCurrentAccount(object.id)}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block px-2 py-3 text-sm rounded-md text-slate-300'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
        <img className='w-6 h-6 rounded-3xl border border-solid border-gray-500' src={object.avatar}/>
<span className='font-normal text-sm'>{object.username}</span>
</div>
                </a>
              )}
            </Menu.Item>
            </>
            )}
        </Menu.Items>
      </Transition>
    </Menu>
    </>
: ""}
    <div className="flex flex-col md:grid-cols-2 md:grid gap-2 w-full">
    {tiktoks.length > 0 ? tiktoks.map((object, i) =>
    <TikTok id={object.id} avatar={account.avatar} cover={object.cover} likes={short_formatter.format(object.likes)} comments={short_formatter.format(object.comments)} views={short_formatter.format(object.views)} status={object.status} date={new Date(parseInt(object.createTime)*1000).toLocaleString()}></TikTok>
    ) : <span className="text-slate-400 text-base text-center">Nie masz jeszcze żadnych tiktoków!</span>}</div>
    </div>
    </motion.main>
    </>
        );

}

export default MyTiktoks;