import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { short_formatter } from "../utils/chart";
import { Modal } from "../fragments/Modal";
import { VideoComponent, downloadVideo } from "../utils/utils";
import moment from "moment";

const Category = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [inspirations, setInspirations] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [offset, setOffset] = useState(10);
    const navigate = useNavigate();
    let { id } = useParams();


    const [modal, setModal] = useState({ show: false, data: null });

    const openDelete = () => {
      setModal({ show: true, data: { type: 'deleteTiktok', id: id } });
    };
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getInspirations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const moreVideos = async () => {
        let newOffset = offset+10;
        setOffset(newOffset);
        }

    const getInspirations = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'inspirations?category=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setInspirations(response.data);
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/inspirations")}>Filmy do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> {id == 0 ? "Wszystkie nowe" : inspirations.category}</span>
        </div>
        {inspirations.videos.length < 1 ? <div className="flex justify-center"><span className="text-slate-400 text-base">W tej kategorii nie ma jeszcze filmów</span></div> : ""}
        <div className="grid grid-cols-2 md:flex md:flex-row md:flex-wrap gap-3">
{inspirations.videos.slice(0, offset).map((object, i) =>
    <VideoComponent object={object}/>
)}
</div>
<div onClick={() => {moreVideos()}} className="cursor-pointer bg-slate-900 inline-flex w-full justify-center items-center gap-x-1.5 rounded-xl px-2 py-2 text-sm font-semibold text-white mt-4 border border-solid border-slate-800">
        <div className='flex flex-row items-center gap-3'>
<span className='text-slate-300 text-base font-light'>Pokaż więcej</span>
</div>
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default Category;