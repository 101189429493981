import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';

const AdminAddInspiration = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [file, setFile] = useState();
    const [category, setCategory] = useState('');
    const [addCategory, setAddCategory] = useState('');
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const { type } = useParams();

    const [modal, setModal] = useState({ show: false, data: null });


    useLayoutEffect(() => {
        refreshToken();
        getCategories();
    }, []);

    const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files);
        }
      };

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleChange = (e) => {
        setCategory(e.target.value);
      };

      const getCategories = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'categories?limit=999', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setCategories(response.data);
    }

    const addFolder = async () => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy chcesz dodać folder?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await refreshToken();
                    const formData = new FormData();
                    formData.append("category", addCategory);
                    const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'admin/addfolder', formData, {headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }});
                if(response.data.status == "success"){
                    Swal.fire('Dodano kategorię', "", 'success');
                }
            }catch(e){
                console.log(e);
                Swal.fire('Błąd', e.response.data.message, 'error');
            }
            }
          })
    }

    const addInspiration = async () => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy chcesz dodać ten film?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await refreshToken();
                    const formData = new FormData();
                    for (let i = 0; i < file.length; i++) {
                        formData.append('video', file[i]);
                      }
                    formData.append("category", category);
                    const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'admin/addvideo', formData, {headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }});
                if(response.data.status == "success"){
                    Swal.fire('Dodano film', "", 'success');
                }
            }catch(e){
                console.log(e);
                Swal.fire('Błąd', e.response.data.message, 'error');
            }
            }
          })
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

if (type == "video"){
    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/admin/inspirations")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Dodaj film</span>

<XMarkIcon onClick={() => navigate("/admin/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    <div className="text-slate-300 text-base font-normal mt-1 mb-1">Film</div>
    <input type="file" multiple accept="video/*" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" onChange={handleFileChange}/>
    
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Folder</div>
    <select value={category} onChange={handleChange}>
    <option ></option>
    {categories.map((object, i) =>
    <option value={object.id}>{object.title}</option>
)}
      </select>    

        <button onClick={addInspiration} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj film</button>
 
        </div>
    </motion.main>
    </>
        );
    }
if(type == "folder"){
    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/admin/inspirations")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Dodaj kategorię</span>

<XMarkIcon onClick={() => navigate("/admin/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    <div className="text-slate-300 text-base font-normal mt-1 mb-1">Nazwa kategorii</div>
    <input type="text" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="Nazwa kategorii" value={addCategory} onChange={(e) => setAddCategory(e.target.value)}/>  

        <button onClick={addFolder} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj kategorię</button>
 
        </div>
    </motion.main>
    </>
        );
}
}

export default AdminAddInspiration;