import { CheckCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from 'axios';

export function Modal({ closeModal, data }) {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [message, setMessage] = useState('');
    const [modalType, setModalType] = useState('');
    const navigate = useNavigate();

    useLayoutEffect(() => {
      refreshToken();
      setModalType(data.type);
      setMessage(data.message);
      
  }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const deleteTikTok = async (props) => {
      const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'deletetiktok?id=' + props, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      if(response.data.status === "success"){
      setModalType("deletedTiktok");
      }else{
        if(response.status == 403){
          setMessage("Wystąpił problem, odśwież stronę i spróbuj jeszcze raz!");
        }else{
        setMessage(response.data.message);
        }
        setModalType("error");
      }
  }


    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

switch(modalType){
    case "deleteTiktok":
        return (
            <div className="modal-container">
              <div className="modal flex justify-center flex-col gap-4 animated">
              <div className="bright-modal red"></div>
                                  <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                      <ExclamationTriangleIcon className=' w-20 text-red-500'></ExclamationTriangleIcon>
                                      <div className='text-2xl font-medium text-white'>Czy na pewno chcesz usunąć tego tiktoka?</div>
                                      </div>
                                      <div className='flex w-full justify-center px-6 gap-3 mt-6'>
                                      <div onClick={() => closeModal()} className="w-1/2 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                 Anuluj
                  </div>
                  <div onClick={() => { deleteTikTok(data.id) }} className="cursor-pointer w-1/2 bg-red-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                                  Usuń
                  </div>
                  </div>
                </div>
            </div>
          );
        break;
    case "deletedTiktok":
      return (
        <div className="modal-container">
        <div className="modal flex justify-center flex-col gap-4 animated">
        <div className="bright-modal green"></div>
                            <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                <div className='text-2xl font-medium text-white'>TikTok został usunięty!</div>
                                </div>
                                <div className='flex w-full justify-center px-6 gap-3 mt-6'>
                                <div onClick={() => {closeModal(); navigate("/mytiktoks")}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
           Przejdź do Moich TikToków
            </div>
            </div>
          </div>
      </div>
      )
      break;
    case "addTiktok":
      return (
            <div className="modal-container">
              <div className="modal flex justify-center flex-col gap-4 animated">
              <div className="bright-modal green"></div>
                                  <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                      <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                      <div className='text-2xl font-medium text-white'>Tiktok został dodany!</div>                                          
                                      </div>
                                      <span className="text-base text-slate-300 mt-1 text-center">Od teraz aktywność wygenerowana pod tym tiktokiem będzie naliczana do statystyk.</span>

                                      <div className='flex w-full justify-center flex-col px-6 gap-3 mt-3'>
                                      <div onClick={() => {closeModal(); navigate("/tiktok/" + data.id)}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                 Sprawdź statystyki
                  </div>
                  <div onClick={() => {closeModal(); navigate("/dashboard")}} className="cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
             Przejdź do kokpitu
              </div>
              <div onClick={() => {closeModal(); navigate("/addtiktok")}} className="cursor-pointer bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
             Dodaj następnego tiktoka
              </div>
                  </div>
            
                </div>
            </div>
          );
      break;
    case "addProfile":
      return (
              <div className="modal-container">
                <div className="modal flex justify-center flex-col gap-4 animated">
                <div className="bright-modal green"></div>
                                    <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                        <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                        <div className='text-2xl font-medium text-white'>Dodano profil!</div>                                          
                                        </div>
                                        <span className="text-base text-slate-300 mt-1 text-center">Od teraz możesz dodawać linki do filmów z tego profilu!</span>
  
                                        <div className='flex w-full justify-center flex-col px-6 gap-3 mt-3'>
                                        <div onClick={() => {closeModal(); navigate("/dashboard")}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                   OK
                    </div>
                    </div>
              
                  </div>
              </div>
            );
      break;
    case "addContact":
      return (
                <div className="modal-container">
                  <div className="modal flex justify-center flex-col gap-4 animated">
                  <div className="bright-modal green"></div>
                                      <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                          <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                          <div className='text-2xl font-medium text-white'>Uzupełniono dane!</div>                                          
                                          </div>
    
                                          <div className='flex w-full justify-center flex-col px-6 gap-3 mt-3'>
                                          <div onClick={() => {closeModal(); navigate("/addcontact")}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                     OK
                      </div>
                      </div>
                
                    </div>
                </div>
              );
      break;
    case "addPayment":
      return (
                  <div className="modal-container">
                    <div className="modal flex justify-center flex-col gap-4 animated">
                    <div className="bright-modal green"></div>
                                        <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                            <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                            <div className='text-2xl font-medium text-white'>Metoda płatności dodana!</div>                                          
                                            </div>
                                            <span className="text-base text-slate-300 mt-1 text-center">Od teraz możesz zacząć zarabiać na reflinkach ze sprzedaży ebooka!</span>
                                            <div className='flex w-full justify-center flex-col px-6 gap-3 mt-3'>
                                            <div onClick={() => {closeModal(); navigate("/addpayment")}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                       OK
                        </div>
                        </div>
                  
                      </div>
                  </div>
                );
      break;
    case "payoutSuccess":
      return (
                    <div className="modal-container">
                      <div className="modal flex justify-center flex-col gap-4 animated">
                      <div className="bright-modal green"></div>
                                          <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                              <CheckCircleIcon className=' w-20 text-green-500'></CheckCircleIcon>
                                              <div className='text-2xl font-medium text-white'>Zlecono wypłatę!</div>                                          
                                              </div>
                                              <span className="text-base text-slate-300 mt-1 text-center"></span>
                                              <div className='flex w-full justify-center flex-col px-6 gap-3 mt-3'>
                                              <div onClick={() => {closeModal(); navigate("/payout")}} className="cursor-pointer bg-sky-600 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         OK
                          </div>
                          </div>
                    
                        </div>
                    </div>
                  );
      break;
    case "error":
      return (
            <div className="modal-container">
              <div className="modal flex justify-center flex-col gap-4 animated">
              <div className="bright-modal red"></div>
                                  <div className='px-12 text-center flex justify-center items-center flex-col gap-4'>
                                      <ExclamationTriangleIcon className=' w-20 text-red-500'></ExclamationTriangleIcon>
                                      <div className='text-lg font-medium text-white'>{message}</div>
                                      </div>
                                      <div className='flex w-full justify-center px-6 gap-3 mt-6'>
                                      <div onClick={() => closeModal()} className="cursor-pointer w-1/2 bg-slate-900 border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                 Zamknij
                  </div>
                  </div>
                </div>
            </div>
          );
      break;
}
  }