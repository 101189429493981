import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ClockIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import AdminNavbar from "./AdminNavbar";
import { Menu, Transition } from '@headlessui/react'

const AdminUsers = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getUsers = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/users', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setUsers(response.data);
        setLoaded(true);
        
    }

    const handleSearch = async () => {
        if(userSearch.length > 0){
            const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/user?nick=' + userSearch, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setUsers(response.data);
        }
    }


    useEffect(() => {
        refreshToken();
        getUsers();
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>Użytkownicy</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz zarządzać użytkownikami.</span>
        </div>
        <button onClick={() => navigate("/admin/adduser")} className="mb-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-base py-2 px-4 rounded w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj użytkownika</button>
        <div className="flex flex-row gap-2 items-center justify-between mb-6 w-full">
        <input type="text" className=" w-2/3 bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block px-2.5 py-3 mb-1" placeholder="Nazwa użytkownika" value={userSearch} onChange={(e) => setUserSearch(e.target.value)}/>
        <div className="w-1/3 bg-sky-600 p-2 rounded-lg whitespace-nowrap text-center" onClick={handleSearch}>Wyszukaj</div>
        </div>
        <div className="flex flex-col gap-3">
        {users.map((object, i) =>
        <>

<Menu as="div" className="block px-2 py-3 text-sm rounded-md text-slate-300 bg-slate-900">
      <div>
        <Menu.Button className="w-full">
        <div className="flex justify-between items-center">
                    <div className="flex flex-row gap-2 items-center">
    {object.accounts.length > 0 ?
        <img className='w-7 h-7 rounded-3xl border border-solid border-gray-500' src={object.accounts[0].avatar}/>
    : ""}
        <div className="flex flex-col">
    <span className='font-normal text-base text-white overflow-hidden text-ellipsis whitespace-nowrap flex flex-row gap-2 items-center'>{object.role == "admin" ? <div className="bg-red-900/30 text-red-500 p-1 py-0.5 rounded">ADMIN</div> : ""}{object.nick}</span>
    {object.accounts.length > 0 ?
    <span className="text-xs text-slate-400 text-left">@{object.accounts[0].username}</span>
: ""}
</div>
</div>
<div>
    {object.status == "waiting" ? <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div> : ""}
    {object.status == "rejected" ? <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>
 : ""}
</div>
</div>
          
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="ring-0 focus:outline-none">
        <>
            <Menu.Item disabled>
              {({ close }) => (
                          <div className='flex flex-col items-center gap-2 bg-slate-900 p-1 rounded-lg mt-2'>
            <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Instagram</div>
                            <div className="text-white text-base">{object.instagram}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Adres e-mail</div>
                            <div className="text-white text-base">{object.mail}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Numer telefonu</div>
                            <div className="text-white text-base">{object.phone}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Kod (ebook)</div>
                            <div className="text-white text-base text-end">{object.refCode ? "https://milioner2023.pl/?ref=" + object.refCode : "Brak"}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Ostatnia wizyta</div>
                            <div className="text-white text-base">{object.lastVisit}</div>
                        </div>
                        <button onClick={() => navigate("/admin/user/" + object.id)} className="mt-1 bg-sky-600 hover:bg-sky-500 text-white font-medium text-base py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Zobacz użytkownika</button>

</div>
</div>
              )}
            </Menu.Item>
            </>
        </Menu.Items>
      </Transition>
    </Menu>

    </>
        )}
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminUsers;