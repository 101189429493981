export const short_formatter = Intl.NumberFormat('en-US', { notation: 'compact', maximumSignificantDigits: 3 });

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    locale: 'pl',
    plugins:{
      legend: {
    display: false
  }
  },
  scales: {
    x: {
            grid: {
        display: false
      },
      display: true,
    },
    y: {
      grid: {
        display: true,
        color: "#1E293B"
      },
      display: true,
      ticks: {
        callback: function (val, index, ticks) {
           return short_formatter.format(val);
         }
      }
    }
  }
  };

  export const optionsSec = {
    responsive: true,
    maintainAspectRatio: false,
    locale: 'pl',
    plugins:{
      legend: {
    display: false
  }
  },
  scales: {
    x: {
            grid: {
        display: false
      },
      display: true,
    },
    y: {
      grid: {
        display: true,
        color: "#1E293B"
      },
      display: true,
      ticks: {
        callback: function (val, index, ticks) {
           return short_formatter.format(val);
         }
      }
    }
  }
  };