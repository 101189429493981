import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon, FolderIcon, PlayCircleIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { downloadVideo } from "../utils/utils";
import moment from "moment";

const Tutorials = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [tutorials, setTutorials] = useState([]);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getTutorials();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getTutorials = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'tutorials', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setTutorials(response.data);
    }




if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Jak tworzyć tiktoki?</span>
        <span className='text-slate-300 font-normal text-sm'>Poniżej znajdziesz poradniki o tym jak tworzyć tiktoki, żeby były ciekawe i zasięgowe.</span>
        </div>
        <div className="flex flex-col gap-3">
        {tutorials.map((tutorial, i) =>
                    <div onClick={() => navigate("/tutorial/" + tutorial.id)} className="cursor-pointer flex flex-row gap-2 p-2 bg-slate-900 items-center rounded-lg">
                    <div className="bg-slate-800 p-1 rounded-lg">
                    <VideoCameraIcon className="w-6 h-6 text-sky-500"></VideoCameraIcon>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-200 text-sm">
                            {tutorial.title}
                        </div>
                        <div className="text-slate-400 text-xs text-left">
                            {tutorial.description}
                        </div>
                    </div>
                    </div>
        )}

        </div>



          </div>
          </motion.main>
    </>
        );

}

export default Tutorials;