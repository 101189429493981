import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon, FolderIcon, MusicalNoteIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import AdminNavbar from "./AdminNavbar";

const AdminHelpful = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Przydatne do tiktoków</span>
        <span className='text-slate-300 font-normal text-sm'>Poniżej możesz edytować zakładkę.</span>
        </div>

        <div className="flex mt-4 flex-col gap-2">

        <div onClick={() => navigate("/admin/music")} className="cursor-pointer bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
<MusicalNoteIcon className="text-slate-300 w-5 h-5"></MusicalNoteIcon>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Muzyka</span>
        <span className="text-xs text-slate-400">Znajdziesz tu listę utworów, które idealnie nadają się do tworzenia tiktoków.</span>
        </div>
        </div>

        <div onClick={() => navigate("/admin/quotations")} className="bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
        <svg className="text-slate-300 w-5 h-5" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0002 14.6668L17.6668 9.3335H12.3335V0.000167847H21.6668V9.3335L19.0002 14.6668H15.0002ZM3.00016 14.6668L5.66683 9.3335H0.333496V0.000167847H9.66683V9.3335L7.00016 14.6668H3.00016Z" fill="#CBD5E1"/>
</svg>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Motywujące cytaty</span>
        <span className="text-xs text-slate-400">Znajdziesz tu listę motywacyjnych cytatów, które można łatwo skopiować.</span>
        </div>
        </div>
        </div>

          </div>
          </motion.main>
    </>
        );

}

export default AdminHelpful;