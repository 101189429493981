import { HeartIcon, PlayIcon, ChatBubbleOvalLeftEllipsisIcon, CheckCircleIcon, XMarkIcon, ClockIcon, UserIcon, UsersIcon, ArrowDownLeftIcon, ArrowRightIcon, ArrowUpRightIcon, ArrowDownTrayIcon, PlayCircleIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { short_formatter } from "./chart";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  export function Top({admin = 0, url, avatar, id, cover, date, likes, views, comments}){
    const navigate = useNavigate();
    return (
        <div onClick={() => admin ? navigate(`/admin/tiktok/${id}`) : window.open(url, '_blank').focus()} className='cursor-pointer gap-4 p-2 flex justify-start items-center rounded-lg flex-row bg-slate-900 '>
          <div>
            <div className='w-11 h-16 rounded-lg' style={{background: `url(${cover}), url("https://jmmplatform.pl/waiting.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
              <img className='w-6 h-6 rounded-xl -top-1 -right-2 absolute' onError={() => {this.onerror=null; this.src='https://jmmplatform.pl/waiting.png'}} src={avatar} alt="Zdjęcie profilowe"></img>
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <span className='text-slate-400 text-sm font-light flex items-center'>{date}</span>
            <div className='font-light text-white text-2xl flex flex-row gap-2'>
              <span className='flex flex-row text-sm gap-1 items-center'><HeartIcon className='h-4 w-4 text-slate-400'></HeartIcon> {likes}</span>
              <span className='flex flex-row text-sm gap-1 items-center'><PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon> {views}</span>
              <span className='flex flex-row text-sm gap-1 items-center'><ChatBubbleOvalLeftEllipsisIcon className='h-4 w-4 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon> {comments}</span>
            </div>
              </div>
                    </div>
    )
}

export function TikTok({admin = 0, username=null, id, avatar, cover, date, likes, views, comments, status}){
  const navigate = useNavigate();
  return (
      <div onClick={() => navigate(admin ? `/admin/tiktok/${id}` : `/tiktok/${id}`)} className='gap-4 p-2 flex justify-start items-center rounded-lg flex-row bg-slate-900 cursor-pointer'>
        <div>
          <div className=' w-16 h-28 rounded-lg !bg-cover !bg-no-repeat' style={{background: `url(${cover}), url("https://jmmplatform.pl/waiting.png")`}}>
            <img loading="lazy" className='w-6 h-6 rounded-xl -top-1 -right-2 absolute' onError={() => {this.onerror=null; this.src='https://jmmplatform.pl/waiting.png'}} src={avatar} alt="Zdjęcie profilowe"></img>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='text-slate-400 text-sm font-light flex items-center'>{date}</span>
          <div className='font-light text-white text-2xl flex flex-row gap-2 flex-wrap'>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><HeartIcon className='h-4 w-4 text-slate-400'></HeartIcon> {likes}</span>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon> {views}</span>
            <span className='flex flex-row text-sm gap-1 items-center break-keep'><ChatBubbleOvalLeftEllipsisIcon className='h-4 w-4 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon> {comments}</span>
            {admin ? <span className='flex flex-row text-sm gap-1 items-center break-keep'><UserIcon className='h-4 w-4 text-slate-400'></UserIcon> {username}</span> : "" }
          </div>
          {status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
            </div>
                  </div>
  )
}

export function Card({title, account, period}){
  let icon;
  let pill;
  let type;
  let stat = 0;
  let percent;
  let stats;
  let difference = 0;
  let position = -1;
  let engagement = 0;
  let statlacznie = 0;
  if(period > 0 && period != 24){
  stats = account.statistics.slice(-(period+1));
  }else{
  stats = account.statistics;
  }
        switch(title){
          case "Wyświetlenia":
             icon = <PlayIcon className='h-6 w-6 text-slate-400'></PlayIcon>;
             statlacznie = short_formatter.format(account.views || 0);
             position = account.view_position+1;
             if(stats.length >= period){
              if(period !=24){
              let old = stats[0].views;
              let newvalue = stats[stats.length-1].views;
              stat = short_formatter.format(newvalue - old) || 0;
              percent = percIncrease(old, newvalue);
              difference = newvalue-old || 0;
              }else{
                difference = account.dayViews;
              }
            }else{
              percent = 0
            }
             break;
          case "Obserwujący":
             icon = <UsersIcon className='h-6 w-6 text-slate-400'></UsersIcon>;
             statlacznie = short_formatter.format(account.followers || 0);
             position = account.follow_position+1;
             if(stats.length >= period){
              if(period !=24){
              let old = stats[0].followers;
              let newvalue = stats[stats.length-1].followers;
              stat = short_formatter.format(newvalue - old) || 0;
              percent = percIncrease(old, newvalue);
              difference = newvalue-old || 0;
              }else{
                difference = account.dayFollowers; 
              }
            }else{
              percent = 0
            }
             break;
          case "Polubienia":
             icon = <HeartIcon className='h-6 w-6 text-slate-400'></HeartIcon>;
             statlacznie = account.likes || 0;
             engagement = Math.floor(((account.likes) / account.views)*100);
             position = account.like_position+1;
             if(stats.length >= period){
              if(period !=24){
              let old = stats[0].likes;
              let newvalue = stats[stats.length-1].likes;
              stat = short_formatter.format(newvalue - old) || 0;
              percent = percIncrease(old, newvalue);
              difference = newvalue-old || 0;
              }else{
                difference = account.dayLikes;
              }
            }else{
              percent = 0
            }
             break;
          case "Komentarze":
            icon = <ChatBubbleOvalLeftEllipsisIcon className='h-6 w-6 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon>;
            statlacznie = short_formatter.format(account.comments || 0);
            position = account.comment_position+1;
            if(stats.length >= period){
              if(period !=24){
              let old = stats[0].comments;
              let newvalue = stats[stats.length-1].comments;
              stat = short_formatter.format(newvalue - old) || 0;
              percent = percIncrease(old, newvalue);
              difference = newvalue-old || 0;
              }else{
                difference = account.dayComments;
              }
            }else{
              percent = 0
            }
            break;
          }
          /*if(percent > 1000){
            percent = 0;
            type = "none";
          }*/
          if(percent > 0 && percent <= 1000){
            type = "green";
          }else if(percent < 0){
            type = "red"
          }else{
            type = "none"
          }
          switch(type){
            case "red":
            pill = <div className='text-red-500 bg-red-900 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs ml-auto'>
              <ArrowDownLeftIcon className='w-3 h-3 font-bold'></ArrowDownLeftIcon>
        {Math.abs(percent)}%</div>;
               break;
            case "green":
            pill = <div className='text-green-500 bg-green-900 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs ml-auto'>
            <ArrowUpRightIcon className='w-3 h-3 font-bold'></ArrowUpRightIcon>
        { Math.abs(percent)}%</div>;
               break;
            case "none":
              pill = <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-xs ml-auto'>- 0%</div>
              break;
            }
            if(title != "Polubienia"){
          return (
              <div className=' gap-2 p-4 flex justify-start rounded-lg flex-col bg-slate-900 w-1/2 lg:w-full border border-slate-700 self-stretch'>
                  <span className='text-white flex flex-row items-center gap-1 mb-2'>{icon} {title} <span className="hidden lg:flex ml-auto">{pill}</span></span>
                  <div className="flex justify-center items-center flex-col lg:flex-row border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    
                  <div class={"flex flex-col items-start w-full pb-3 pt-3 pl-4 "}>
                  <span className=' font-bold text-lg w-full'>{difference>=0 ? "+" : "-"} <span className={"text-xl font-bold ".concat(difference>=0 ? "text-green-400" : "text-red-400")}>{short_formatter.format(difference).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(difference).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span></span>
                  <span className="text-xs text-gray-400">{period == 24 ? "Ostatnie 24h" : ""}{period > 0 && period != 24 ? `Ostatnie ${period} dni` : ""}{period == 0 ? "Od zawsze" : ""}</span>
                  </div>

                  <div class="flex flex-col items-start w-full pb-3 pt-3 pl-4 border-t lg:border-l border-slate-800">
                  <span className=' font-medium text-white text-xl w-full'>{statlacznie.match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{statlacznie.match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Łącznie</span>
                  </div>
          
                  </div>
                  <div class="text-gray-400 text-xs mt-3">Zajmujesz <span className="text-green-400">{position} miejsce</span> w rankingu!</div>
                          </div>
          )
            }else{
              return (
                <div className=' gap-2 p-4 flex justify-start rounded-lg flex-col bg-slate-900 w-full border border-slate-700 self-stretch'>
                    <span className='text-white flex flex-row items-center gap-1 mb-2'>{icon} {title} {pill}</span>
                    <div className="flex justify-center items-center flex-row gap-4">
                      <div class="flex items-center justify-center flex-col">
                      <div class="flex items-center justify-center relative w-full">
                      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="99" className="relative" viewBox="0 0 99 99" fill="none">
  <circle cx="49.5" cy="49.5" r="49.5" transform="rotate(-90 49.5 49.5)" fill="url(#paint0_radial_611_610)" fill-opacity="0.2"/>
  <circle cx="49.5" cy="49.5" r="48.5" transform="rotate(-90 49.5 49.5)" stroke="#22C55E" stroke-opacity="0.2" stroke-width="2"/>
  <defs>
    <radialGradient id="paint0_radial_611_610" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.5 49.5) rotate(90) scale(49.5)">
      <stop stop-color="#22C55E"/>
      <stop offset="1" stop-color="#22C55E" stop-opacity="0"/>
    </radialGradient>
  </defs>
</svg>
                    <span className=' font-bold text-white text-2xl w-full text-center absolute'>{engagement || 0}%</span>
                    </div>
                    <div class="text-gray-400 text-xs text-center mt-3">Osób zostawia serce pod twoimi filmami</div>
                    </div>
                    <div class="flex flex-col items-center w-full ">

                    <div className="w-full h-full flex justify-center items-center flex-col border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    

                  <div class={"flex flex-col items-start w-full p-3 "}>
                  <span className=' font-bold text-lg w-full'>{difference>=0 ? "+" : "-"} <span className={"text-xl font-bold ".concat(difference>=0 ? "text-green-400" : "text-red-400")}>{short_formatter.format(difference).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(difference).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span></span>
                  <span className="text-xs text-gray-400">{period == 24 ? "Ostatnie 24h" : ""}{period > 0 && period != 24 ? `Ostatnie ${period} dni` : ""}{period == 0 ? "Od zawsze" : ""}</span>
                  </div>
                  <div className=" bg-slate-800" style={{width:'100%', height:'1px'}}></div>
                  <div class="flex flex-col items-start w-full p-3">
                  <span className=' font-medium text-white text-xl w-full'>{short_formatter.format(statlacznie).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(statlacznie).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Łącznie</span>
                  </div>

          
                  </div>

                    </div>
            
                    </div>
                            </div>
            )
            }
      }

export function UseLazyImg({src, url}) {
        const [source, setSource] = useState("https://jmmplatform.pl/black.jpg")
        const navigate = useNavigate();
      
        useEffect(() => {
            const img = new Image()
            img.src = src
            img.onload = () => setSource(src)
        }, [src])
      
        return(
          <div onClick={() => navigate(url)} className="!bg-cover vid rounded-md flex justify-center items-center cursor-pointer h-auto md:w-40" style={{aspectRatio: '9 / 13', background: `center linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${source})`, backgroundSize: "cover",}}><PlayCircleIcon className="text-sky-500 h-14 w-14 hover:scale-110 transition-transform"></PlayCircleIcon></div>
        )
      }

export function VideoComponent (props) {
        const [percentCompleted, setPercentCompleted] = useState(0);
        const [downloading, setDownloading] = useState(false);
        let object = props.object;
        const downloadVideo = (url) => {
          setDownloading(true);
          axios({
            url,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total)); // you can use this to show user percentage of file downloaded
          },
          }).then((response) => {
            const urlObject = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlObject;
            link.setAttribute('download', 'recording.mp4');
            link.setAttribute('target', '_blank')
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloading(false);
            setPercentCompleted(0);
          });
        };
      
        return (
          <div className="bg-slate-900 border-solid border border-slate-800 rounded-md md:w-40">
            {moment().diff(moment(object.createdAt), 'days') < 3 ? 
            <div className="text-xs bg-red-500 rounded-lg p-1 px-1.5 absolute -top-1 -right-2 z-3">🔥 NOWY</div>
            : ""}
            <UseLazyImg url={"/video/" + object.id + "?type=all"} src={process.env.REACT_APP_API_URL + "cover?filename=".concat(object.cover)}></UseLazyImg>
          <div onClick={() => downloadVideo(process.env.REACT_APP_API_URL + "video?filename=".concat(object.path))} className={"bg-slate-800 text-white flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer".concat(!downloading ? "" : " bg-slate-900 text-slate-400 opacity-70 cursor-not-allowed")}><ArrowDownTrayIcon className="text-sky-500 h-5 w-5"></ArrowDownTrayIcon>{downloading ? "Pobieranie... (" + percentCompleted + "%)" : "Pobierz"}</div>
        </div>
        );
        
      }

      export const priceformatter = new Intl.NumberFormat('pl-PL', {
        minimumFractionDigits: 1,
        maximumSignificantDigits: 5
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      

export function percIncrease(a, b) {
  let percent;
  if(b !== 0) {
      if(a !== 0) {
          percent = (b - a) / a * 100;
      } else {
          percent = b * 100;
      }
  } else {
      percent = - a * 100;            
  }       
  return Math.floor(percent);
}

export function timeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

export const str_pad_left = (string,pad,length) => {
  return (new Array(length+1).join(pad)+string).slice(-length);
}