import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { motion } from "framer-motion";
import dateFormat from 'dateformat';
import { i18n } from "dateformat";
import gift from "../gift.png";
import miejsce_1 from "../miejsce_1.png";
import miejsce_2 from "../miejsce_2.png";
import miejsce_3 from "../miejsce_3.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { Filler } from "chart.js";
import { options, short_formatter } from '../utils/chart';
import { Card, classNames, percIncrease, priceformatter, Top } from '../utils/utils';
import { ArrowDownLeftIcon, ArrowLeftCircleIcon, ArrowLeftIcon, ArrowRightCircleIcon, ArrowRightIcon, ArrowUpRightIcon, CameraIcon, ChatBubbleOvalLeftEllipsisIcon, ChevronDoubleUpIcon, ClockIcon, CurrencyDollarIcon, ExclamationCircleIcon, EyeIcon, HeartIcon, InformationCircleIcon, MoonIcon, PlayIcon, QuestionMarkCircleIcon, SunIcon, UserCircleIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import moment from 'moment/moment';
import MapChart from './MapChart';
import MapContainer from '../map/MapContainer.tsx';
ChartJS.register(Filler);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement
);

i18n.dayNames = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

i18n.monthNames = [
  "St",
  "Lut",
  "Mrz",
  "Kw",
  "Maj",
  "Cz",
  "Lip",
  "Sier",
  "Wrz",
  "Paź",
  "Lis",
  "Gr",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


const Dashboard = () => {
    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState([]);

    const [labels, setLabels] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [chartData, setChartData] = useState([]);

    const [labels2, setLabels2] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [date2, setDate2] = useState("");
    const [dateIndex, setDateIndex] = useState(-1);


    const [user, setUser] = useState([]);
    const [period, setPeriod] = useState(7);
    const navigate = useNavigate();
    const formatter = Intl.NumberFormat('pl');

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setExpire(decoded.exp);
            
            getAccounts();
        } catch (error) {
            if (error.response) {
                navigate("/login")
                setLoaded(true);
            }else{
              alert("[ERROR] refreshToken: " + error.message)
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handlePeriodChange = async (props) => {
      try{
      setLabels([]);
      setChartData([]);
      setPeriod(props);
      let stats;
      if(props > 0){
      stats = account.statistics.slice(-((props)+1));
      }else{
      stats = account.statistics;
      }
      if(props == 24){
        stats = account.statistics.slice(-8);
      }
      if(stats.length >= 2){
      stats.forEach(function (stat, i){
        if(i > 0){
        setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
        setChartData(chartData => [...chartData, stats[i].views - stats[i-1].views]);
        }
      }) 
    }
    }catch(e){
      alert("[ERROR] handlePeriodChange: " + e.message);
    }
    }

    const setCurrentAccount = async (props) => {
      try{
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'account?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data);
        setLabels([]);
        setChartData([]);
        let stats;
        if(props > 0){
        stats = response.data.statistics.slice(-((props)+1));
        }else{
        stats = response.data.statistics;
        }
        if(props == 24){
          stats = response.data.statistics.slice(-8);
        }

        if(stats.length >=2){
        stats.forEach(function (stat, i){
          if(i > 0){
          setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
          setChartData(chartData => [...chartData, stats[i].views - stats[i-1].views]);
          }
        })  
      }
        //await handlePeak(response.data);
      }catch(e){
        console.log(e);
        alert("[ERROR] currentAccount: " + e.message)
      }
    }

    const handleDataIndex = async (type) => {
      let hourstats = account.hourstats;
      let dates = Object.keys(hourstats);
      let tempdateindex = dates.indexOf(dates.at(dateIndex));
      if(type == "previous" && tempdateindex > 1){
          setDateIndex((tempdateindex-1));
          await handlePeak(account, (tempdateindex-1))
      }else if(type == "next" && tempdateindex < dates.length-1){
      setDateIndex(tempdateindex+1);
      //await handlePeak(account, (tempdateindex+1))
      }
    }


    const handlePeak = async (account, tempdateindex = null) => {
      let hourstats = account.hourstats;
      let dates = Object.keys(hourstats);
      if(dates.length > 2){
        if(tempdateindex == null){
        tempdateindex = dates.indexOf(dates.at(dateIndex));
        }
        let date = dates[tempdateindex];
        setDate2(date);

        setLabels2([]);
        setChartData2([]);
        let today = hourstats[date];
        let yesterday = hourstats[Object.keys(hourstats)[tempdateindex-1]]
        let todayhours = Object.keys(today);
        if(todayhours.length > 0){
        todayhours.forEach(function (hour, i){
          if(today[todayhours[i-1]] && today[todayhours[i-2]] && yesterday["23:00"]){
          if(hour == "00:00"){
          setLabels2(labels => [...labels, hour])
          setChartData2(chartData => [...chartData, today[hour].views - yesterday["23:00"].views]);
          }else{
            if(moment().format("YYYY-MM-DD") == date && hour != moment().format("HH:00") || today[hour].views - today[todayhours[i-1]].views > 0){
          setLabels2(labels => [...labels, hour])
          if(today[hour].views - today[todayhours[i-1]].views >= 0){
          setChartData2(chartData => [...chartData, today[hour].views - today[todayhours[i-1]].views]);
          }else{
            setChartData2(chartData => [...chartData, today[todayhours[i-1]].views - today[todayhours[i-2]].views]);
          }
            }
          }
        }
        })
        }else{
          handleDataIndex("previous");
        }
      }
    }

    const getAccounts = async () => {
      try{
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccounts(response.data.accounts);
        if(response.data.accounts.length > 0){
        setAccount(response.data.accounts[0])
        setUser(response.data)
        setLabels([]);
        setChartData([]);

        let stats;
        if(period > 0){
        stats = response.data.accounts[0].statistics.slice(-((period)+1));
        }else{
        stats = response.data.accounts[0].statistics;
        }
        if(period == 24){
          stats = response.data.accounts[0].statistics.slice(-8);
        }



        if(stats.length >= 2){
        stats.forEach(function (stat, i){
          if(i > 0){
          setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
          setChartData(chartData => [...chartData, stats[i].views - stats[i-1].views]);
          }
        })  
      }


        //await handlePeak(response.data.accounts[0])
      }
      setLoaded(true);
    }catch(e){
      console.log(e);
      alert("[ERROR] getAccounts: " + e.message);
    }
    }

    const data = {
      labels: labels,
      datasets: [
        {
          data: chartData,
          borderColor: '#22C55E',
          pointRadius: 5,
          tension: 0.4,
          fill: 'start',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(34, 197, 94, 0.3)");
            gradient.addColorStop(0.25, "rgba(34, 197, 94, 0.25)");
            gradient.addColorStop(0.5, "rgba(34, 197, 94, 0.2)");
            gradient.addColorStop(0.75, "rgba(34, 197, 94, 0.15)");
            gradient.addColorStop(1, "transparent");
            return gradient;
          },
        },
      ],
    };

    const data2 = {
      labels: labels2,
      datasets: [
        {
          data: chartData2,
          borderColor: '#22C55E',
          pointRadius: 4,
          tension: 0.4,
          fill: 'start',
          backgroundColor: 'rgba(34, 197, 94, 0.8)'
        },
      ],
    };

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    if(accounts.length < 1) return (
      <>
               <motion.main
   className="main__container"
   initial={{ display: "none", opacity: 0 }}
    animate={{ display: "flex", opacity: 1 }}
    exit={{ display: "none", opacity: 0 }}
    transition={{  duration: 1 }}
>
<Navbar role={role} avatar={account.avatar} username={account.username} account={account} accounts={accounts} setCurrentAccount={setCurrentAccount}/>
<div className="container mx-auto px-5 pb-12 flex">
          <div id="bright hidden"></div>
          <div className='w-full h-full flex flex-col gap-3 justify-center items-center'>
          {role == "admin" ?
        <div onClick={() => navigate("/admin/dashboard")} className=' cursor-pointer  gap-2 mb-4 py-2 px-3 flex justify-center flex-row items-center rounded-lg bg-slate-900 border border-solid border-slate-800'>

                <span className='font-light text-slate-300 text-base'>Przejdź do panelu admina</span>
               
                        </div>
                        : " "}
          <span className='text-slate-400 text-center'>Nie masz jeszcze żadnego konta TikTok. Kliknij <a className='text-blue-200' href="" onClick={() => navigate("/addprofile")}>tutaj</a> aby je dodać.</span>
          </div>
          </div>
</motion.main>
      </>
    );

    return (
        <>
         <motion.main
   className="main__container"
   initial={{ display: "none", opacity: 0 }}
    animate={{ display: "flex", opacity: 1 }}
    exit={{ display: "none", opacity: 0 }}
    transition={{  duration: 1 }}
>
        <Navbar role={role} avatar={account.avatar} username={account.username}  accounts={accounts} setCurrentAccount={setCurrentAccount}></Navbar>
        <div className=' glow' style={{right: "-30rem"}}></div>
        <div className='glow' style={{left: "-30rem"}}></div>
        {!user.telegram && (
           <div className=' w-[100svw] h-[100svh] backdrop-blur-sm bg-black bg-opacity-50 fixed z-20 overflow-hidden flex items-center justify-center'>

           <div className='bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mb-4 border border-slate-700'>
           <div className='flex flex-row gap-2 items-center text-white'>
           <ExclamationCircleIcon className='w-6 h-6 text-slate-300'/>
           <div>Uzupełnij dane kontaktowe</div>
           </div>
           <div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
           Uzupełnij swoje dane, abyśmy mogli się z tobą kontaktować oraz wyświetlić na naszej mapie użytkowników!
           </div>
           <button onClick={() => navigate("/addcontact")} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Uzupełnij dane kontaktowe</button>
           
           </div>
           
            </div>
          )}
        {!user.telegram_joined && user.telegram && (
 <div className=' w-[100svw] h-[100svh] backdrop-blur-sm bg-black bg-opacity-50 fixed z-20 overflow-hidden flex items-center justify-center'>

<div className='bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mb-4 border border-slate-700'>
<div className='flex flex-row gap-2 items-center text-white'>
<ExclamationCircleIcon className='w-6 h-6 text-slate-300'/>
<div>Dołącz do telegrama</div>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
Aby móc korzystać z platformy musisz dołączyć do naszego telegrama. Kliknij poniżej aby dołączyć.<br/>Po dołączeniu odśwież stronę, aby zobaczyć zawartość.
</div>
<button onClick={() =>  window.open('https://t.me/jmmplatform_bot', '_blank')} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Dołącz do telegrama</button>
<button onClick={() => navigate("/addcontact")} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Zmień nazwę użytkownika z telegrama</button>
</div>

 </div>
)}
        <div className="container mx-auto px-5 pb-12">

          <div id="bright"></div>
        <div className='pb-5 flex flex-row gap-2 justify-between items-center'>
        <span className='text-slate-50 text-2xl font-medium flex gap-2'>👋 Witaj <span style={{background: "linear-gradient(301deg, #73D4FF 7.04%, #FFF 93.05%)", "-webkit-text-fill-color": "transparent", "-webkit-background-clip": "text"}} className="bg-clip-text font-bold">{user.nick}!</span></span>
        <span className='text-slate-300 font-normal text-sm hidden'>Sprawdź jak idzie Ci Twój rozwój profili na TikToku.</span>
        <Menu as="div" className="hidden lg:flex w-fit">
      <div>
        <Menu.Button className="w-fit cursor-pointer gap-2 py-2 px-3 flex justify-center flex-row items-center rounded-lg bg-slate-800 border border-solid border-slate-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#97A2AE" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
                <span className='font-light text-slate-200 text-sm'>{period == 24 ? "Ostatnie 24h" : ""}{period == 7 ? "Ostatnie 7 dni" : ""}{period == 14 ? "Ostatnie 14 dni" : ""}{period == 30 ? "Ostatnie 30 dni" : ""}{period == 0 ? "Od zawsze" : ""}</span>
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items  className="bg-slate-900 border border-slate-800 p-2 absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none boxshadow">
        <>
        <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(24)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 24 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 24h</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(7)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 7 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 7 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(14)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 14 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 14 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(30)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 30 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 30 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(0)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 0 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Od zawsze</span>
</div>
                </a>
              )}
            </Menu.Item>
            </>
        </Menu.Items>
      </Transition>
    </Menu>
        </div>
{(!user.mail || !user.instagram || !user.city) && role != "admin" ? 
<div className='bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mb-4 border border-slate-700'>
<div className='flex flex-row gap-2 items-center text-white'>
<ExclamationCircleIcon className='w-6 h-6 text-slate-300'/>
<div>Uzupełnij dane kontaktowe</div>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
Uzupełnij swoje dane, abyśmy mogli się z tobą kontaktować oraz wyświetlić na naszej mapie użytkowników!
</div>
<button onClick={() => navigate("/addcontact")} className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-2.5 px-4 rounded-lg w-full flex justify-center gap-2 items-center">Uzupełnij dane kontaktowe</button>

</div>
: ""}

{role == "admin" ?
        <div onClick={() => navigate("/admin/dashboard")} className='hidden cursor-pointer gap-2 mb-4 py-2 px-3 flex justify-center flex-row items-center rounded-lg bg-slate-900 border border-solid border-slate-800'>

                <span className='font-light text-slate-300 text-base'>Przejdź do panelu admina</span>
               
                        </div>
                        : " "}

                        {/*<ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                style={{marginTop:"1rem"}}
            >
              <Masonry gutter="1rem">*/}
              <div className='flex flex-col lg:flex-row gap-4 mb-10'>
            <div className='flex justify-center rounded-lg flex-col gap-5 bg-slate-900 w-full lg:w-1/2 h-80 lg:h-auto border border-slate-700'>

            <MapContainer mapAccounts={user.mapAccounts}></MapContainer>

              </div>

              <Menu as="div" className="flex lg:hidden w-full">
      <div className='w-full'>
        <Menu.Button className="w-full cursor-pointer gap-2 py-2 px-3 flex justify-center flex-row items-center rounded-lg bg-slate-800 border border-solid border-slate-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#97A2AE" className="h-4 w-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
                <span className='font-light text-slate-200 text-sm'>{period == 24 ? "Ostatnie 24h" : ""}{period == 7 ? "Ostatnie 7 dni" : ""}{period == 14 ? "Ostatnie 14 dni" : ""}{period == 30 ? "Ostatnie 30 dni" : ""}{period == 0 ? "Od zawsze" : ""}</span>
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items  className="bg-slate-900 border border-slate-800 p-2 absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none boxshadow">
        <>
        <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(24)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 7 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Dzisiaj</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(7)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 7 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 7 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(14)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 14 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 14 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(30)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 30 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Ostatnie 30 dni</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => handlePeriodChange(0)}
                  className={"block p-2 text-sm rounded-md text-slate-300".concat(period == 0 ? ' bg-slate-800' : '')}
                >
                          <div className='flex flex-row items-center gap-2'>
<span className='font-normal'>Od zawsze</span>
</div>
                </a>
              )}
            </Menu.Item>
            </>
        </Menu.Items>
      </Transition>
    </Menu>

<div className='flex gap-5 flex-col'>
<div className='flex gap-5 flex-row'>
            <Card title="Wyświetlenia" account={account} period={period}/>
                <Card title="Obserwujący" account={account} period={period}/>
                </div>
                <div className='flex gap-5 flex-col lg:flex-row'>
                <Card title="Polubienia" account={account} period={period}/>
                <div className=' gap-3 flex items-center rounded-lg flex-col bg-slate-900 h-full w-full border border-slate-700 relative'>
                <div className='absolute w-full' style={{height: "7rem", borderRadius: "0.5rem 0.5rem 0rem 0rem", background: "radial-gradient(47.63% 100% at 50% 0%, rgba(14, 165, 233, 0.35) 0%, rgba(15, 23, 41, 0.35) 100%)"}}></div>
                <svg className='absolute -top-6' xmlns="http://www.w3.org/2000/svg" width="157" height="112" viewBox="0 0 157 112" fill="none">
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 148.273 46.019)" fill="#0EA5E9"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 12.216 54.1594)" fill="#0EA5E9"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 28.4963 30.9019)" fill="#0EA5E9"/>
  <circle cx="2.90719" cy="2.90719" r="2.90719" transform="matrix(-1 0 0 1 50.591 91.3713)" fill="#0EA5E9"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 121.526 84.394)" fill="#0EA5E9"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 138.97 79.7424)" fill="#0EA5E9"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 84.3144 99.5112)" fill="#0EA5E9"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 27.3334 32.0647)" fill="white"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 11.0531 55.3223)" fill="white"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 48.2652 93.6968)" fill="white"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 83.1515 100.674)" fill="white"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 120.364 85.5569)" fill="white"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 147.11 47.1821)" fill="white"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 48.2652 8.80713)" fill="#0EA5E9"/>
  <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 126.178 14.6216)" fill="#0EA5E9"/>
  <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 125.015 15.7842)" fill="white"/>
  <g filter="url(#filter0_f_656_3895)">
    <path d="M44.7766 94.2785C44.7766 95.8841 46.0782 97.1857 47.6838 97.1857C49.2894 97.1857 50.591 95.8841 50.591 94.2785C50.591 92.6729 49.2894 91.3713 47.6838 91.3713C46.0782 91.3713 44.7766 92.6729 44.7766 94.2785Z" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 148.273 46.019)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 12.2161 54.1594)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 28.4963 30.9019)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 121.526 84.394)" fill="#0EA5E9"/>
    <circle cx="0.581438" cy="0.581438" r="0.581438" transform="matrix(-1 0 0 1 138.97 79.7424)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 84.3144 99.5112)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 48.2652 8.80713)" fill="#0EA5E9"/>
    <circle cx="1.74432" cy="1.74432" r="1.74432" transform="matrix(-1 0 0 1 126.178 14.6216)" fill="#0EA5E9"/>
  </g>
  <circle cx="0.517544" cy="0.517544" r="0.517544" transform="matrix(-1 0 0 1 47.0257 9.8418)" fill="white"/>
  <defs>
    <filter id="filter0_f_656_3895" x="0.587279" y="0.666991" width="155.825" height="110.473" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="4.07007" result="effect1_foregroundBlur_656_3895"/>
    </filter>
  </defs>
</svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="102" viewBox="0 0 100 102" fill="none" className='absolute -top-6'>
  <g filter="url(#filter0_di_656_3861)">
    <g clip-path="url(#clip0_656_3861)">
      <rect x="1.68671" y="35.5872" width="71.1743" height="71.1743" rx="35.5871" transform="rotate(-30 1.68671 35.5872)" fill="#0F172A"/>
      <rect x="1.68671" y="35.5872" width="71.1743" height="71.1743" rx="35.5871" transform="rotate(-30 1.68671 35.5872)" fill="url(#paint0_radial_656_3861)"/>
      <g filter="url(#filter1_d_656_3861)">
        <path d="M60.6 34.7753C59.0693 33.0278 58.2258 30.7837 58.2264 28.4607H51.3073V56.2269C51.2539 57.7295 50.6195 59.1527 49.5378 60.1969C48.456 61.2411 47.0112 61.8247 45.5077 61.825C42.328 61.825 39.6858 59.2275 39.6858 56.003C39.6858 52.1516 43.4029 49.263 47.2319 50.4498V43.3738C39.5066 42.3438 32.7442 48.3449 32.7442 56.003C32.7442 63.4596 38.9244 68.7665 45.4853 68.7665C52.5164 68.7665 58.2264 63.0565 58.2264 56.003V41.9184C61.0322 43.9333 64.4008 45.0144 67.855 45.0085V38.0893C67.855 38.0893 63.6453 38.2908 60.6 34.7753Z" fill="white"/>
      </g>
      <g filter="url(#filter2_f_656_3861)">
        <path d="M22.2195 37.9923C24.3599 32.1061 32.0653 20.2268 45.7639 19.7988" stroke="url(#paint1_radial_656_3861)" stroke-width="2.1404"/>
      </g>
      <g filter="url(#filter3_f_656_3861)">
        <path d="M78.4051 59.9316C76.2647 65.8177 68.5592 77.697 54.8606 78.125" stroke="url(#paint2_radial_656_3861)" stroke-width="2.1404"/>
      </g>
    </g>
    <rect x="2.31695" y="35.756" width="70.2515" height="70.2515" rx="35.1258" transform="rotate(-30 2.31695 35.756)" stroke="#334155" stroke-width="0.922739"/>
  </g>
  <defs>
    <filter id="filter0_di_656_3861" x="-12.1544" y="-10.1501" width="124.908" height="124.908" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3.69096"/>
      <feGaussianBlur stdDeviation="6.92054"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0656944 0 0 0 0 0.10055 0 0 0 0 0.183333 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_656_3861"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_656_3861" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2.76822"/>
      <feGaussianBlur stdDeviation="2.76822"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.219608 0 0 0 0 0.741176 0 0 0 0 0.972549 0 0 0 0.42 0"/>
      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_656_3861"/>
    </filter>
    <filter id="filter1_d_656_3861" x="28.2061" y="26.1917" width="44.187" height="49.382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2.26903"/>
      <feGaussianBlur stdDeviation="2.26903"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_656_3861"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_656_3861" result="shape"/>
    </filter>
    <filter id="filter2_f_656_3861" x="18.9447" y="16.4602" width="29.1217" height="24.1667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.13451" result="effect1_foregroundBlur_656_3861"/>
    </filter>
    <filter id="filter3_f_656_3861" x="52.5582" y="57.2969" width="29.1217" height="24.1667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1.13451" result="effect1_foregroundBlur_656_3861"/>
    </filter>
    <radialGradient id="paint0_radial_656_3861" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.5451 74.8762) rotate(-50.7539) scale(40.9626)">
      <stop stop-color="#38BDF8"/>
      <stop offset="1" stop-color="#38BDF8" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint1_radial_656_3861" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.9917 30.753) rotate(180) scale(12.1305 54.3693)">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#065375" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint2_radial_656_3861" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(66.6329 67.1708) scale(12.1305 54.3693)">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#065375" stop-opacity="0"/>
    </radialGradient>
    <clipPath id="clip0_656_3861">
      <rect x="1.68671" y="35.5872" width="71.1743" height="71.1743" rx="35.5871" transform="rotate(-30 1.68671 35.5872)" fill="white"/>
    </clipPath>
  </defs>
</svg>
<div className='flex justify-end items-center w-full flex-col p-4 h-60 lg:h-full'>
                    <div className='text-xl mt-4 font-bold bg-clip-text text-center' style={{background: "linear-gradient(301deg, #73D4FF 7.04%, #FFF 93.05%)", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent"}}>
                    Nagrywaj Tiktoki z<br></br>Truemanem i wygrywaj!
                    </div>
                    <div onClick={() => navigate("/addtiktok")} className=' cursor-pointer flex items-center justify-center gap-2 rounded bg-slate-700 w-full pt-2 pb-2 mt-6'>
                      Dodaj TikToka 
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.9999 8.4999C0.9999 8.63251 1.05258 8.75968 1.14635 8.85345C1.24011 8.94722 1.36729 8.9999 1.4999 8.9999L13.2929 8.9999L10.1459 12.1459C10.052 12.2398 9.99927 12.3671 9.99927 12.4999C9.99927 12.6327 10.052 12.76 10.1459 12.8539C10.2398 12.9478 10.3671 13.0005 10.4999 13.0005C10.6327 13.0005 10.76 12.9478 10.8539 12.8539L14.8539 8.8539C14.9005 8.80745 14.9374 8.75228 14.9626 8.69153C14.9878 8.63079 15.0008 8.56567 15.0008 8.4999C15.0008 8.43413 14.9878 8.36901 14.9626 8.30827C14.9374 8.24752 14.9005 8.19234 14.8539 8.1459L10.8539 4.1459C10.8074 4.09941 10.7522 4.06253 10.6915 4.03738C10.6307 4.01222 10.5656 3.99927 10.4999 3.99927C10.3671 3.99927 10.2398 4.05201 10.1459 4.1459C10.052 4.23979 9.99927 4.36712 9.99927 4.4999C9.99927 4.63267 10.052 4.76001 10.1459 4.8539L13.2929 7.9999L1.4999 7.9999C1.36729 7.9999 1.24011 8.05258 1.14635 8.14635C1.05258 8.24011 0.9999 8.36729 0.9999 8.4999Z" fill="white"/>
</svg>
                    </div>
                    </div>
                          </div>
                          </div>
                          </div>
                
                </div>
                <div class="flex flex-col lg:flex-row gap-5 w-full">
                <div class="flex flex-col gap-5">
<div onClick={() => navigate("/earnings")} className=' cursor-pointer gap-4 p-4 flex justify-start rounded-lg flex-col bg-slate-900 w-full border border-slate-700'>
<span className='text-white flex flex-row items-center gap-1 mb-2'><CurrencyDollarIcon className='w-6 h-6 text-slate-400'/> Nagrody</span>
<div className='flex flex-col gap-3'>
<div className="flex justify-center items-center flex-row border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    
                    <div class="flex flex-row gap-3 items-center w-full pb-3 pt-3 pl-3">
                  <span className=' font-medium text-white text-xl'><img className='w-5 h-5' src={miejsce_1}></img></span>
                  <span className="text-xs text-white">Nagroda za 1. miejsce wynosi <span className='font-bold' style={{color:"#fbbf24"}}>300zł</span></span>
                  </div>
          
                  </div>
                  <div className="flex justify-center items-center flex-row border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    
                    <div class="flex flex-row gap-3 items-center w-full pb-3 pt-3 pl-3">
                  <span className=' font-medium text-white text-xl'><img className='w-5 h-5' src={miejsce_2}></img></span>
                  <span className="text-xs text-white">Nagroda za 2. miejsce wynosi <span className='font-bold' style={{color:"#94a3b8"}}>200zł</span></span>
                  </div>
          
                  </div>

                  <div className="flex justify-center items-center flex-row border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    
                    <div class="flex flex-row gap-3 items-center w-full pb-3 pt-3 pl-3">
                  <span className=' font-medium text-white text-xl'><img className='w-5 h-5' src={miejsce_3}></img></span>
                  <span className="text-xs text-white">Nagroda za 3. miejsce wynosi <span className='font-bold' style={{color:"#fb923c"}}>100zł</span></span>
                  </div>
          
                  </div>
</div>
                  <div onClick={() => navigate('/earnings')} className='bg-slate-700 pt-2 pb-2 flex flex-row gap-2 justify-center items-center w-full rounded text-sm font-medium cursor-pointer'>
                    Rozpocznij współpracę <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className=''>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999961 8.4999C0.999961 8.63251 1.05264 8.75968 1.14641 8.85345C1.24018 8.94722 1.36735 8.9999 1.49996 8.9999L13.293 8.9999L10.146 12.1459C10.0521 12.2398 9.99933 12.3671 9.99933 12.4999C9.99933 12.6327 10.0521 12.76 10.146 12.8539C10.2398 12.9478 10.3672 13.0005 10.5 13.0005C10.6327 13.0005 10.7601 12.9478 10.854 12.8539L14.854 8.8539C14.9005 8.80745 14.9375 8.75228 14.9627 8.69153C14.9879 8.63079 15.0009 8.56567 15.0009 8.4999C15.0009 8.43413 14.9879 8.36901 14.9627 8.30827C14.9375 8.24752 14.9005 8.19234 14.854 8.1459L10.854 4.1459C10.8075 4.09941 10.7523 4.06253 10.6915 4.03738C10.6308 4.01222 10.5657 3.99927 10.5 3.99927C10.3672 3.99927 10.2398 4.05201 10.146 4.1459C10.0521 4.23979 9.99933 4.36712 9.99933 4.4999C9.99933 4.63267 10.0521 4.76001 10.146 4.8539L13.293 7.9999L1.49996 7.9999C1.36735 7.9999 1.24018 8.05258 1.14641 8.14635C1.05264 8.24011 0.999961 8.36729 0.999961 8.4999Z" fill="white"/>
</svg>
                  </div>
                        </div>

                        <div className=' gap-3 p-4 flex justify-start rounded-lg flex-col bg-slate-900 w-full border border-slate-700 hidden'>
                <span className='text-white flex flex-row items-center gap-1 mb-2'><ClockIcon className='w-6 h-6 text-slate-400'></ClockIcon> Statystyki (dzisiaj)</span>
                <div className="w-full h-full flex justify-center items-center flex-row flex-wrap border border-slate-800 rounded-lg" style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
                    
                    <div class="flex flex-col items-start w-1/2 p-3 border-r border-slate-800">
                  <span className={' font-medium  text-xl w-1/2 '.concat(account.dayViews>=0 ? "text-green-400" : "text-red-400")}><span className='text-slate-400'>{account.dayViews>=0 ? "+" : "-"}</span>{short_formatter.format(Math.abs(account.dayViews)).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(account.dayViews).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Wyświetleń</span>
                  </div>

                  <div class={"flex flex-col items-start w-1/2 p-3 "}>
                  <span className={' font-medium  text-xl w-1/2 '.concat(account.dayLikes>=0 ? "text-green-400" : "text-red-400")}><span className='text-slate-400'>{account.dayLikes>=0 ? "+" : "-"}</span>{short_formatter.format(Math.abs(account.dayLikes)).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(account.dayLikes).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Polubień</span>
                  </div>
<br></br>
                  <div class={"flex flex-col items-start w-1/2 p-3 border-r border-t border-slate-800"}>
                  <span className={' font-medium  text-xl w-1/2 '.concat(account.dayComments>=0 ? "text-green-400" : "text-red-400")}><span className='text-slate-400'>{account.dayComments>=0 ? "+" : "-"}</span>{short_formatter.format(Math.abs(account.dayComments)).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(account.dayComments).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Komentarzy</span>
                  </div>

                  <div class={"flex flex-col items-start w-1/2 p-3 border-t border-slate-800"}>
                  <span className={' font-medium  text-xl w-1/2 '.concat(account.dayFollowers>=0 ? "text-green-400" : "text-red-400")}><span className='text-slate-400'>{account.dayFollowers>=0 ? "+" : "-"}</span>{short_formatter.format(Math.abs(account.dayFollowers)).match(/[a-z]+|[^a-z]+/gi)[0]}<span className="text-xs font-bold text-slate-400">{short_formatter.format(account.dayFollowers).match(/[a-z]+|[^a-z]+/gi)[1]}</span></span>
                  <span className="text-xs text-gray-400">Obserwacji</span>
                  </div>
          
                  </div>
                  
                        </div>

                        <div className=' gap-2 p-4 flex justify-start rounded-lg flex-col bg-slate-900 w-full border border-slate-700'>
                <span className='text-white flex flex-row items-center gap-1 mb-2'><ChevronDoubleUpIcon className='w-6 h-6 text-slate-400'></ChevronDoubleUpIcon> Najlepszy TikTok (24h)</span>
                <div className='p-2 gap-4 flex justify-start items-center rounded-lg flex-row border border-slate-800' style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
        <div>
        <div className=' w-16 h-24 rounded-lg !bg-cover !bg-no-repeat' style={{background: `url(${user.topTikTok.cover}), url("https://jmmplatform.pl/waiting.png")`}}>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <span className='text-slate-400 text-sm font-light flex items-center'>{new Date(parseInt(user.topTikTok.createTime)*1000).toLocaleString()}</span>
          <div className='font-light text-white text-2xl flex flex-row gap-2 flex-wrap'>
            <span className='flex flex-row text-xs gap-1 items-center break-keep'><HeartIcon className='h-4 w-4 text-slate-400'></HeartIcon> {short_formatter.format(user.topTikTok.likes)}</span>
            <span className='flex flex-row text-xs gap-1 items-center break-keep'><PlayIcon className='h-4 w-4 text-slate-400'></PlayIcon> {short_formatter.format(user.topTikTok.views)}</span>
            <span className='flex flex-row text-xs gap-1 items-center break-keep'><ChatBubbleOvalLeftEllipsisIcon className='h-4 w-4 text-slate-400'></ChatBubbleOvalLeftEllipsisIcon> {short_formatter.format(user.topTikTok.comments)}</span>
           <span className='flex flex-row text-xs gap-1 items-center break-keep'><img className='w-5 h-5 rounded-full' src={user.topTikTok.account_tiktok.avatar}/> {user.topTikTok.account_tiktok.username}</span>
          </div>
            </div>
                  </div>

                        </div>

</div>
<div className='flex flex-col-reverse lg:flex-col gap-5 w-full lg:w-11/12'>
<div className='bg-slate-900 border border-slate-700 p-4 rounded-lg relative'>
  <div className='absolute -top-4 -left-5'>
    <div className='w-8 h-8 opacity-60 bg-yellow-400 absolute top-4 left-5' style={{filter: "blur(17.88914680480957px)"}}></div>
  <img className='z-10' src={gift}></img>
  </div>
  <div className='flex flex-row items-center gap-6'>
    <div className='bg-slate-800' style={{width: '100%', height:'1px'}}></div>
    <div className='text-white text-lg font-medium flex flex-shrink-0 gap-2'><span className='text-amber-400'>NAGRODY </span> ZA {new Date().toLocaleString('default', { month: 'long' }).toUpperCase()}</div>
    <div className='bg-slate-800' style={{width: '100%', height:'1px'}}></div>
  </div>
  <div className='flex flex-col lg:flex-row gap-4 mt-4'>

    <div className='border border-slate-800 rounded-lg w-full'>
      <div className='p-3 text-center text-sm font-medium rounded-t-lg border-b border-slate-800' style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
              Największy wzrost obserwacji
      </div>
      <div className='flex flex-col'>
      <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topFollowers[0].avatar ? "" : "hidden")}></UserCircleIcon>
          <img className={'w-9 h-9 rounded-lg '.concat(!user.topFollowers[0].avatar ? "hidden": "")} src={user.topFollowers[0].avatar}/>
              <div className='flex flex-col'>
                <div className='text-sm' style={{height: "1.4em",
    lineHeight: "1.4em",
    overflow: "hidden"}}>{user.topFollowers[0].username ? "@".concat(user.topFollowers[0].username) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>+{short_formatter.format(user.topFollowers[0].grow)} obserwujących</div>
              </div>
              <img src={miejsce_1} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topFollowers[1].avatar ? "" : "hidden")}></UserCircleIcon>
          <img className={'w-9 h-9 rounded-lg '.concat(!user.topFollowers[1].avatar ? "hidden": "")} src={user.topFollowers[1].avatar}/>
              <div className='flex flex-col'>
                <div className='text-sm' style={{height: "1.4em",
    lineHeight: "1.4em",
    overflow: "hidden"}}>{user.topFollowers[1].username ? "@".concat(user.topFollowers[1].username) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>+{short_formatter.format(user.topFollowers[1].grow)} obserwujących</div>
              </div>
              <img src={miejsce_2} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topFollowers[2].avatar ? "" : "hidden")}></UserCircleIcon>
          <img className={'w-9 h-9 rounded-lg '.concat(!user.topFollowers[2].avatar ? "hidden": "")} src={user.topFollowers[2].avatar}/>
              <div className='flex flex-col'>
                <div className='text-sm' style={{height: "1.4em",
    lineHeight: "1.4em",
    overflow: "hidden"}}>{user.topFollowers[2].username ? "@".concat(user.topFollowers[2].username) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>+{short_formatter.format(user.topFollowers[2].grow)} obserwujących</div>
              </div>
              <img src={miejsce_3} className='ml-auto'></img>
        </div>
      </div>
    </div>

    <div className='border border-slate-800 rounded-lg w-full'>
      <div className='p-3 text-center text-sm font-medium rounded-t-lg border-b border-slate-800' style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
              Najbardziej lajkowane filmy
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topLikes[0].cover ? "" : "hidden")}></UserCircleIcon>
          <img className={'w-9 h-9 rounded-lg '.concat(!user.topLikes[0].cover ? "hidden": "")} src={user.topLikes[0].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm' style={{height: "1.4em",
    lineHeight: "1.4em",
    overflow: "hidden"}}>{user.topLikes[0]['account_tiktok.username'] ? "@".concat(user.topLikes[0]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{short_formatter.format(user.topLikes[0].likes)} polubień</div>
              </div>
              <img src={miejsce_1} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 border-b border-t border-slate-800 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topLikes[1].cover ? "" : "hidden")}></UserCircleIcon>
        <img className={'w-9 h-9 rounded-lg '.concat(!user.topLikes[1].cover ? "hidden": "")} src={user.topLikes[1].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm'>{user.topLikes[1]['account_tiktok.username'] ? "@".concat(user.topLikes[1]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{user.topLikes[1].likes ? short_formatter.format(user.topLikes[1].likes) : "0"} polubień</div>
              </div>
              <img src={miejsce_2} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topLikes[2].cover ? "" : "hidden")}></UserCircleIcon>
        <img className={'w-9 h-9 rounded-lg '.concat(!user.topLikes[2].cover ? "hidden": "")} src={user.topLikes[2].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm'>{user.topLikes[2]['account_tiktok.username'] ? "@".concat(user.topLikes[2]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{user.topLikes[2].likes ? short_formatter.format(user.topLikes[2].likes) : "0"} polubień</div>
              </div>
              <img src={miejsce_3} className='ml-auto'></img>
        </div>
      </div>
    </div>

    <div className='border border-slate-800 rounded-lg w-full'>
      <div className='p-3 text-center text-sm font-medium rounded-t-lg border-b border-slate-800' style={{background: "linear-gradient(180deg, rgba(51, 65, 85, 0.50) 0%, rgba(51, 65, 85, 0.00) 100%)"}}>
              Najpopularniejsze flmy
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topTikToks[0].cover ? "" : "hidden")}></UserCircleIcon>
        <img className={'w-9 h-9 rounded-lg '.concat(!user.topTikToks[0].cover ? "hidden": "")} src={user.topTikToks[0].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm'>{user.topTikToks[0]['account_tiktok.username'] ? "@".concat(user.topTikToks[0]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{user.topTikToks[0].views ? short_formatter.format(user.topTikToks[0].views) : "0"} wyświetleń</div>
              </div>
              <img src={miejsce_1} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 border-b border-t border-slate-800 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topTikToks[1].cover ? "" : "hidden")}></UserCircleIcon>
        <img className={'w-9 h-9 rounded-lg '.concat(!user.topTikToks[1].cover ? "hidden": "")} src={user.topTikToks[1].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm'>{user.topTikToks[1]['account_tiktok.username'] ? "@".concat(user.topTikToks[1]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{user.topTikToks[1].views ? short_formatter.format(user.topTikToks[1].views) : "0"} wyświetleń</div>
              </div>
              <img src={miejsce_2} className='ml-auto'></img>
        </div>
        <div className='flex flex-row p-3 gap-3 items-center'>
        <UserCircleIcon className={'text-slate-300 w-9 h-9 '.concat(!user.topTikToks[2].cover ? "" : "hidden")}></UserCircleIcon>
        <img className={'w-9 h-9 rounded-lg '.concat(!user.topTikToks[2].cover ? "hidden": "")} src={user.topTikToks[2].cover}/>
              <div className='flex flex-col'>
                <div className='text-sm'>{user.topTikToks[2]['account_tiktok.username'] ? "@".concat(user.topTikToks[2]['account_tiktok.username']) : "Wkrótce"}</div>
                <div className='text-xs opacity-90'>{user.topTikToks[2].views ? short_formatter.format(user.topTikToks[2].views) : "0"} wyświetleń</div>
              </div>
              <img src={miejsce_3} className='ml-auto'></img>
        </div>
      </div>
    </div>
  </div>
</div>
                <div className=' gap-5 p-4 flex justify-start rounded-lg flex-col bg-slate-900 border border-slate-700'>
                <div className='flex justify-between items-center'>
                <span className='font-light text-slate-200'>Wyświetlenia</span>
                <span className='font-normal text-slate-500 text-xs'>{period == 7 || period == 24 ? "Ostatnie 7 dni" : ""}{period == 14 ? "Ostatnie 14 dni" : ""}{period == 30 ? "Ostatnie 30 dni" : ""}{period == 0 ? "Od zawsze" : ""}</span>
                </div>
                <div className='h-60 w-full flex justify-center items-center'>
                  {chartData.length > 1 ?
                <Line options={options} data={data} />
                  : 
                  
                  <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                        </div>
                        <div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 hidden'>
                <div className='flex justify-between items-center'>
                <span className='font-light text-slate-200'>Okresy największej aktywności</span>
                {date2 ? <span className='font-normal text-slate-500 text-xs flex flex-row gap-1'><ArrowLeftCircleIcon className='w-4 h-4 text-slate-300 cursor-pointer' onClick={() => handleDataIndex("previous")}/> <span className='w-max'>{date2}</span> <ArrowRightCircleIcon className='w-4 h-4 text-slate-300 cursor-pointer' onClick={() => handleDataIndex("next")}/></span> : ""}
                </div>
                <div className=' h-60 w-full flex justify-center items-center'>
                  {chartData2.length > 3 ?
                <Bar options={options} data={data2} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                        </div>

</div>
                        </div>
            {/*<Card title="Komentarze" account={account} period={period}/>*/}
                        <div class="flex flex-row gap-3">
                        {/*<div className='flex flex-col gap-3 w-1/3'>
<div className='h-fit bg-slate-900 rounded-xl p-2.5 flex flex-row gap-3 items-center'>
<div className='flex justify-center items-center h-fit bg-slate-800 rounded-lg p-1.5'>
<MoonIcon className='w-5 h-5 text-slate-300'/>
</div>
<div className=' text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
{account.nightViews > 0 && new Date().getHours() > 7 ? <>Wygenerowałeś {short_formatter.format(account.nightViews)} wyświetleń w nocy</> : "Statystyki nocne nie są jeszcze dostępne!"}
</div>
</div>
<div className='h-fit bg-slate-900 rounded-xl p-2.5 flex flex-row gap-3 items-center'>
<div className='flex justify-center items-center bg-slate-800 rounded-lg p-1.5 h-fit'>
<ClockIcon className='w-5 h-5 text-slate-300'/>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
{account.dayViews > 0 ? <>Wygenerowałeś {short_formatter.format(account.dayViews)} wyświetleń w 24h</> : "Statystyki nie są jeszcze dostępne!"}
</div>
</div>
<div className=' h-fit bg-slate-900 rounded-xl p-2.5 flex-row gap-3 flex items-center'>
<div className='flex justify-center items-center bg-slate-800 rounded-lg p-1.5 h-fit'>
<SunIcon className='w-5 h-5 text-slate-300'/>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
{account.sevenViews > 0 ? <>Wygenerowałeś {short_formatter.format(account.sevenViews)} wyświetleń w 7 dni</> : "Statystyki nie są jeszcze dostępne!"}
</div>
</div>
<div className=' h-fit bg-slate-900 rounded-xl p-2.5 flex flex-row gap-3 items-center'>
<div className='flex justify-center items-center bg-slate-800 rounded-lg p-1.5 h-fit'>
<SunIcon className='w-5 h-5 text-slate-300'/>
</div>
<div className='text-slate-300 text-sm flex items-center break-words' style={{hyphens: 'auto'}}>
{account.monthViews > 0 ? <>Wygenerowałeś {short_formatter.format(account.monthViews)} wyświetleń w 30 dni</> : "Statystyki nie są jeszcze dostępne!"}
</div>
</div>
                  </div>*/}
</div>
            {/*</Masonry>
            </ResponsiveMasonry>*/}
            <div onClick={() => navigate("/topday")} className='hidden cursor-pointer mt-4 rounded-lg p-2 px-3 bg-slate-900  flex justify-center items-center text-slate-300 text-center'>
                Zobacz ostatnie najpopularniejsze tiktoki na platformie
            </div>
         </div>
        </motion.main>
        </>
    )
}

export default Dashboard
