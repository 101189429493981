import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ClockIcon, CurrencyDollarIcon, PlusIcon, UserCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import AdminNavbar from "./AdminNavbar";
import { Menu, Transition } from '@headlessui/react'
import { priceformatter } from "../../utils/utils";

const AdminRefs = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [refs, setRefs] = useState([]);
    const [refSearch, setRefSearch] = useState('');
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getRefs = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/refs', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setRefs(response.data);
        setLoaded(true);
        
    }

    const handleSearch = async () => {
        if(refSearch.length > 0){
            const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/refs?code=' + refSearch, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setRefs(response.data);
        }
    }


    useEffect(() => {
        refreshToken();
        getRefs();
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>Zakupy ebooka z kodem</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz sprawdzić listę zakupów ebooka z kodem użytkownika platformy.</span>
        </div>        
        <div className="flex flex-row gap-2 items-center justify-between mb-6 w-full">
        <input type="text" className=" w-2/3 bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block px-2.5 py-3 mb-1" placeholder="Kod użytkownika (bez linka)" value={refSearch} onChange={(e) => setRefSearch(e.target.value)}/>
        <div className="w-1/3 bg-sky-600 p-2 rounded-lg whitespace-nowrap text-center" onClick={handleSearch}>Wyszukaj</div>
        </div>
        <div className="flex flex-col gap-3">
        {refs.map((object, i) =>
        <>

        <div className="flex items-center bg-slate-900 p-2 rounded-lg">
            <div className="flex flex-col gap-1">
            <div className="flex flex-row items-start gap-2">
                <div className="flex items-start justify-start p-1 bg-slate-800 rounded-lg">
                <CurrencyDollarIcon className="w-7 h-7 text-sky-400"></CurrencyDollarIcon>
                </div>
                <div className="flex flex-col">
                    <span className="text-sm">{object.ref_user.nick} <span className="text-slate-300">({object.ref_user.refCode})</span></span>
                    <span className="text-slate-400 text-xs">{object.createdAt}</span>
                </div>
            </div>
            <div className="flex flex-row gap-1 flex-wrap mt-1">
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1"><CurrencyDollarIcon className="w-4 h-4 text-white"></CurrencyDollarIcon> {priceformatter.format(object.price_full)}</div>
            <div className="p-1 bg-sky-500 text-white text-xs rounded-lg flex flex-row gap-1"><UserCircleIcon className="w-4 h-4 text-white"></UserCircleIcon> {object.first_name} {object.last_name}</div>

            </div>
            </div>
</div>

    </>
        )}
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminRefs;