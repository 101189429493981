import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import { short_formatter } from "../../utils/chart";
import { TikTok } from "../../utils/utils";
import AdminNavbar from "./AdminNavbar";

const AdminTikToks = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [status, setStatus] = useState('all');
    const [tiktoks, setTikToks] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getTikToks = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/tiktoks?status=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setTikToks(response.data);
        setLoaded(true);
    }


    const handleStatus = async(props) => {
        console.log(props);
        await setStatus(props);
        await getTikToks(props);
    }

    useEffect(() => {
        refreshToken();
        getTikToks("all");
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
        <span className='text-slate-50 text-2xl'>TikToki</span>
        <span className='text-slate-300 font-normal text-sm'>W tym miejscu możesz sprawdzić tiktoki użytkowników.</span>
        </div>
        <button onClick={() => navigate("/admin/tiktoktinder")} className="mb-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-2 px-4 rounded w-full flex justify-center gap-2 items-center"><CheckBadgeIcon className="text-white w-5 h-5"></CheckBadgeIcon> Tinder Swipe</button>
        <div className="flex flex-row gap-1 mb-4 flex-wrap">
        <div onClick={() => {handleStatus("all")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "all" ? "bg-green-900" : "bg-slate-800")}>Wszystko</div>
        <div onClick={() => {handleStatus("accepted")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "accepted" ? "bg-green-900" : "bg-slate-800")}>Zaakceptowane</div>
        <div onClick={() => {handleStatus("waiting")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "waiting" ? "bg-green-900" : "bg-slate-800")}>Oczekujące</div>
        <div onClick={() => {handleStatus("rejected")}} className={"p-2 rounded-xl cursor-pointer ".concat(status == "rejected" ? "bg-green-900" : "bg-slate-800")}>Odrzucone</div>
        </div>
    <div className="flex flex-col gap-4">
    {tiktoks.length > 0 ? tiktoks.map((object, i) =>
    <TikTok id={object.id} username={object.account_tiktok.username} admin={1} avatar={object.account_tiktok.avatar} cover={object.cover} likes={short_formatter.format(object.likes)} comments={short_formatter.format(object.comments)} views={short_formatter.format(object.views)} status={object.status} date={new Date(parseInt(object.createTime)*1000).toLocaleString()}></TikTok>
    ) : <span className="text-slate-400 text-base text-center">Nie ma jeszcze żadnych tiktoków!</span>}</div>
    </div>
    </motion.main>
    </>
        );

}

export default AdminTikToks;