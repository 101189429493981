import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, BookOpenIcon, ChevronDownIcon, ChevronUpIcon, FolderIcon, MusicalNoteIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { downloadVideo } from "../utils/utils";
import moment from "moment";

const Helpful = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account !== undefined ? account.avatar : ""} username={account.username}></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
        <span className='text-slate-50 text-2xl'>Przydatne do tiktoków</span>
        <span className='text-slate-300 font-normal text-sm'>Poniżej znajdziesz wiele przydatnych rzeczy, które pomogą Ci w tworzeniu Twoich tiktoków.</span>
        </div>


        <div onClick={() => navigate("/music")} className="cursor-pointer bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
<MusicalNoteIcon className="text-slate-300 w-5 h-5"></MusicalNoteIcon>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Muzyka</span>
        <span className="text-xs text-slate-400">Znajdziesz tu listę utworów, które idealnie nadają się do tworzenia tiktoków.</span>
        </div>
        </div>

        <div className="flex mt-4 flex-col gap-2">
        <div onClick={() => navigate("/quotations")} className="cursor-pointer bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
        <svg className="text-slate-300 w-5 h-5" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0002 14.6668L17.6668 9.3335H12.3335V0.000167847H21.6668V9.3335L19.0002 14.6668H15.0002ZM3.00016 14.6668L5.66683 9.3335H0.333496V0.000167847H9.66683V9.3335L7.00016 14.6668H3.00016Z" fill="#CBD5E1"/>
</svg>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Motywujące cytaty</span>
        <span className="text-xs text-slate-400">Znajdziesz tu listę motywacyjnych cytatów, które można łatwo skopiować.</span>
        </div>
        </div>
    

        <div className="opacity-40 bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
        <svg fill="#CBD5E1" className="w-5 h-5 text-slate-300" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Tiktokowe inspiracje</span>
        <span className="text-xs text-slate-400">Znajdziesz tu podobne tiktoki innych, które wygenerowały duże zasięgi.</span>
        </div>
        </div>

        <div onClick={() => navigate("/tutorials")} className=" cursor-pointer bg-slate-900 border border-solid border-1 border-slate-800 p-3 flex flex-row gap-3 rounded-lg">
        <div className="bg-slate-800 rounded-lg p-3 flex w-fit justify-center items-center h-fit">
<BookOpenIcon className="text-slate-300 w-5 h-5"></BookOpenIcon>
        </div>
        <div className="flex flex-col">
        <span className="text-base text-slate-200">Jak tworzyć tiktoki?</span>
        <span className="text-xs text-slate-400">Znajdziesz poradniki o tym jak tworzyć tiktoki, żeby były ciekawe i zasięgowe. </span>
        </div>
        </div>

        </div>

          </div>
          </motion.main>
    </>
        );

}

export default Helpful;