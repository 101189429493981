import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ChatBubbleOvalLeftEllipsisIcon, CheckCircleIcon, ChevronRightIcon, HeartIcon, PlayIcon, ClipboardIcon, InformationCircleIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { options, short_formatter } from "../utils/chart";
import { Modal } from "../fragments/Modal";
import dateFormat from 'dateformat';
import { Line } from "react-chartjs-2";

const TikTokPage = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [tiktok, setTikTok] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartType, setChartType] = useState('views');
    const navigate = useNavigate();
    let { id } = useParams();


    const [modal, setModal] = useState({ show: false, data: null });

    const openDelete = () => {
      setModal({ show: true, data: { type: 'deleteTiktok', id: id } });
    };
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            getTikTok(id);
            setLoaded(true);
            
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getTikTok = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'tiktok?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setTikTok(response.data);
        setCurrentAccount(response.data.account);

        setLabels([]);
        setChartData([]);
        response.data.stats.forEach(function (stat){
          setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
          setChartData(chartData => [...chartData, stat.views])
        })

    }

    const setCurrentAccount = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'account?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data);
    }

        const data = {
        labels,
        datasets: [
          {
            data: chartData,
            borderColor: '#22C55E',
            pointRadius: 4,
            tension: 0.4,
            fill: 'start',
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(34, 197, 94, 0.1)");
              gradient.addColorStop(0.75, "rgba(34, 197, 94, 0)");
              gradient.addColorStop(1, "transparent");
              return gradient;
            },
          },
        ],
      };

          const handleChart = async(props) => {
        setChartType(props);
        setLabels([]);
        setChartData([]);
        switch(props){
            case "views":
                tiktok.stats.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.views])
                  })
                  break;
            case "likes":
                tiktok.stats.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.likes])
                  })
                  break;
            case "comments":
                tiktok.stats.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.comments])
                  })
                  break;
            default:
                tiktok.stats.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.views])
                  })
                  break;
        }
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
{modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u>Moje Tiktoki</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> TikTok #{id}</span>
        <span className='text-slate-50 text-2xl'>TikTok #{id}</span>
        </div>
        <div className="flex flex-col md:flex-row md:gap-10">
        <div class="w-full h-48 md:h-auto relative">
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-50" style={{ filter: 'blur(22.5px)', backgroundImage: "url(" + tiktok.cover + ")"}}></div>
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-xl" style={{backgroundImage: "url(" + tiktok.cover + ")"}}></div>
</div>
<div>
        <div className="text-white text-lg font-normal mt-7 md:mt-0 mb-4">Profil</div>
        <div className=" bg-slate-900 inline-flex w-full justify-between items-center gap-x-1.5 rounded-xl px-3 py-3 text-sm font-semibold text-white">
        <div className='flex flex-row items-center gap-3'>
        <img className='w-8 h-8 rounded-3xl border border-solid border-slate-800' src={account.avatar}/>
<span className='text-slate-300 text-lg font-light'>{account.username}</span>
</div>
</div>
<div className="text-white text-lg font-normal mt-7 mb-4">Aktywność</div>
<div className=' gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-base flex flex-row items-center gap-1'><HeartIcon className="w-6 h-6 text-slate-400"></HeartIcon> Polubienia</span>
                <span className='font-light text-white text-2xl'>{short_formatter.format(tiktok.likes)}</span>
                        </div>
                        <div className="flex flex-row gap-5 mt-5">
                        <div className='w-1/2 gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-base flex flex-row items-center gap-1'><ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 text-slate-400"></ChatBubbleOvalLeftEllipsisIcon> Komentarze</span>
                <span className='font-light text-white text-2xl'>{short_formatter.format(tiktok.comments)}</span>
                        </div>
                        <div className='w-1/2 gap-3 py-3 px-3 flex justify-start rounded-lg flex-col bg-slate-900 '>
                <span className='text-white font-light text-base flex flex-row items-center gap-1'><PlayIcon className="w-6 h-6 text-slate-400"></PlayIcon> Wyświetlenia</span>
                <span className='font-light text-white text-2xl'>{short_formatter.format(tiktok.views)}</span>
                        </div>
                        </div>
                        <div className="text-white text-lg font-normal mt-7 mb-4">Szczegóły</div>
                        <div className="flex flex-col gap-3">
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">ID</div>
                            <div className="text-white flex flex-row items-center gap-2">{id} <ClipboardIcon className="text-sky-500 w-5 h-5 font-extrabold"></ClipboardIcon></div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Status</div>
                            {tiktok.status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {tiktok.status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {tiktok.status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Data dodania</div>
                            <div className="text-white">{new Date(tiktok.createdAt).toLocaleString("pl")}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Data aktualizacji</div>
                            <div className="text-white">{new Date(tiktok.updatedAt).toLocaleString("pl")}</div>
                        </div>
                        </div>

                        
                        <div className="flex flex-col gap-3 mt-6 justify-center items-center">
        <div className="flex flex-row gap-1 mb-4 flex-wrap">
        <div onClick={() => {handleChart("views")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "views" ? "bg-green-900" : "bg-slate-800")}>Wyświeltenia</div>
        <div onClick={() => {handleChart("likes")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "likes" ? "bg-green-900" : "bg-slate-800")}>Polubienia</div>
        <div onClick={() => {handleChart("comments")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "comments" ? "bg-green-900" : "bg-slate-800")}>Komentarze</div>        </div>
        <div className='h-44 w-full flex justify-center items-center'>
                  {labels.length > 1 ?
                <Line options={options} data={data} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                </div>

                        <div className="mt-8 flex flex-row items-start sm:items-center gap-2 mb-8">
                            <InformationCircleIcon className="w-10 text-sky-400"></InformationCircleIcon><span className="text-slate-300">Statystyki o zasięgach tego tiktoka są aktualizowane co 24 godziny. </span>
                        </div>

                        <div onClick={() => window.open(tiktok.url, '_blank').focus()} className="bg-slate-900 cursor-pointer border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                        <svg fill="#FFFFFF" className="w-5 h-5" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                         Zobacz na tiktoku
        </div>
        <div onClick={openDelete} className="cursor-pointer mt-4 bg-red-900/30 border border-solid border-red-500 rounded-md text-red-500 text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Usuń TikToka
        </div>
        </div>
        </div></div>
    </motion.main>
    </>
        );

}

export default TikTokPage;