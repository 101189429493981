import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import './index.css';
import axios from "axios";
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

axios.defaults.withCredentials = true;

root.render(
  <BrowserRouter>
  <App />
  </BrowserRouter>);

