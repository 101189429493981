import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, DocumentDuplicateIcon, DocumentIcon, FolderIcon, HandThumbUpIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { downloadVideo } from "../utils/utils";
import moment from "moment";

const Quotations = () => {


    const navigate = useNavigate();

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [quotations, setQuotations] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getQuotations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const likeQuota = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'likequota?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        getQuotations();
    }

  const getQuotations = async () => {
    const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'quotations', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    
    setQuotations(response.data);
}

if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div className="loader"></div></div>);

    return (
        <>
                 <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account !== undefined ? account.avatar : ""}></Navbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-4 flex flex-col gap-1'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/helpful")}>Przydatne do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> Motywacyjne cytaty</span>
        <span className='text-slate-50 text-2xl'>Motywujące cytaty</span>
        </div>

        <div className="flex mt-4 flex-col gap-2">
        {quotations.map((object, i) =>
        <div className="flex bg-slate-900 border border-1 border-solid border-slate-800 rounded-lg flex-col p-3 gap-3">
        <span className="text-slate-200 text-sm">
        {object.text}
        </span>
        <div className="flex flex-row w-full gap-2">
        <div onClick={() => navigator.clipboard.writeText(object.text)} className="w-1/2 cursor-pointer bg-slate-800 flex justify-center items-center gap-2 rounded-lg p-2 text-xs"><DocumentDuplicateIcon className="w-4 h-4 text-sky-500"></DocumentDuplicateIcon>Kopiuj cytat</div>
        <div onClick={() => likeQuota(object.id)} className={"w-1/2 bg-green-900/30 text-green-500 flex justify-center items-center gap-2 rounded-lg p-2 text-xs ".concat(object.liked ? "opacity-40 cursor-not-allowed" : "cursor-pointer")}><HandThumbUpIcon className="w-4 h-4 text-green-500"></HandThumbUpIcon>{object.liked ? "Polubiono" : "Polub"}</div>
       </div>
        </div>
        )}
        </div>

          </div>
          </motion.main>
    </>
        );

}

export default Quotations;