import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {CheckCircleIcon, ClockIcon, CurrencyDollarIcon, LockOpenIcon, PaperClipIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import { short_formatter } from "../../utils/chart";
import AdminNavbar from "./AdminNavbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';
import { priceformatter } from "../../utils/utils";
import { upload } from "@testing-library/user-event/dist/upload";

const AdminUser = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [user, setUser] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [forref, setForref] = useState(0.2*100);
    const [dlawszystkich, setDlawszystkich] = useState(0.4*100);
    const [foruser, setForuser] = useState(0.5*100);
    const [nagrody, setNagrody] = useState(0.1*100);
    const inputFile = useRef(null) 
    let { id } = useParams();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            

        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getUser = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/user?id=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        
        setUser(response.data);
        setForref(response.data.percents.forref_factor*100);
        setDlawszystkich(response.data.percents.dlawszystkich_factor*100);
        setNagrody(response.data.percents.nagrody_factor*100);
        setForuser(response.data.percents.foruser_factor*100);
        getStatistics(response.data.id);
    }

    const getStatistics = async (props) => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/statistcs?id=' + props, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setStatistics(response.data[0]);
        setLoaded(true);
    }

    const handleSetCode = async (userid) => {
        const {value: code } = await MySwal.fire({
            title: 'Dodaj kod ebook',
            html: 'Musi on być najpierw utworzony na sklepie z ebookiem',
            input: 'text',
            inputLabel: 'Kod',
            inputPlaceholder: 'Wpisz kod dla użytkownika',
            inputValue: user.refCode,
            showCancelButton: true,
            confirmButtonText:'Dodaj',
            cancelButtonText: 'Anuluj'
        });
        if(code !== undefined){
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/setcode?id=' + userid + '&code=' + code, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getUser();
              Swal.fire('Ustawiono kod ' + code, '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
        }
    };

    const reject = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz odrzucić to konto?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewuser?id=' + id + '&action=reject', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getUser();
              Swal.fire('Odrzucono konto', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const accept = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz zaakceptować to konto?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewuser?id=' + id + '&action=accept', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getUser();
              Swal.fire('Zaakceptowao konto', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const forrefChange = async(percent) => {
        if(percent <= 100){
        setForref(percent);
        }else{
            setForref(forref);
        }
    }

    const dlawszystkichChange = async(percent) => {
        setDlawszystkich(percent);
    }

    const nagrodyChange = async(percent) => {
        setNagrody(percent);
    }

    const foruserChange = async(percent) => {
        setForuser(percent);
    }

    const changePercents = async () => {
        try{

            if(100*(forref/100)-100*(forref/100)*(foruser/100)-100*(forref/100)*(nagrody/100)-100*(forref/100)*(dlawszystkich/100) < 0){
                return Swal.fire('Błąd', 'Nierozgospodarowane środki nie mogą być mniejsze od zera', 'error');
            }

            await refreshToken();
            const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'admin/editpercents', {
                forref: forref/100,
                dlawszystkich: dlawszystkich/100,
                nagrody: nagrody/100,
                foruser: foruser/100,
                id: user.id
            }, {headers: {
                Authorization: `Bearer ${token}`
            }});

            if(response.data.status == "success"){
                await getUser();
                Swal.fire('Zedytowano procenty', "", 'success');
            }else{
                Swal.fire('Błąd', response.data.message, 'error');
            }

        }catch(e){
            console.log(e);
            alert(e.message)
        }
    }

    const uploadInvoiceButtonClick = () => {
        inputFile.current.click();
      };

    const uploadInvoice = async (e) => {
        console.log("test")
        console.log(e.target.files);
        if(e.target.files){
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy chcesz dodać umowę temu użytkownikowi?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await refreshToken();
                    const formData = new FormData();
                    formData.append('invoice', e.target.files[0]);
                    formData.append('userId', id);
                    const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'admin/addinvoice', formData, {headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }});
                if(response.data.status == "success"){
                    await getUser();
                    Swal.fire('Dodano umowę', "", 'success');
                }
            }catch(e){
                console.log(e);
                Swal.fire('Błąd', e.response.data.message, 'error');
            }
            }
          })
        }
    }

    const seeInvoice = () => {
        fetch(process.env.REACT_APP_API_URL + "invoice?filename=" + user.invoice, {'headers': { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data'}} ) // FETCH BLOB FROM IT
        .then((response) => response.blob())
        .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus(); // window.open + focus
      }).catch((err) => {
        console.log(err);
      });
    }

    const resetPassword = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz zresetować hasło tego użytkownika?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/resetpassword?id=' + id, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getUser();
              Swal.fire('Zresetowano hasło', 'Tymczasowe hasło: temp', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    useEffect(() => {
        refreshToken();
        getUser();
        
    }, []);

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className="flex justify-center gap-3 flex-col items-center mb-14">
          {user.accounts.length > 0 ?
          <div class="h-20 w-20 relative">
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-50" style={{ filter: 'blur(22.5px)', willChange: 'transform', backgroundImage: "url(" + user.accounts[0].avatar + ")"}}></div>
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-full" style={{backgroundImage: "url(" + user.accounts[0].avatar + ")"}}></div>
</div>
: ""}
<div className="flex gap-1 flex-col justifty-center items-center">
<span className="font-bold text-white text-lg">{user.nick}</span>
{user.role == "admin" ?
<span className="font-semibold text-red-500 px-1 py-0.5 bg-red-900/30 rounded text-sm">{user.role}</span>
: " "}
</div>
</div>
<div className="text-slate-200 text-lg font-normal mt-7 mb-4">Informacje</div>
<div className="flex gap-2 flex-col">
<div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Nazwa użytkownika</div>
                            <div className="text-slate-400 text-base">{user.nick}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Instagram</div>
                            <div className="text-slate-400 text-base">{user.instagram}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Adres e-mail</div>
                            <div className="text-slate-400 text-base">{user.mail}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Numer telefonu</div>
                            <div className="text-slate-400 text-base">{user.phone}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Ostatnia wizyta</div>
                            <div className="text-slate-400 text-base">{user.lastVisit}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Rola</div>
                            <div className="text-slate-400 text-base">{user.role}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Kod (ebook)</div>
                            <div className="text-slate-400 text-base text-end">{user.refCode ? "https://milioner2023.pl/?ref=" + user.refCode : "Brak"}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Zarobki z wyświetleń</div>
                            <div className="text-slate-400 text-base">{priceformatter.format(user.viewsEarnings)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Zarobki z ebooka</div>
                            <div className="text-slate-400 text-base">{priceformatter.format(user.earnings)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Sprzedane</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(user.sold)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Status</div>
                            {user.status === "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {user.status === "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {user.status === "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Ilość kont</div>
                            <div className="text-slate-400 text-base">{user.accounts.length}</div>
                        </div>
<div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Ostrzeżenia</div>
                            <div className="text-green-500 text-base">0/3</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Obserwujący</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(statistics.totalFollowers)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Wyświetlenia</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(statistics.totalViews)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Polubienia</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(statistics.totalLikes)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300 text-sm">Komentarze</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(statistics.totalComments)}</div>
                        </div>
                        </div>

                        <div onClick={() => handleSetCode(user.id)} className="bg-slate-900 mt-4 cursor-pointer border border-solid border-slate-800 rounded-md text-white font-normal p-2 flex justify-center flex-row items-center gap-2">
    <CurrencyDollarIcon className="w-4 h-4 text-sky-500"></CurrencyDollarIcon>         Ustaw kod (ebook)
</div>
{user.invoice ? 
<div onClick={() => seeInvoice()} className="bg-slate-900 mt-4 cursor-pointer border border-solid border-slate-800 rounded-md text-white font-normal p-2 flex justify-center flex-row items-center gap-2">
    <PaperClipIcon className="w-4 h-4 text-sky-500"></PaperClipIcon>         Zobacz umowę
</div>
: ""}

<div onClick={uploadInvoiceButtonClick} className="bg-slate-900 mt-4 cursor-pointer border border-solid border-slate-800 rounded-md text-white font-normal p-2 flex justify-center flex-row items-center gap-2">
    <PaperClipIcon className="w-4 h-4 text-sky-500"></PaperClipIcon>         Wgraj umowę
</div>
<input type='file' id='file' ref={inputFile} onChange={uploadInvoice} style={{display: 'none'}}/>


        <div className="text-slate-200 text-lg font-normal mt-9 mb-4">Procenty zarobków</div>
        <div className="flex flex-col gap-2">
        <div>
            <div className="text-slate-400 text-sm">
                Procent z ebooka na system zarabiania na platformie
            </div>
            <input value={forref} onChange={(e) => forrefChange(e.target.value)} className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1 mt-2"></input>
        </div>
        <div>
            <div className="text-slate-400 text-sm">
                Procent z systemu zarabiania do puli na wyświetlenia
            </div>
            <input value={dlawszystkich} onChange={(e) => dlawszystkichChange(e.target.value)} className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1 mt-2"></input>
        </div>
        <div>
            <div className="text-slate-400 text-sm">
                Procent z systemu zarabiania do puli na nagrody
            </div>
            <input value={nagrody} onChange={(e) => nagrodyChange(e.target.value)} className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1 mt-2"></input>
        </div>
        <div>
            <div className="text-slate-400 text-sm">
                Procent z systemu zarabiania, który idzie na konto użytkownika
            </div>
            <input value={foruser} onChange={(e) => foruserChange(e.target.value)} className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1 mt-2"></input>
        </div>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Koszt zakupu
            </div>
            <div className="text-white text-sm">
                100,00 zł
            </div>
        </div>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Na system zarabiania
            </div>
            <div className="text-white text-sm">
                {priceformatter.format(100*(forref/100))}
            </div>
        </div>
        <hr style={{borderTop: '1px solid gray'}}></hr>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Do puli na wyświetlenia
            </div>
            <div className="text-white text-sm">
                {priceformatter.format(100*(forref/100)*(dlawszystkich/100))}
            </div>
        </div>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Do puli na nagrody
            </div>
            <div className="text-white text-sm">
                {priceformatter.format(100*(forref/100)*(nagrody/100))}
            </div>
        </div>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Na konto użytkownika
            </div>
            <div className="text-white text-sm">
                {priceformatter.format(100*(forref/100)*(foruser/100))}
            </div>
        </div>
        <div className="flex flex-row justify-between items-center">
        <div className="text-slate-400 text-sm">
                Nierozgospodarowane
            </div>
            <div className="text-white text-sm">
            {priceformatter.format(100*(forref/100)-100*(forref/100)*(foruser/100)-100*(forref/100)*(nagrody/100)-100*(forref/100)*(dlawszystkich/100))}
            </div>
        </div>
        <button onClick={changePercents} className="mt-3 bg-sky-600 hover:bg-sky-500 text-white font-medium text-sm py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PencilSquareIcon className="text-white w-4 h-4"></PencilSquareIcon> Zmień procenty zarobków</button>
        <div>
</div>
        </div>
        <div>

        </div>

                        <div className="text-slate-200 text-lg font-normal mt-9 mb-4">Profile na tiktoku</div>
                        <div className="flex flex-col gap-2">
                        {user.accounts.map((object, i) =>
        <>
                <a
                  href=""
                  onClick={() => navigate("/admin/account/" + object.id)}
                  className='block px-2 py-3 text-sm rounded-md text-slate-300 bg-slate-900'
                >
                    <div className="flex justify-between items-center">
                          <div className='flex flex-row items-center gap-2'>
        <img className='w-7 h-7 rounded-3xl border border-solid border-gray-500' src={object.avatar}/>
<span className='font-normal text-base text-white overflow-hidden text-ellipsis whitespace-nowrap' style={{maxWidth: '9rem'}}>{object.username}</span>
</div>
{object.status == "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {object.status == "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {object.status == "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
</div>
                </a>
            </>
            )}
                    {user.status == "waiting" || user.status == "rejected" ?
        <div className="flex flex-row gap-2 mt-4">
        <div onClick={accept} className="w-1/2 bg-green-900 cursor-pointer border border-solid border-green-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Zatwierdź
        </div>
        <div onClick={reject} className="w-1/2 bg-red-900 cursor-pointer border border-solid border-red-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Odrzuć
        </div>
        </div>
        :    
        <>  
        <div onClick={resetPassword} className="bg-slate-900 mt-4 cursor-pointer border border-solid border-slate-800 rounded-md text-white font-normal p-2 flex justify-center flex-row items-center gap-2">
    <LockOpenIcon className="w-4 h-4 text-sky-500"></LockOpenIcon>         Resetuj hasło
</div>
        
        <div onClick={reject} className="cursor-pointer mt-2 bg-red-900/30 border border-solid border-red-500 rounded-md text-red-500 font-normal p-2 flex justify-center flex-row items-center gap-2">
        Zablokuj dostęp użytkownikowi
</div> 
</>}
        </div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminUser;