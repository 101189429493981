import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { createRoot } from 'react-dom/client';
 
mapboxgl.accessToken = 'pk.eyJ1IjoiamFubzQiLCJhIjoiY2xscDY1Nmp2MDNrazNwcDgwdmd0ZDB5YyJ9.56UtxehEsGZ-qQUez1LF0Q';

export default function MapContainer({mapAccounts}) {
  const mapContainer = useRef(null);
  const [lng, setLng] = useState(19.08376);
  const [lat, setLat] = useState(51.87806);
  const [zoom, setZoom] = useState(5);

  const [width, setWidth] = useState<number>(window.innerWidth);

const isMobile = width <= 768;

  const Marker = ({ avatar, children, padding }) => {
    /*const _onClick = () => {
      onClick(feature.properties.description);
    };*/
  
    return (
      <button style={{ backgroundImage: `url(${avatar}), url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi3uQLGHIrl-NnEAOo0pymINEVN7IJhXFsW0UbUmKCjpaIjG90KQDoAWE5ju5z1kVzYJ0&usqp=CAU")`, padding: padding}} /*onClick={_onClick}*/ className="marker">
        {children}
      </button>
    );
  };

  useEffect(() => {
    if(isMobile){
      setZoom(4);
    }
    if (map) return; // initialize map only once
    var map = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/jano4/clm4uhby400uk01r412cn0thv?optimize=true',
    center: [lng, lat],
    zoom: zoom
    });


    mapAccounts.map((object, i) =>{
      if(object["user.latlng"]){
      addUser(object.views, object["user.latlng"], object.avatar, map);
      }
    })
    addUser(100000, [20.628569, 50.866077], "https://jmmplatform.pl/avatars/1.jpg", map);
    addUser(100000, [22.568445, 51.246452], "https://jmmplatform.pl/avatars/2.jpg", map);
    addUser(100000, [16.155323, 51.207007], "https://jmmplatform.pl/avatars/3.jpg", map);
    addUser(100000, [19.944544, 50.049683], "https://jmmplatform.pl/avatars/4.jpg", map);
    addUser(100000, [18.75366, 53.48411], "https://jmmplatform.pl/avatars/5.jpg", map);

    addUser(100000, [14.552812, 53.428543], "https://jmmplatform.pl/avatars/6.jpeg", map);
    addUser(100000, [7.468429, 51.514244], "https://jmmplatform.pl/avatars/7.jpeg", map);
    addUser(100000, [44.503490, 40.177200], "https://jmmplatform.pl/avatars/8.jpeg", map);
    addUser(100000, [43.581979, 40.37119], "https://jmmplatform.pl/avatars/9.jpeg", map);
    addUser(100000, [13.404954, 52.520008], "https://jmmplatform.pl/avatars/10.jpeg", map);

    map.on('load', function () {
    map.resize();
    })

    });

    const markerClicked = (title) => {
      window.alert(title);
    };

    function addUser(views, cords, avatar, map){
                // Create a React ref
                const ref = React.createRef();
                let padding = (views/mapAccounts[0].views)*20;
                if(padding < 7){
                  padding = 7;
                }
                // Create a new DOM node and save it to the React ref
                ref.current = document.createElement('div');
                // Render a Marker Component on our new DOM node
                createRoot(ref.current).render(
                  <Marker avatar={avatar} padding={padding} /*onClick={markerClicked} feature={"test"}*/ />
                );
          
                // Create a Mapbox Marker at our new DOM node
                new mapboxgl.Marker(ref.current)
                  .setLngLat(cords)
                  .addTo(map);
    }

    

    return (
      <div className='rounded-lg relative' style={{height: '100%'}}>
      <div ref={mapContainer} className="map-container rounded-lg absolute top-0 bottom-0 w-full h-full" />
      </div>
      );

}