import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';

const AdminAddSound = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [sound, setSound] = useState('');
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();

    const [modal, setModal] = useState({ show: false, data: null });


    useLayoutEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const addSound = async () => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy chcesz dodać ten dźwięk?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await refreshToken();
                    const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'admin/addsound', {
                        url: sound
                    }, {headers: {
                        Authorization: `Bearer ${token}`
                    }});
                if(response.data.status == "success"){
                    Swal.fire('Dodano dźwięk', "", 'success');
                    setSound('');
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                console.log(JSON.stringify(e));
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);


    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
<nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-between gap-3 items-center">
<ArrowLeftIcon onClick={() => navigate("/admin/music")} className="cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500"></ArrowLeftIcon>


                    <span className='text-white font-normal text-xl'>Dodaj dźwięk</span>

<XMarkIcon onClick={() => navigate("/admin/dashboard")} className='cursor-pointer hover:scale-105 transition-transform w-9 h-9 text-sky-500'></XMarkIcon>
            </div>
        </nav>
    <div className="container mx-auto px-6 pb-12">
    
    <div className="text-slate-300 text-base font-normal mt-3 mb-1">Dźwięk</div>
    <input rows="10" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg  block w-full px-2.5 py-3 mb-1" placeholder="Link do dźwięku" value={sound} onChange={(e) => setSound(e.target.value)}/>

        <button onClick={addSound} className="mt-6 bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-3 px-4 rounded-lg w-full flex justify-center gap-2 items-center"><PlusIcon className="text-white w-5 h-5"></PlusIcon> Dodaj dźwięk</button>
 
        </div>
    </motion.main>
    </>
        );

}

export default AdminAddSound;