import React, { useLayoutEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import jwt_decode from "jwt-decode";
import { Modal } from '../fragments/Modal';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [newUser, setNewUser] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    const [modal, setModal] = useState({ show: false, data: null });
  
    const handleClose = () => {
      setModal({ show: false, data: null });
    };

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            setNewUser(decoded.newuser);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const ChangePassword = async (e) => {
        e.preventDefault();
        try {
            await refreshToken();
            const response = await axiosJWT.post(process.env.REACT_APP_API_URL + 'changepassword', {
                password: password,
                confirmPassword: confirmPassword
            },{headers: {
                Authorization: `Bearer ${token}`
            }});
            navigate("/start")
        } catch (error) {
            
            if(error.response.status == 403){
                setMsg("Wystąpił problem, odśwież stronę i spróbuj jeszcze raz!");
                setModal({ show: true, data: { type: 'error', message: "Wystąpił problem, odśwież stronę i spróbuj jeszcze raz!" } });
              }else if (error.response){
                setMsg(error.response.data.msg);
            }else{
                setMsg(error.message);
                setModal({ show: true, data: { type: 'error', message: error.message } });
            }
        }
    }

    return (
        <>
        <motion.main
        className="main__container"
        initial={{ display: "none",opacity: 0 }}
         animate={{ display: "flex",opacity: 1 }}
         exit={{ display: "none",opacity: 0 }}
         transition={{ duration: 1 }}
     >
        {modal.show && modal.data && <Modal closeModal={handleClose} data={modal.data} />}
                <div className="container mx-auto flex justify-center flex-grow h-full items-center flex-col p-6">
                    <svg width="108" height="108" viewBox="0 0 108 108" className='mb-8' fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5805 78.8183L54 3.61344L97.4195 78.8183H10.5805Z" stroke="white" stroke-width="3.61345"/>
<rect width="55.105" height="16.2605" transform="translate(26.8992 69.5588)" fill="black"/>
<rect width="55.105" height="16.2605" transform="translate(26.8992 69.5588)" fill="#0F172A" fill-opacity="0.5"/>
<path d="M32.9733 82.5588V81.3296L35.8454 78.6703C36.0897 78.4339 36.2945 78.2212 36.46 78.0321C36.6281 77.843 36.7555 77.6578 36.8422 77.4766C36.9288 77.2927 36.9722 77.0944 36.9722 76.8817C36.9722 76.6453 36.9183 76.4417 36.8106 76.271C36.7029 76.0977 36.5559 75.965 36.3694 75.8731C36.1829 75.7786 35.9715 75.7313 35.7351 75.7313C35.4882 75.7313 35.2728 75.7812 35.089 75.881C34.9051 75.9808 34.7633 76.1239 34.6635 76.3104C34.5637 76.4969 34.5138 76.7188 34.5138 76.9762H32.8945C32.8945 76.4483 33.014 75.99 33.2531 75.6013C33.4921 75.2125 33.8269 74.9118 34.2577 74.6991C34.6884 74.4863 35.1848 74.3799 35.7469 74.3799C36.3247 74.3799 36.8277 74.4824 37.2558 74.6872C37.6866 74.8895 38.0214 75.1705 38.2605 75.5304C38.4995 75.8902 38.619 76.3025 38.619 76.7674C38.619 77.0721 38.5586 77.3728 38.4377 77.6696C38.3196 77.9664 38.1081 78.296 37.8034 78.6585C37.4988 79.0183 37.0693 79.4504 36.5152 79.9547L35.3372 81.109V81.1642H38.7253V82.5588H32.9733Z" fill="white"/>
<path d="M47.5585 82.7361C46.8809 82.7335 46.2978 82.5667 45.8093 82.2358C45.3234 81.9048 44.9491 81.4255 44.6864 80.7978C44.4264 80.17 44.2977 79.4149 44.3003 78.5324C44.3003 77.6526 44.4304 76.9027 44.6904 76.2828C44.953 75.663 45.3273 75.1915 45.8132 74.8685C46.3017 74.5428 46.8835 74.3799 47.5585 74.3799C48.2335 74.3799 48.814 74.5428 49.2999 74.8685C49.7884 75.1942 50.164 75.6669 50.4266 76.2868C50.6893 76.904 50.8193 77.6526 50.8167 78.5324C50.8167 79.4176 50.6853 80.174 50.4227 80.8017C50.1627 81.4294 49.7897 81.9088 49.3038 82.2397C48.8179 82.5707 48.2361 82.7361 47.5585 82.7361ZM47.5585 81.3218C48.0208 81.3218 48.3898 81.0893 48.6656 80.6244C48.9414 80.1595 49.0779 79.4622 49.0753 78.5324C49.0753 77.9205 49.0123 77.4109 48.8862 77.0038C48.7628 76.5967 48.5868 76.2907 48.3583 76.0859C48.1324 75.881 47.8658 75.7786 47.5585 75.7786C47.0989 75.7786 46.7312 76.0084 46.4554 76.468C46.1796 76.9276 46.0404 77.6158 46.0378 78.5324C46.0378 79.1523 46.0995 79.6697 46.2229 80.0847C46.349 80.497 46.5263 80.807 46.7548 81.0145C46.9833 81.2193 47.2512 81.3218 47.5585 81.3218Z" fill="white"/>
<path d="M59.7989 82.6692C59.2105 82.6692 58.6865 82.568 58.2269 82.3658C57.7699 82.1609 57.4087 81.8799 57.1435 81.5227C56.8808 81.1629 56.7456 80.7479 56.7377 80.2777H58.4554C58.4659 80.4747 58.5303 80.6481 58.6484 80.7978C58.7693 80.9449 58.9295 81.0591 59.1291 81.1405C59.3287 81.222 59.5533 81.2627 59.8028 81.2627C60.0628 81.2627 60.2926 81.2167 60.4922 81.1248C60.6919 81.0328 60.8481 80.9055 60.9611 80.7426C61.074 80.5798 61.1305 80.392 61.1305 80.1792C61.1305 79.9639 61.0701 79.7734 60.9493 79.608C60.8311 79.4399 60.6603 79.3086 60.4371 79.214C60.2165 79.1195 59.9538 79.0722 59.6491 79.0722H58.8967V77.8193H59.6491C59.9065 77.8193 60.1337 77.7747 60.3307 77.6854C60.5303 77.5961 60.6853 77.4726 60.7956 77.3151C60.9059 77.1548 60.9611 76.9684 60.9611 76.7556C60.9611 76.5534 60.9125 76.3761 60.8153 76.2237C60.7207 76.0688 60.5868 75.948 60.4134 75.8613C60.2427 75.7746 60.0431 75.7313 59.8146 75.7313C59.5835 75.7313 59.372 75.7733 59.1803 75.8574C58.9886 75.9388 58.8349 76.0557 58.7194 76.208C58.6038 76.3603 58.5421 76.5389 58.5342 76.7438H56.8992C56.9071 76.2789 57.0397 75.8692 57.2971 75.5146C57.5545 75.16 57.9012 74.8829 58.3372 74.6833C58.7758 74.4811 59.2709 74.3799 59.8225 74.3799C60.3793 74.3799 60.8665 74.4811 61.2841 74.6833C61.7017 74.8855 62.0261 75.1587 62.2572 75.5028C62.491 75.8442 62.6066 76.2277 62.6039 76.6532C62.6066 77.1049 62.4661 77.4818 62.1824 77.7839C61.9014 78.0859 61.535 78.2777 61.0832 78.3591V78.4221C61.6768 78.4983 62.1285 78.7045 62.4385 79.0407C62.751 79.3742 62.906 79.7918 62.9034 80.2935C62.906 80.7531 62.7733 81.1615 62.5054 81.5187C62.2402 81.876 61.8738 82.157 61.4063 82.3619C60.9387 82.5667 60.4029 82.6692 59.7989 82.6692Z" fill="white"/>
<path d="M72.4016 82.7361C71.724 82.7335 71.1409 82.5667 70.6524 82.2358C70.1665 81.9048 69.7922 81.4255 69.5296 80.7978C69.2696 80.17 69.1409 79.4149 69.1435 78.5324C69.1435 77.6526 69.2735 76.9027 69.5335 76.2828C69.7962 75.663 70.1704 75.1915 70.6563 74.8685C71.1449 74.5428 71.7266 74.3799 72.4016 74.3799C73.0767 74.3799 73.6571 74.5428 74.143 74.8685C74.6315 75.1942 75.0071 75.6669 75.2698 76.2868C75.5324 76.904 75.6624 77.6526 75.6598 78.5324C75.6598 79.4176 75.5285 80.174 75.2658 80.8017C75.0058 81.4294 74.6329 81.9088 74.147 82.2397C73.661 82.5707 73.0793 82.7361 72.4016 82.7361ZM72.4016 81.3218C72.8639 81.3218 73.2329 81.0893 73.5087 80.6244C73.7845 80.1595 73.9211 79.4622 73.9184 78.5324C73.9184 77.9205 73.8554 77.4109 73.7293 77.0038C73.6059 76.5967 73.4299 76.2907 73.2014 76.0859C72.9755 75.881 72.7089 75.7786 72.4016 75.7786C71.942 75.7786 71.5743 76.0084 71.2985 76.468C71.0227 76.9276 70.8835 77.6158 70.8809 78.5324C70.8809 79.1523 70.9426 79.6697 71.0661 80.0847C71.1921 80.497 71.3694 80.807 71.5979 81.0145C71.8264 81.2193 72.0943 81.3218 72.4016 81.3218Z" fill="white"/>
</svg>

                            <form onSubmit={ChangePassword} className="flex gap-4 flex-col bg-slate-800/30 border border-solid border-slate-800 p-3 rounded-xl w-full">
                                {newUser ? <p className='text-center text-base text-slate-400 mt-3'>Jest to twoje pierwsze logowanie i posiadasz tymczasowe hasło, które musisz zmienić!</p> : ""}
                                <p className="text-center text-red-500 text-base mt-3">{msg}</p>
                                <div >
                                    <label className="block mb-2 text-sm font-medium text-slate-300">Hasło</label>
                                    <div className="controls">
                                        <input type="password" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg block w-full p-2.5" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div >
                                    <label className="block mb-2 text-sm font-medium text-slate-300">Potwierdź hasło</label>
                                    <div className="controls">
                                        <input type="password" className="bg-slate-900 border border-slate-800 text-white text-sm rounded-lg block w-full p-2.5" placeholder="******" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="text-center mt-1">
                                    <button className=" bg-sky-600 hover:bg-sky-500 text-white font-medium text-lg py-2 px-4 rounded w-full flex justify-center items-center">Zmień hasło</button>
                                </div>
                            </form>
                </div>
                </motion.main>
                </>
    )
}

export default ChangePassword
