import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { short_formatter } from "../utils/chart";
import { Modal } from "../fragments/Modal";
import { downloadVideo } from "../utils/utils";
import moment from "moment";

const Video = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [inspiration, setInspiration] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [inspirations, setInspirations] = useState([]);
    const [videoIndex, setVideoIndex] = useState();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    useEffect(() => {
        refreshToken();
        
    }, []);

      useEffect(() => {
        window.addEventListener('keydown', e => {
         if(e.key === 'ArrowRight'){
          nextVideo();
         }else if(e.key === 'ArrowLeft'){
            previousVideo();
         }
        })
       })

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getVideo();
            setLoaded(true);

        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getVideo = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'inspiration?id=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setInspiration(response.data);
            await getInspirations(response.data.category_id);
    }

    const getInspirations = async (cid) => {
        let response;
        if(searchParams.get("type")){
            response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'allinspirations', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        }else{
        response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'inspirations?category=' + cid, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
        setInspirations(response.data);
        let vidid = response.data.videos.find(el => el.id == id);
        vidid = response.data.videos.indexOf(vidid);
        setVideoIndex(vidid);
        document.onkeydown = checkKey;
    }

    const previousVideo = async () =>{
        if(videoIndex > 0){
            navigate("/video/" + inspirations.videos[videoIndex-1].id + (searchParams.get("type") ? "?type=all" : ""))
        }
    }

    const nextVideo = async () =>{
        if(videoIndex < inspirations.videos.length-1){
            navigate("/video/" + inspirations.videos[videoIndex+1].id + (searchParams.get("type") ? "?type=all" : ""))
        }
    }

    async function checkKey(e) {

        e = e || window.event;
        if (e.keyCode == '37') {
           await previousVideo();
        }
        else if (e.keyCode == '39') {
           await nextVideo();
        }
    
    }

    const downloadVideo = (url) => {
        setDownloading(true);
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total)); // you can use this to show user percentage of file downloaded
        },
        }).then((response) => {
          const urlObject = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = urlObject;
          link.setAttribute('download', 'recording.mp4');
          link.setAttribute('target', '_blank')
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloading(false);
          setPercentCompleted(0);
        });
      };

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="absolute flex flex-row justify-between w-full p-2 pointer-events-none" style={{zIndex: 1, top: '50%'}}>
        <div onClick={() => previousVideo()} className={"pointer-events-auto bg-slate-900 border border-solid border-slate-700 p-3 rounded-full cursor-pointer ".concat(videoIndex > 0 ? "" : "invisible")}>
            <ArrowLeftIcon className="w-6 h-6 text-sky-500"></ArrowLeftIcon>
        </div>

        <div onClick={() => nextVideo()} className={"pointer-events-auto bg-slate-900 border border-solid border-slate-700 p-3 rounded-full cursor-pointer ".concat(videoIndex < inspirations.videos.length-1 ? "" : "invisible")}>
           <ArrowRightIcon className="w-6 h-6 text-sky-500"></ArrowRightIcon>
        </div>
        </div>
    <div className="flex flex-col container mx-auto px-6 h-full max-h-full mb-7">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/inspirations")}>Filmy do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>{inspiration.category_title}</span>
        </div>
        <div className="flex flex-col mb-6 justify-center items-center h-full relative rounded-lg">
        <video className="rounded-lg flex w-auto h-full absolute object-contain" id="video" loop autoPlay controls>
   <source src={process.env.REACT_APP_API_URL + "videopreview?filename=".concat(inspiration.path)}/>
   Your browser does not support the video tag.
</video> 
</div>
<div onClick={() => downloadVideo(process.env.REACT_APP_API_URL + "video?filename=".concat(inspiration.path))} className={"bg-slate-800 text-white flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer".concat(!downloading ? "" : " bg-slate-900 text-slate-400 opacity-70 cursor-not-allowed")}><ArrowDownTrayIcon className="text-sky-500 h-5 w-5"></ArrowDownTrayIcon>{downloading ? "Pobieranie... (" + percentCompleted + "%)" : "Pobierz"}</div>

        </div>
    </motion.main>
    </>
        );

}

export default Video;