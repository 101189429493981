import { BellIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';

const AdminNavbar = (props) => {
    const navigate = useNavigate();
    const [sidebarOpen, setSideBarOpen] = useState(false);
    const handleViewSidebar = () => {
      setSideBarOpen(!sidebarOpen);
    };

    return (
<>
<AdminSidebar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
        <nav className="navbar py-4 px-4 mb-10 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-start gap-3 items-center">
        <svg onClick={handleViewSidebar} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#0EA5E9" className="cursor-pointer hover:scale-105 transition-transform w-9 h-9">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>


                    <span className='text-white font-normal text-xl mr-auto'>JMM <span className='px-1 py-0.5 bg-red-900/30 text-red-500 rounded'>ADMIN</span></span>


            </div>
        </nav>

</>
    )
}

export default AdminNavbar
