import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import moment from "moment";
import AdminNavbar from "./AdminNavbar";

const AdminCategory = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [inspirations, setInspirations] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [downloading, setDownloading] = useState(false);
    let { id } = useParams();

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getInspirations();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }

    const getInspirations = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'inspirations?category=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setInspirations(response.data);
    }

    const downloadVideo = (url) => {
        setDownloading(true);
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const urlObject = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = urlObject;
          link.setAttribute('download', 'recording.mp4');
          link.setAttribute('target', '_blank')
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloading(false);
        });
      };

    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2 mb-4'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/admin/inspirations")}>Filmy do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon> {id == 1 ? "Wszystkie nowe" : inspirations.category}</span>
        </div>
        {inspirations.videos.length < 1 ? <div className="flex justify-center"><span className="text-slate-400 text-base">W tej kategorii nie ma jeszcze filmów</span></div> : ""}
        <div className="grid grid-cols-2 md:flex md:flex-row md:flex-wrap gap-3">
{inspirations.videos.map((object, i) =>
    <div className="bg-slate-900 border-solid border border-slate-800 rounded-md md:w-40">
    {moment().diff(moment(object.createdAt), 'days') < 3 ? 
    <div className="text-xs bg-red-500 rounded-lg p-1 px-1.5 absolute -top-1 -right-2 z-3">🔥 NOWY</div>
    : ""}
  <div onClick={() => navigate("/video/" + object.id)} className="vid rounded-md flex justify-center items-center cursor-pointer h-auto md:w-40" style={{aspectRatio: '9 / 13', background: 'center linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(' + process.env.REACT_APP_API_URL + "cover?filename=".concat(object.cover) +')', backgroundSize: "cover",}}><PlayCircleIcon className="text-sky-500 h-14 w-14 hover:scale-110 transition-transform"></PlayCircleIcon></div>
  <div onClick={() => downloadVideo(process.env.REACT_APP_API_URL + "video?filename=".concat(object.path))} className={"bg-slate-800 text-white flex flex-row justify-center items-center text-base rounded-md m-2 p-2 gap-2 cursor-pointer".concat(!downloading ? "" : " bg-slate-900 text-slate-400 opacity-70 cursor-not-allowed")}><ArrowDownTrayIcon className="text-sky-500 h-5 w-5"></ArrowDownTrayIcon>{downloading ? "Pobieranie..." : "Pobierz"}</div>
</div>
)}
</div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminCategory;