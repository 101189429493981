import { BellIcon, ChevronDownIcon, UserIcon } from '@heroicons/react/24/outline';
import React, { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminSidebar from '../admin/components/AdminSidebar';
import SideBar from './Sidebar';
import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../utils/utils';
import axios from 'axios';

const Navbar = ({role=null, avatar, username, account, accounts=[], setCurrentAccount}) => {
    const navigate = useNavigate();
    const [sidebarOpen, setSideBarOpen] = useState(false);
    const handleViewSidebar = () => {
      setSideBarOpen(!sidebarOpen);
    };

    const Logout = async () => {
      try {
          await axios.delete(process.env.REACT_APP_API_URL + 'logout');
          navigate("/login");
      } catch (error) {
          
      }
  }

    return (
<>
<SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
        <nav className="navbar py-4 px-4 mb-8 bg-slate-900/80 backdrop-blur relative">
            <div className="flex justify-start gap-3 items-center" style={{maxWidth: "1280px", margin: "0 auto"}}>
<div class="flex items-center gap-4">
  <a href="/dashboard" className=' text-white text-xl lg:text-2xl font-semibold'><span className='text-sky-400'>JMM</span>PLATFORM</a>
  </div>

  <Menu as="div" className="relative inline-block text-left w-fit ml-auto">
      <div>
        <Menu.Button className=" cursor-pointer  bg-slate-800 inline-flex w-fit justify-between items-center gap-x-1.5 rounded-xl p-1 pr-3 text-sm font-semibold text-white">
        <div className='flex flex-row items-center gap-3'>
          {avatar ? <img className='w-8 h-8 rounded-3xl border border-solid border-slate-800' onError={({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src="https://jmmplatform.pl/waiting.png";
  }}   src={avatar}/> : <UserIcon className='w-6 h-6 text-slate-400'></UserIcon>}
<span className='text-white font-light text-base hidden lg:flex'>{username || <div class="h-2.5 bg-gray-500 rounded-full w-48"></div>}</span>
</div>
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items style={{width: "13.125rem"}}  className="bg-slate-900 border border-slate-800 absolute right-0 z-10 mt-2 origin-top-right rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none boxshadow">
        {
        accounts.length > 1 ?
        accounts.map((object, i) =>
        <>
            <Menu.Item key={i}>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => setCurrentAccount(object.id)}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
        <img className='w-6 h-6 rounded-3xl border border-solid border-gray-500' src={object.avatar}/>
<span className='font-light text-white'>{object.username}</span>
</div>
                </a>
              )}
            </Menu.Item>
            </>
        )
      : ""}
            <div className="bg-slate-800" style={{width: '100%', height: '1px'}}></div>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/dashboard")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span className='font-light text-white'>Kokpit</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/profile")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                  <UserIcon className='w-5 h-5 text-slate-300'></UserIcon>
<span className='font-light text-white'>Mój profil</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/mytiktoks")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path d="M13.8333 4.85C13.2637 4.19969 12.9498 3.36453 12.95 2.5H10.375V12.8333C10.3551 13.3925 10.1191 13.9222 9.71648 14.3108C9.31389 14.6994 8.77621 14.9166 8.21667 14.9167C7.03334 14.9167 6.05001 13.95 6.05001 12.75C6.05001 11.3167 7.43334 10.2417 8.85834 10.6833V8.05C5.98334 7.66667 3.46667 9.9 3.46667 12.75C3.46667 15.525 5.76668 17.5 8.20834 17.5C10.825 17.5 12.95 15.375 12.95 12.75V7.50833C13.9942 8.25821 15.2478 8.66054 16.5333 8.65833V6.08333C16.5333 6.08333 14.9667 6.15833 13.8333 4.85Z" fill="#CBD5E1"/>
</svg>
<span className='font-light text-white'>Moje tiktoki</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/inspirations")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path d="M12.5 8.33333L16.2942 6.43667C16.4212 6.3732 16.5623 6.34323 16.7042 6.34962C16.846 6.35601 16.9839 6.39854 17.1047 6.47317C17.2255 6.5478 17.3252 6.65206 17.3944 6.77606C17.4636 6.90006 17.4999 7.03967 17.5 7.18167V12.8183C17.4999 12.9603 17.4636 13.0999 17.3944 13.2239C17.3252 13.3479 17.2255 13.4522 17.1047 13.5268C16.9839 13.6015 16.846 13.644 16.7042 13.6504C16.5623 13.6568 16.4212 13.6268 16.2942 13.5633L12.5 11.6667V8.33333ZM4.16667 15H10.8333C11.2754 15 11.6993 14.8244 12.0118 14.5118C12.3244 14.1993 12.5 13.7754 12.5 13.3333V6.66667C12.5 6.22464 12.3244 5.80072 12.0118 5.48816C11.6993 5.17559 11.2754 5 10.8333 5H4.16667C3.72464 5 3.30072 5.17559 2.98816 5.48816C2.67559 5.80072 2.5 6.22464 2.5 6.66667V13.3333C2.5 13.7754 2.67559 14.1993 2.98816 14.5118C3.30072 14.8244 3.72464 15 4.16667 15Z" stroke="#CBD5E1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span className='font-light text-white'>Filmy do tiktoków</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/helpful")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 5.03501C8.62634 3.80281 6.84533 3.12246 5 3.12501C4.12333 3.12501 3.28167 3.27501 2.5 3.55167V15.4267C3.30302 15.1434 4.14847 14.9991 5 15C6.92083 15 8.67333 15.7225 10 16.91M10 5.03501C11.3736 3.80274 13.1547 3.12238 15 3.12501C15.8767 3.12501 16.7183 3.27501 17.5 3.55167V15.4267C16.697 15.1434 15.8515 14.9991 15 15C13.1547 14.9975 11.3737 15.6778 10 16.91M10 5.03501V16.91" stroke="#CBD5E1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span className='font-light text-white'>Materiały</span>
</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {navigate("/earnings")}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path d="M10 5V15M7.5 12.6515L8.23239 13.2008C9.20867 13.9331 10.7915 13.9331 11.7678 13.2008C12.7441 12.4686 12.7441 11.2814 11.7678 10.5492C11.2796 10.183 10.6398 9.99996 9.99991 10C9.3958 10 8.79183 9.817 8.33091 9.45085C7.40916 8.71861 7.40916 7.53143 8.33091 6.7992C9.25266 6.06696 10.7471 6.06696 11.6689 6.7992L12.0146 7.07386M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CBD5E1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span className='font-light text-white'>Zarobki</span>
</div>
                </a>
              )}
            </Menu.Item>
            <div className="bg-slate-800" style={{width: '100%', height: '1px'}}></div>
            {role == "admin" ?
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => navigate("/admin/dashboard")}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                  
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path d="M3.125 5C3.125 3.96447 3.96447 3.125 5 3.125H6.875C7.91053 3.125 8.75 3.96447 8.75 5V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H5C3.96447 8.75 3.125 7.91053 3.125 6.875V5Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.125 13.125C3.125 12.0895 3.96447 11.25 5 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V15C8.75 16.0355 7.91053 16.875 6.875 16.875H5C3.96447 16.875 3.125 16.0355 3.125 15V13.125Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.25 5C11.25 3.96447 12.0895 3.125 13.125 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V6.875C16.875 7.91053 16.0355 8.75 15 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V5Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H15C16.0355 11.25 16.875 12.0895 16.875 13.125V15C16.875 16.0355 16.0355 16.875 15 16.875H13.125C12.0895 16.875 11.25 16.0355 11.25 15V13.125Z" stroke="#0EA5E9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span className='font-light text-white'>Panel admina</span>
</div>
                </a>
              )}
            </Menu.Item>
            : ""}
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {Logout()}}
                  className={classNames(
                    active ? ' bg-slate-800' : '',
                    'block py-3 px-4 text-base text-white'
                  )}
                >
                  
                          <div className='flex flex-row items-center gap-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='w-5 h-5'>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.25C3 3.00736 4.00736 2 5.25 2H10.75C11.9926 2 13 3.00736 13 4.25V6.25C13 6.66421 12.6642 7 12.25 7C11.8358 7 11.5 6.66421 11.5 6.25V4.25C11.5 3.83579 11.1642 3.5 10.75 3.5H5.25C4.83579 3.5 4.5 3.83579 4.5 4.25V15.75C4.5 16.1642 4.83579 16.5 5.25 16.5H10.75C11.1642 16.5 11.5 16.1642 11.5 15.75V13.75C11.5 13.3358 11.8358 13 12.25 13C12.6642 13 13 13.3358 13 13.75V15.75C13 16.9926 11.9926 18 10.75 18H5.25C4.00736 18 3 16.9926 3 15.75V4.25Z" fill="#CBD5E1"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19 10C19 9.58579 18.6642 9.25 18.25 9.25H8.70447L9.75172 8.30747C10.0596 8.03038 10.0846 7.55616 9.80747 7.24828C9.53038 6.94039 9.05616 6.91543 8.74828 7.19253L6.24828 9.44253C6.09024 9.58476 6 9.78738 6 10C6 10.2126 6.09024 10.4152 6.24828 10.5575L8.74828 12.8075C9.05616 13.0846 9.53038 13.0596 9.80747 12.7517C10.0846 12.4438 10.0596 11.9696 9.75172 11.6925L8.70447 10.75H18.25C18.6642 10.75 19 10.4142 19 10Z" fill="#CBD5E1"/>
</svg>
<span className='font-light text-white'>Wyloguj się</span>
</div>
                </a>
              )}
            </Menu.Item>

        </Menu.Items>
      </Transition>
    </Menu>
</div>
        </nav>
</>
    )
}

export default Navbar
