import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { CheckCircleIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import { options, short_formatter } from "../../utils/chart";
import { TikTok } from "../../utils/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '@sweetalert2/theme-dark/dark.scss';
import AdminNavbar from "./AdminNavbar";
import dateFormat from 'dateformat';
import { Line } from "react-chartjs-2";

const AdminAccount = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [user, setUser] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [tiktoks, setTikToks] = useState([]);
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartType, setChartType] = useState('views');
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    let { id } = useParams();

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);


        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getAccount = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/account?id=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setAccount(response.data);
        setUser(response.data.user);
        setTikToks(response.data.tiktoks);

        setLabels([]);
        setChartData([]);
        response.data.statistics.forEach(function (stat){
          setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
          setChartData(chartData => [...chartData, stat.views])
        })

        setLoaded(true);
    }

    const reject = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz odrzucić to konto?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewaccount?id=' + id + '&action=reject', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getAccount();
              Swal.fire('Odrzucono konto', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const accept = async (props) => {
        MySwal.fire({
            title: <strong>UWAGA</strong>,
            html: 'Czy na pewno chcesz zaakceptować to konto?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText:'Tak',
            cancelButtonText: 'Anuluj'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'admin/reviewaccount?id=' + id + '&action=accept', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.data.status == "success"){
                    getAccount();
              Swal.fire('Zaakceptowao konto', '', 'success')
                }else{
                    Swal.fire('Błąd', response.data.message, 'error');
                }
            }catch(e){
                Swal.fire('Błąd', e.message, 'error');
            }
            }
          })
    }

    const data = {
        labels,
        datasets: [
          {
            data: chartData,
            borderColor: '#22C55E',
            pointRadius: 4,
            tension: 0.4,
            fill: 'start',
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(34, 197, 94, 0.1)");
              gradient.addColorStop(0.75, "rgba(34, 197, 94, 0)");
              gradient.addColorStop(1, "transparent");
              return gradient;
            },
          },
        ],
      };

    const handleChart = async(props) => {
        setChartType(props);
        setLabels([]);
        setChartData([]);
        switch(props){
            case "views":
                account.statistics.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.views])
                  })
                  break;
            case "likes":
                account.statistics.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.likes])
                  })
                  break;
            case "comments":
                account.statistics.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.comments])
                  })
                  break;
            default:
                account.statistics.forEach(function (stat){
                    setLabels(labels => [...labels, dateFormat(new Date(stat.date), "dd mmm")])
                    setChartData(chartData => [...chartData, stat.views])
                  })
                  break;
        }
    }

    useEffect(() => {
        refreshToken();
        getAccount();
    }, []);
    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);
    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <AdminNavbar></AdminNavbar>
    <div className="container mx-auto px-6 pb-12">
          <div id="bright"></div>
          <div className="flex justify-center gap-3 flex-col items-center mb-14">
          <div class="h-20 w-20 relative">
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-50" style={{ filter: 'blur(22.5px)', willChange: 'transform', backgroundImage: "url(" + account.avatar + ")"}}></div>
     <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-full" style={{backgroundImage: "url(" + account.avatar + ")"}}></div>
</div>
<span className="font-bold text-white text-lg">{account.username}</span>
{account.status == "accepted" && <div className='text-green-500 bg-green-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <CheckCircleIcon className="text-green-500 w-4 h-4"></CheckCircleIcon> Aktywny</div>}
    {account.status == "waiting" && <div className='text-yellow-500 bg-yellow-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <ClockIcon className="text-yellow-500 w-4 h-4"></ClockIcon> Oczekujący</div>}
    {account.status == "rejected" && <div className='text-red-500 bg-red-900/30 bg-opacity-30  px-2 py-1 rounded-lg w-fit flex flex-row items-center gap-1 text-sm'>
    <XMarkIcon className="text-red-500 w-4 h-4"></XMarkIcon> Odrzucony</div>}
</div>
<div className="text-slate-200 text-lg font-normal mt-7 mb-4">Informacje</div>
<div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Nazwa użytkownika</div>
                            <div className="text-slate-400 text-base">{user.nick}</div>
                        </div>
<div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Ostrzeżenia</div>
                            <div className="text-green-500 text-base">0/3</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">TikToki</div>
                            <div className="text-slate-400 text-base">{tiktoks.length}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Obserwujący</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(account.followers)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Wyświetlenia</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(account.views)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Polubienia</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(account.likes)}</div>
                        </div>
                        <div className="flex justify-between items-center flex-row">
                            <div className="text-slate-300">Komentarze</div>
                            <div className="text-slate-400 text-base">{short_formatter.format(account.comments)}</div>
                        </div>

                        <div className="flex flex-col gap-3 mt-6 justify-center items-center">
        <div className="flex flex-row gap-1 mb-4 flex-wrap">
        <div onClick={() => {handleChart("views")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "views" ? "bg-green-900" : "bg-slate-800")}>Wyświeltenia</div>
        <div onClick={() => {handleChart("likes")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "likes" ? "bg-green-900" : "bg-slate-800")}>Polubienia</div>
        <div onClick={() => {handleChart("comments")}} className={"p-2 rounded-xl cursor-pointer ".concat(chartType == "comments" ? "bg-green-900" : "bg-slate-800")}>Komentarze</div>        </div>
        <div className='h-44 w-full flex justify-center items-center'>
                  {labels.length > 1 ?
                <Line options={options} data={data} />
                  : <span className='text-slate-400 text-xs'>Statystyki nie są jeszcze dostępne!</span>}
                </div>
                </div>

                        <div onClick={() => window.open("https://tiktok.com/@" + account.username, '_blank').focus()} className="mt-4 bg-slate-900 cursor-pointer border border-solid border-slate-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                        <svg fill="#FFFFFF" className="w-5 h-5" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                         Zobacz na tiktoku
        </div>
        {account.status == "waiting" || account.status == "rejected" ?
        <div className="flex flex-row gap-2 mt-4">
        <div onClick={accept} className="w-1/2 bg-green-900 cursor-pointer border border-solid border-green-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Zatwierdź
        </div>
        <div onClick={reject} className="w-1/2 bg-red-900 cursor-pointer border border-solid border-red-800 rounded-md text-white text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
                         Odrzuć
        </div>
        </div>
        : <div onClick={reject} className="cursor-pointer mt-4 bg-red-900/30 border border-solid border-red-500 rounded-md text-red-500 text-lg font-normal p-3 flex justify-center flex-row items-center gap-2">
        Odrzuć konto
</div> }
                        <div className="text-slate-200 text-lg font-normal mt-7 mb-4">TikToki</div>
                        <div className="flex flex-col gap-4">
    {tiktoks.length > 0 ? tiktoks.map((object, i) =>
    <TikTok admin={1} id={object.id} avatar={account.avatar} cover={object.cover} likes={short_formatter.format(object.likes)} comments={short_formatter.format(object.comments)} views={short_formatter.format(object.views)} status={object.status} date={new Date(parseInt(object.createTime)*1000).toLocaleString()}></TikTok>
    ) : <span className="text-slate-400 text-base text-center">Użytkownik nie ma jeszcze tiktoków!</span>}</div>
        </div>
    </motion.main>
    </>
        );

}

export default AdminAccount;