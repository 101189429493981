import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar";
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PlayCircleIcon, ArrowDownTrayIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon, MusicalNoteIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { short_formatter } from "../utils/chart";
import { Modal } from "../fragments/Modal";
import { downloadVideo } from "../utils/utils";
import moment from "moment";

const Tutorial = () => {

    
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [account, setAccount] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [tutorial, setTutorial] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    useLayoutEffect(() => {
        refreshToken();
        
    }, []);
    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
            await getAccounts();
            await getTutorial();
            setLoaded(true);
        } catch (error) {
            if (error.response) {
                navigate("/login")
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setExpire(decoded.exp);
            
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getTutorial = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'tutorial?id=' + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setTutorial(response.data);
    }

    const getAccounts = async () => {
        const response = await axiosJWT.get(process.env.REACT_APP_API_URL + 'accounts', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setAccount(response.data[0])
    }




    if(!loaded) return (<div className="w-full h-full flex justify-center items-center"><div class="loader"></div></div>);

    return (
        <>
                         <motion.main
   className="main__container"
   initial={{ display: "none",opacity: 0 }}
    animate={{ display: "flex",opacity: 1 }}
    exit={{ display: "none",opacity: 0 }}
    transition={{ duration: 1 }}
>
    <Navbar avatar={account.avatar} username={account.username} ></Navbar>
    <div className="flex flex-col container mx-auto px-6 h-full max-h-full mb-7">
          <div id="bright"></div>
          <div className='pb-6 flex flex-col gap-2'>
          <span className='text-slate-300 font-normal text-sm flex flex-row items-center gap-1'><u className=" cursor-pointer" onClick={() => navigate("/helpful")}>Przydatne do tiktoków</u> <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>Jak tworzyć tiktoki?</span>
        <span className='text-slate-50 text-xl'>{tutorial.title}</span>
        </div>
        <div className="flex flex-col mb-6 h-full rounded-lg">
        <video className="rounded-lg flex w-full h-auto object-contain" id="video" loop autoPlay controls>
   <source src={process.env.REACT_APP_API_URL + "tutorialvideo?id=".concat(id)}/>
   Your browser does not support the video tag.
</video> 
{tutorial.capcut ? 
<div onClick={() => window.open("https://capcut.com", '_blank').focus()} className="cursor-pointer flex flex-row gap-2 bg-slate-900 rounded-lg p-2 mt-4 items-center">
    <div>
        <img className="w-9 h-9 rounded-lg" src="https://i.pinimg.com/736x/e0/02/20/e002205cf0c57b42ee6045d4ae5ec2ce.jpg"/>
    </div>
    <div>
    Pobierz aplikację CapCut
    </div>
</div>
: ""}
{tutorial.soundId ?
<div onClick={() => navigate("/music?id=" + tutorial.soundId)} className="cursor-pointer flex flex-row gap-2 bg-slate-900 rounded-lg p-2 mt-4 items-center">
    <div className="bg-slate-800 p-1 rounded-lg">
        <MusicalNoteIcon className="w-7 h-7 text-sky-500"></MusicalNoteIcon>
    </div>
    <div>
    Pobierz dźwięk z filmu
    </div>
</div>
: ""}
</div>

        </div>
    </motion.main>
    </>
        );

}

export default Tutorial;